import { apiWrapper } from './api';

export const getDataByInnEgrul = async (inn: string): Promise<any> => {
  return await apiWrapper.get(`/organizations/egrul?inn=${inn}`).then((response) => {
    return response.data;
  });
};

export const getDataByInnSbis = async (inn: string): Promise<any> => {
  return await apiWrapper.get(`/organizations/saby?inn=${inn}`).then((response) => {
    return response.data;
  });
};

export const downloadEgrulPdf = async (inn: string = '1655378684'): Promise<any> => {
  await apiWrapper.get(`/organizations/egrul_pdf?inn=${inn}`).then((response) => {
    let a = document.createElement('a');
    a.style.display = 'none';
    a.href = response.data.link;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  });
};

export const getFoundersByInnSbis = async (inn: string): Promise<any> => {
  return await apiWrapper.get(`/organizations/founders?inn=${inn}`).then((response) => {
    return response.data;
  });
};
