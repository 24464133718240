import React, { useContext, useEffect, useState } from 'react';
import Dashboard from '../../../containers/admin/Dashboard';
import { DonationRequest } from '../../../types/DonationRequest';
import AppDonationRequestDialog from '../../../components/admin/AppDonationRequestDialog/AppDonationRequestDialog';
import { useDonationRequests } from '../../../hooks/useDonationRequests';
import DonationRequestsTable from '../../../components/admin/DonationRequests/DonationRequestsRegistryTable';
import FilterBar from '../../../components/admin/DonationRequests/FilterBar';
import { PageContext } from '../../../contexts/PageContext';

const SummaryRegistry: React.FC = () => {
  const { setPageName } = useContext(PageContext);

  useEffect(() => {
    setPageName('Сводный Реестр');
  }, [setPageName]);

  const {
    donationRequests,
    loading,
    status,
    setStatus,
    page,
    totalPages,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    ogrnFilter,
    setOgrnFilter,
    innFilter,
    setInnFilter,
    searchHandler,
    onPageChange,
    resetFilters,
  } = useDonationRequests();

  const [currentDonationRequest, setCurrentDonationRequest] = useState<DonationRequest | null>(
    null,
  );
  const [donationRequestDialogShown, setDonationRequestDialogShown] = useState<boolean>(false);

  const onOpenHandler = (donationRequest: DonationRequest): void => {
    setCurrentDonationRequest(donationRequest);
    setDonationRequestDialogShown(true);
  };

  const onCloseHandler = (): void => {
    setCurrentDonationRequest(null);
    setDonationRequestDialogShown(false);
  };

  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <Dashboard
      content={
        <div className="h-screen px-4 pt-10 pb-4 bg-slate-200">
          {/* Filter Bar */}
          <FilterBar
            status={status}
            setStatus={setStatus}
            ogrnFilter={ogrnFilter}
            setOgrnFilter={setOgrnFilter}
            innFilter={innFilter}
            setInnFilter={setInnFilter}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            searchHandler={searchHandler}
            resetFilters={resetFilters}
          />

          {/* Donation Requests Table */}
          <div className="mt-4 overflow-scroll h-3/4">
            {loading ? (
              <div className="text-center">Загрузка...</div>
            ) : (
              <div className="overflow-x-auto">
                <DonationRequestsTable
                  donationRequests={donationRequests}
                  onOpenHandler={onOpenHandler}
                />
              </div>
            )}
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-center mt-5">
            {pages.map((pageNum) => (
              <button
                key={pageNum}
                className={`h-8 w-8 mx-1 flex justify-center items-center border-gray-300 border rounded-md ${
                  pageNum === page ? 'bg-primary text-white' : 'bg-white text-gray-700'
                }`}
                onClick={() => onPageChange(pageNum)}
              >
                {pageNum}
              </button>
            ))}
          </div>

          {/* Donation Request Dialog */}
          {currentDonationRequest && (
            <AppDonationRequestDialog
              isOpen={donationRequestDialogShown}
              current_donation_request={currentDonationRequest}
              onClose={onCloseHandler}
            />
          )}
        </div>
      }
    />
  );
};

export default SummaryRegistry;
