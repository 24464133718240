import * as React from 'react';
import { TextField, TextFieldProps } from '@mui/material';

interface AppInputProps extends Omit<TextFieldProps, 'onChange'> {
  onChange: (event: any) => void;
  name: string;
}

const AppInput: React.FC<AppInputProps> = ({
  onChange,
  size = 'medium',
  variant = 'outlined',
  name,
  ...props
}) => {
  return <TextField {...props} name={name} onChange={onChange} size={size} variant={variant} />;
};

export default AppInput;
