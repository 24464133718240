import React from 'react';
import AppImportTransactionsDialog from '../../../../components/admin/transactions_flow/AppImportTransactionsDialog';
import { DonationStat } from '../../../../types/DonationStat';
import { apiWrapper } from '../../../../utils/api';
import { Filter } from '../../../../types/Filter';
import { downloadMinjust } from '../../../../utils/downloadMinjust';
import { downloadUsageReportFund } from '../../../../utils/downloadUsageReportFund';
import { BankAccount } from '../../../../types/BankAccount';
import { Fund } from '../../../../types/Fund';
import { toast } from 'react-toastify';
import FundReportDownload from '../../../../components/admin/fund/FundReportDownload';
import BankAccountsCard from '../../../../components/admin/bank_accounts/BankAccountsCard';
import AnalyticsReportsCard from '../../../../components/admin/analytics/AnalyticsReportsCard';
import SberBankStubModal from '../../../../components/admin/modals/SberBankStubModal';
import { Box, Button, Card, CardContent, CardHeader } from '@mui/material';
import AppCardTitle from '../../../../components/shared/AppCardTitle';
import AppBalanceDisplay from '../../../../components/admin/fund/AppBalanceDisplay';
import AppSberButton from '../../../../components/admin/AppSberButton';
import AppExpandButton from '../../../../components/shared/AppExpandButton';
import { SberTransaction } from '../../../../types/SberTransaction';
import AppTransactionsTableMini from '../../../../components/admin/Transactions/AppTransactionsTableMini';
import AppTransactionsTabs from '../../../../components/admin/Transactions/AppTransactionsTabs';
import AppTransactionModal from '../../../../components/admin/Transactions/AppTransactionModal';
import { PaginatedResponse, PaginationMeta } from '../../../../types/Pagination';

/**
 * Интерфейс пропсов компонента FundContainer
 * @interface FundContainerProps
 * @property {number|string} fundId - Идентификатор фонда
 * @property {Fund[]} funds - Список всех фондов
 */
interface FundContainerProps {
  fundId: number | string;
  funds: Fund[];
}

/**
 * Интерфейс состояния компонента FundContainer
 * @interface FundContainerState
 */
interface FundContainerState {
  isImportTransactionDialogOpen: boolean;
  accounts: string[];
  bank_accounts: BankAccount[];
  donation_stats: DonationStat[];
  justiceFilter: Filter | null;
  usageFilter: Filter | null;
  transactionsList: any[] | null;
  isNewBankAccountDialogOpen: boolean;

  currentAmount: number | null;
  currentAmounts: any[] | null;
  activeFundId: null | string | number;
  generation: number;
  loading: boolean;
  isSberModalOpen: boolean;
  query: string;

  // Обновленные поля для пагинации
  income: number;
  outcome: number;
  transactions: SberTransaction[];
  paginationMeta: PaginationMeta | null;
  mode: 'income' | 'outcome';
  activeTransacton: SberTransaction | null;
  // Параметры для API запросов
  page: number;
  perPage: number;
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  searchQuery: string;
}

/**
 * Компонент контейнера фонда
 *
 * Отображает информацию о фонде, включая баланс, транзакции, банковские счета.
 * Реализует серверную пагинацию и фильтрацию для оптимизации работы с большими объемами данных.
 */
export default class FundContainer extends React.Component<FundContainerProps, FundContainerState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isImportTransactionDialogOpen: false,
      accounts: [],
      bank_accounts: [],
      donation_stats: [],
      justiceFilter: null,
      usageFilter: null,
      transactionsList: null,
      isNewBankAccountDialogOpen: false,
      income: 0,
      outcome: 0,
      currentAmount: null,
      mode: 'outcome',
      transactions: [],
      paginationMeta: null,
      currentAmounts: null,
      activeFundId: localStorage.getItem('activeFundId')
        ? Number(localStorage.getItem('activeFundId'))
        : Number(props.fundId),
      generation: 0,
      loading: false,
      activeTransacton: null,
      isSberModalOpen: false,
      query: '',
      // Параметры для API запросов
      page: 1,
      perPage: 10,
      sortBy: 'date',
      sortDirection: 'desc',
      searchQuery: '',
    };

    this.getData = this.getData.bind(this);
    this.importUsingSber = this.importUsingSber.bind(this);
    this.updateFundData = this.updateFundData.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  /**
   * Жизненный цикл: компонент смонтирован
   * Загружает данные фонда при монтировании компонента
   */
  async componentDidMount() {
    if (this.state.activeFundId && !isNaN(Number(this.state.activeFundId))) {
      await this.updateFundData();
    }
  }

  /**
   * Обновляет данные фонда
   * Загружает банковские счета, статистику пожертвований и транзакции
   */
  async updateFundData() {
    await this.getBankAccounts();
    await this.getDonationStats();
    await this.getData();
  }

  /**
   * Устанавливает активный фонд и обновляет данные
   * @param {number|string} newId - Идентификатор нового активного фонда
   */
  setActiveFundId = (newId: number | string) => {
    localStorage.setItem('activeFundId', String(newId));
    this.setState({ activeFundId: newId }, () => {
      if (!isNaN(Number(newId))) {
        this.updateFundData().then(() => {});
      }
    });
  };

  /**
   * Получает транзакции с сервера с учетом пагинации и фильтрации
   * Использует параметры из состояния компонента для формирования запроса
   */
  async getData() {
    const { activeFundId, mode, page, perPage, sortBy, sortDirection, searchQuery } = this.state;

    this.setState({ loading: true });

    try {
      // Формируем параметры запроса
      const params = new URLSearchParams({
        page: page.toString(),
        per_page: perPage.toString(),
        type: mode,
        sort_by: sortBy,
        sort_direction: sortDirection,
      });

      // Добавляем параметр поиска, если он есть
      if (searchQuery) {
        params.append('q', searchQuery);
      }

      const response = await apiWrapper.get(
        `/funds/${activeFundId}/transactions?${params.toString()}`,
      );

      const data = response.data as PaginatedResponse<SberTransaction>;

      this.setState({
        income: data.meta.income_total,
        outcome: data.meta.outcome_total,
        transactions: data.transactions,
        paginationMeta: data.meta.pagination,
        loading: false,
      });
    } catch (error) {
      console.error('Error fetching transactions:', error);
      this.setState({ loading: false });
    }
  }

  /**
   * Обрабатывает изменение страницы в пагинации
   * @param {number} page - Номер новой страницы
   * @param {number} perPage - Количество элементов на странице
   */
  handlePageChange(page: number, perPage: number) {
    this.setState({ page, perPage }, this.getData);
  }

  /**
   * Обрабатывает изменение параметров сортировки
   * @param {string} sortBy - Поле для сортировки
   * @param {'asc'|'desc'} sortDirection - Направление сортировки
   */
  handleSortChange(sortBy: string, sortDirection: 'asc' | 'desc') {
    this.setState({ sortBy, sortDirection }, this.getData);
  }

  /**
   * Обрабатывает изменение поискового запроса
   * @param {string} searchQuery - Текст поискового запроса
   */
  handleSearchChange(searchQuery: string) {
    this.setState({ searchQuery, page: 1 }, this.getData);
  }

  /**
   * Обрабатывает изменение режима отображения (доходы/расходы)
   * @param {'income'|'outcome'} newMode - Новый режим отображения
   */
  handleModeChange = (newMode: 'income' | 'outcome') => {
    this.setState({ mode: newMode, page: 1 }, this.getData);
  };

  /**
   * Получает статистику пожертвований для фонда
   */
  getDonationStats = async (): Promise<void> => {
    let me = this;

    await apiWrapper
      .get(`/donation_stats/top?fund_id=${me.state.activeFundId}`)
      .then((response) => {
        this.setState({
          donation_stats: [...response.data],
        });
      });
  };

  /**
   * Открывает диалог импорта транзакций
   */
  handleOpenImportDialog = (): void => {
    this.setState({ isImportTransactionDialogOpen: true });
  };

  /**
   * Закрывает диалог импорта транзакций и обновляет данные
   */
  handleCloseImportDialog = (): void => {
    let me = this;
    me.setState(
      {
        isImportTransactionDialogOpen: false,
      },
      () => {
        me.getData().then(() => {
          me.setState({
            generation: me.state.generation + 1,
          });
        });
      },
    );
  };

  /**
   * Обрабатывает завершение импорта транзакций и обновляет данные
   */
  handleSubmitTransactionsImport = async (): Promise<void> => {
    let me = this;
    me.setState(
      {
        isImportTransactionDialogOpen: false,
      },
      () => {
        me.getData().then(() => {
          me.setState(
            {
              generation: me.state.generation + 1,
            },
            async () => {
              await this.getBankAccounts();
              await this.getDonationStats();
              await this.getData();
            },
          );
        });
      },
    );
  };

  /**
   * Получает банковские счета фонда
   */
  getBankAccounts = async (): Promise<void> => {
    let me = this;
    this.setState({ bank_accounts: [] });

    return new Promise((_resolve) => {
      apiWrapper.get(`/bank_accounts?fund_id=${me.state.activeFundId}`).then((response) => {
        this.setState(
          {
            bank_accounts: [...response.data],
          },
          () => {
            _resolve();
          },
        );
      });
    });
  };

  /**
   * Устанавливает фильтр для отчета Минюста
   * @param {Filter} newState - Новый фильтр
   */
  setJusticeFilter = (newState: Filter): void => {
    this.setState(
      {
        justiceFilter: {
          year: newState.year,
          q: newState.q,
          fund_id: newState.fund_id,
        },
      },
      async () => {
        if (this.state.justiceFilter?.fund_id) {
          await downloadMinjust(this.state.justiceFilter.fund_id);
        }
      },
    );
  };

  /**
   * Устанавливает фильтр для отчета по расходам
   * @param {Filter} newState - Новый фильтр
   */
  setUsageFilter = (newState: Filter): void => {
    this.setState(
      {
        usageFilter: {
          year: newState.year,
          q: newState.q,
          fund_id: newState.fund_id,
        },
      },
      async () => {
        if (this.state.usageFilter?.fund_id) {
          await downloadUsageReportFund(this.state.usageFilter.fund_id);
        }
      },
    );
  };

  /**
   * Импортирует транзакции через API Сбербанка
   */
  importUsingSber() {
    let me = this;
    me.setState({ loading: true }, () => {
      apiWrapper
        .get(`/bank_accounts/refresh_with_business_api?fund_id=${me.props.fundId}`)
        .then((_res: any) => {
          me.getData().then(() => {
            me.setState({ loading: false }, () => {
              toast(_res.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 5000,
                type: toast.TYPE.INFO,
                pauseOnHover: true,
              });
            });
          });
        });
    });
  }

  /**
   * Открывает модальное окно Сбербанка
   */
  openSberModal = () => {
    this.setState({ isSberModalOpen: true });
  };

  /**
   * Закрывает модальное окно Сбербанка
   */
  closeSberModal = () => {
    this.setState({ isSberModalOpen: false });
  };

  render() {
    const { isSberModalOpen, loading, transactions, paginationMeta, mode } = this.state;
    let me = this;
    let dataNotOk = false;
    let noDataEl = false;
    return (
      <>
        {/* Карточка с балансом и транзакциями */}
        <Card
          elevation={0}
          sx={{
            mt: 3,
            border: 1,
            borderColor: 'border.main',
            borderRadius: '0.5rem',
          }}
        >
          <CardHeader
            title={
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <AppCardTitle>Общий баланс поступлений и расходов</AppCardTitle>
                <AppBalanceDisplay
                  bankAccounts={this.state.bank_accounts}
                  isLoading={this.state.loading}
                  error={dataNotOk}
                />
                <div className={`flex flex-row items-center space-between space-x-2`}>
                  <div>
                    <AppSberButton onClick={this.openSberModal} isLoading={this.state.loading} />
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      size="medium"
                      color="appOrange"
                      onClick={this.handleOpenImportDialog}
                    >
                      Импортировать банковские транзакции
                      <i className="ml-2 fas fa-file-plus"></i>
                    </Button>
                  </div>
                  <div>
                    <AppExpandButton to={`/admin/balance?fund_id=${me.state.activeFundId}`} />
                  </div>
                </div>
              </Box>
            }
            sx={{ pb: 0 }}
          />
          <CardContent>
            {/* Табы переключения между доходами и расходами */}
            <AppTransactionsTabs
              mode={mode}
              onModeChange={this.handleModeChange}
              income={Number(this.state.income)}
              outcome={Number(this.state.outcome)}
            />

            <Box sx={{ mt: 2 }}>
              {/* Таблица транзакций с серверной пагинацией */}
              <AppTransactionsTableMini
                mode={mode}
                transactions={transactions}
                onRowClick={(transaction) => this.setState({ activeTransacton: transaction })}
                pagination={paginationMeta || undefined}
                onPageChange={this.handlePageChange}
                onSortChange={this.handleSortChange}
                onSearch={this.handleSearchChange}
                loading={loading}
              />
            </Box>
            {/* Модальное окно для просмотра детальной информации о транзакции */}
            <AppTransactionModal
              transaction={this.state.activeTransacton}
              open={!!this.state.activeTransacton}
              onClose={() => this.setState({ activeTransacton: null })}
            />
          </CardContent>
        </Card>

        {/* Диалог импорта транзакций */}
        <AppImportTransactionsDialog
          isOpen={this.state.isImportTransactionDialogOpen}
          onClose={this.handleCloseImportDialog}
          onSubmit={this.handleSubmitTransactionsImport}
          accounts={this.state.bank_accounts}
        />

        {/* Расчетные счета в банках */}
        <BankAccountsCard
          accounts={me.state.bank_accounts}
          fundId={Number(me.state.activeFundId)}
          onAddAccount={() => {
            console.log('added');
          }}
        />

        {/* Аналитические отчеты */}
        <AnalyticsReportsCard
          dataNotOk={dataNotOk}
          noDataEl={noDataEl}
          activeFundId={Number(me.state.activeFundId)}
          incomeTransactions={transactions.filter((t) => Number(t.amount) > 0)}
          outcomeTransactions={transactions.filter((t) => Number(t.amount) < 0)}
        />

        {!dataNotOk && (
          <div className={`w-full mt-3 bg-white p-3 rounded-xl`}>
            <div className="flex flex-row items-center justify-between">
              <div className="px-5 py-2 text-sm font-semibold text-white uppercase bg-primary rounded-xl">
                Нормативная отчетность
              </div>
            </div>

            <FundReportDownload activeFundId={Number(this.state.activeFundId)} />
          </div>
        )}

        {/* Заглушка модалки для сбера */}
        <SberBankStubModal open={isSberModalOpen} onClose={this.closeSberModal} />
      </>
    );
  }
}
