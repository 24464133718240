import React, { useState, useMemo } from 'react';
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Chip,
  Tooltip,
  Button,
  LinearProgress,
} from '@mui/material';
import { BankAccount } from '../../../../../types/BankAccount';
import { SberTransaction } from '../../../../../types/SberTransaction';
import AppTransactionModal from '../../../Transactions/AppTransactionModal';
import AppTransactionsTableMini from '../../../Transactions/AppTransactionsTableMini';

interface TransactionsTabProps {
  transactions: SberTransaction[];
  filteredTransactions: SberTransaction[];
  filteredTotal: number;
  bankAccounts: BankAccount[];
  selectedAccountIds: number[];
  toggleAccountSelection: (accountId: number) => void;
  selectAllAccounts: () => void;
  deselectAllAccounts: () => void;
  getShortenedAccountNumber: (accountNumber: string) => string;
  isCikFilterEnabled: boolean;
  setIsCikFilterEnabled: (enabled: boolean) => void;
  transactionMode: 'income' | 'outcome';
  activeTransacton: SberTransaction | null;
  setActiveTransacton: (transaction: SberTransaction | null) => void;
  formatCurrency: (amount: number) => string;
  formatLargeNumber: (num: number) => string;
  total: number;
  MAX_ANNUAL_LIMIT: number;
  percentFilled: number;
  getLimitColor: (percent: number) => 'success' | 'warning' | 'error';
}

/**
 * Вкладка транзакций для отображения списка транзакций с фильтрацией по счетам и ЦИК
 */
const TransactionsTab: React.FC<TransactionsTabProps> = ({
  transactions,
  filteredTransactions,
  filteredTotal,
  bankAccounts,
  selectedAccountIds,
  toggleAccountSelection,
  selectAllAccounts,
  deselectAllAccounts,
  getShortenedAccountNumber,
  isCikFilterEnabled,
  setIsCikFilterEnabled,
  transactionMode,
  activeTransacton,
  setActiveTransacton,
  formatCurrency,
  formatLargeNumber,
  total,
  MAX_ANNUAL_LIMIT,
  percentFilled,
  getLimitColor,
}) => {
  // Состояние для локальной пагинации
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('date');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  // Создаем мета-данные для пагинации
  const paginationMeta = useMemo(() => {
    return {
      current_page: page,
      per_page: perPage,
      total_count: filteredTransactions.length,
      total_pages: Math.ceil(filteredTransactions.length / perPage),
    };
  }, [page, perPage, filteredTransactions.length]);

  // Отфильтрованные и отсортированные транзакции с учетом поискового запроса
  const searchedTransactions = useMemo(() => {
    // Сначала фильтруем
    let result = filteredTransactions;

    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      result = result.filter(
        (transaction) =>
          transaction.payer_name?.toLowerCase().includes(query) ||
          transaction.payee_name?.toLowerCase().includes(query) ||
          transaction.payment_purpose?.toLowerCase().includes(query) ||
          transaction.payer_inn?.toString().includes(query) ||
          transaction.payee_inn?.toString().includes(query) ||
          transaction.payer_account?.includes(query) ||
          transaction.payee_account?.includes(query) ||
          transaction.amount?.toString().includes(query),
      );
    }

    // Затем сортируем
    return result.slice().sort((a, b) => {
      let aValue: any;
      let bValue: any;

      // Определяем значения для сортировки в зависимости от выбранного поля
      switch (sortBy) {
        case 'date':
          aValue = a.date_of_execution || '';
          bValue = b.date_of_execution || '';
          break;
        case 'amount':
          aValue = Math.abs(Number(a.amount));
          bValue = Math.abs(Number(b.amount));
          break;
        case 'payer_name':
          aValue = a.payer_name || '';
          bValue = b.payer_name || '';
          break;
        case 'payee_name':
          aValue = a.payee_name || '';
          bValue = b.payee_name || '';
          break;
        case 'description':
          aValue = a.payment_purpose || '';
          bValue = b.payment_purpose || '';
          break;
        default:
          aValue = a[sortBy as keyof SberTransaction] || '';
          bValue = b[sortBy as keyof SberTransaction] || '';
      }

      // Применяем направление сортировки
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
      }

      // Для строковых значений
      const aStr = String(aValue).toLowerCase();
      const bStr = String(bValue).toLowerCase();

      return sortDirection === 'asc' ? aStr.localeCompare(bStr) : bStr.localeCompare(aStr);
    });
  }, [filteredTransactions, searchQuery, sortBy, sortDirection]);

  // Пагинированные транзакции
  const paginatedTransactions = useMemo(() => {
    const start = (page - 1) * perPage;
    const end = start + perPage;
    return searchedTransactions.slice(start, end);
  }, [searchedTransactions, page, perPage]);

  // Обработчик изменения страницы для AppTransactionsTableMini
  const handlePageChange = (newPage: number, newPerPage: number) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };

  // Обработчик изменения сортировки для AppTransactionsTableMini
  const handleSortChange = (newSortBy: string, newSortDirection: 'asc' | 'desc') => {
    // Маппинг между ключами API и ключами в компоненте
    const sortKeyMap: Record<string, string> = {
      date: 'date',
      amount: 'amount',
      payer_name: 'payer_name',
      payee_name: 'payee_name',
      description: 'payment_purpose',
    };

    // Используем маппинг или оригинальное значение
    const mappedSortBy = sortKeyMap[newSortBy] || newSortBy;

    setSortBy(mappedSortBy);
    setSortDirection(newSortDirection);
  };

  // Обработчик поиска для AppTransactionsTableMini
  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setPage(1); // Сбрасываем на первую страницу при поиске
  };

  return (
    <div className="p-0">
      {/* Bank accounts selection */}
      <div className="mb-4">
        <div className="flex items-center justify-between mb-2">
          <Typography variant="subtitle1">Банковские счета ({bankAccounts.length})</Typography>
          <div className="space-x-2">
            <Button
              size="small"
              variant="outlined"
              onClick={selectAllAccounts}
              disabled={selectedAccountIds.length === bankAccounts.length}
            >
              Выбрать все
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={deselectAllAccounts}
              disabled={selectedAccountIds.length === 0}
              color="error"
            >
              Снять выбор
            </Button>
          </div>
        </div>

        <div className="flex flex-wrap gap-2 mb-4">
          {bankAccounts.map((account) => (
            <Chip
              key={account.id}
              label={getShortenedAccountNumber(account.payment_account)}
              onClick={() => toggleAccountSelection(Number(account.id))}
              color={selectedAccountIds.includes(Number(account.id)) ? 'primary' : 'default'}
              variant={selectedAccountIds.includes(Number(account.id)) ? 'filled' : 'outlined'}
              className="cursor-pointer"
            />
          ))}
        </div>
      </div>

      {/* Limit progress indicator */}
      <div className="mt-2 mb-6">
        <div className="flex items-center justify-between mb-1">
          <div className="flex items-center">
            <Typography variant="subtitle1" className="font-medium">
              Лимит пожертвований
            </Typography>
            <Tooltip title="Максимальная сумма пожертвований в год - 43,3 млн. рублей">
              <i className="ml-2 fas fa-question-circle"></i>
            </Tooltip>
          </div>
          <Typography variant="body2" color="textSecondary">
            {formatCurrency(total)} / {formatLargeNumber(MAX_ANNUAL_LIMIT)}
          </Typography>
        </div>

        <div className="relative">
          <LinearProgress
            variant="determinate"
            value={percentFilled}
            color={getLimitColor(percentFilled)}
            sx={{
              height: 10,
              borderRadius: 5,
              backgroundColor: '#f0f0f0',
              '& .MuiLinearProgress-bar': {
                borderRadius: 5,
              },
            }}
          />
          <div className="absolute transform -translate-y-1/2 -right-1 top-1/2">
            {percentFilled >= 98 && (
              <div className="bg-red-100 text-red-800 text-xs font-medium px-2 py-0.5 rounded">
                Лимит!
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-between mt-1">
          <Typography variant="caption" color="textSecondary">
            0₽
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {formatLargeNumber(MAX_ANNUAL_LIMIT)}
          </Typography>
        </div>

        {percentFilled >= 90 && (
          <div className="p-2 mt-2 text-sm text-red-700 rounded bg-red-50">
            <strong>Внимание!</strong> Сумма пожертвований приближается к годовому лимиту или лимит
            уже превышен.
          </div>
        )}
      </div>

      {/* Transactions list */}
      <div className="flex items-center justify-between py-2">
        <Typography variant="subtitle1">Транзакции ({filteredTransactions.length})</Typography>
        <Typography variant="subtitle2">
          Общая сумма: <strong>{formatCurrency(filteredTotal)}</strong>
        </Typography>
      </div>

      {selectedAccountIds.length === 0 ? (
        <Typography color="textSecondary">
          Выберите хотя бы один банковский счет для отображения транзакций
        </Typography>
      ) : transactions.length > 0 ? (
        <Box>
          <div className="flex items-center mb-3">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCikFilterEnabled}
                  onChange={(e) => setIsCikFilterEnabled(e.target.checked)}
                  size="small"
                />
              }
              label={
                <span className="text-sm font-medium">
                  Проверка транзакций в адрес Центрального исполнительного комитета Партии
                  <Tooltip title="Фильтрует транзакции, где одной из сторон является ЦИК">
                    <i
                      className="ml-1 text-gray-400 fas fa-info-circle"
                      style={{ fontSize: '14px' }}
                    ></i>
                  </Tooltip>
                </span>
              }
            />
            {isCikFilterEnabled && (
              <div className="ml-2 text-xs bg-blue-50 text-blue-700 px-2 py-0.5 rounded">
                Отфильтровано: {filteredTransactions.length} из {transactions.length}
              </div>
            )}
          </div>

          <AppTransactionsTableMini
            mode={transactionMode}
            transactions={paginatedTransactions}
            onRowClick={(transaction) =>
              setActiveTransacton(activeTransacton?.id === transaction.id ? null : transaction)
            }
            pagination={paginationMeta}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange}
            onSearch={handleSearch}
          />
          <AppTransactionModal
            transaction={activeTransacton}
            open={!!activeTransacton}
            onClose={() => setActiveTransacton(null)}
          />
        </Box>
      ) : (
        <Typography color="textSecondary">Нет доступных транзакций по выбранным счетам</Typography>
      )}
    </div>
  );
};

export default TransactionsTab;
