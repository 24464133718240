import React, { useEffect, useState, useCallback, useContext } from 'react';
import Dashboard from '../../../containers/admin/Dashboard';
import { api } from '../../../utils/api';
import { Fund, FundType } from '../../../types/Fund';
import './account.scss';
import FundContainer from './FundContainer/FundContainer';
import AppFundTypeSelector from '../../../components/admin/AppFundTypeSelector';
import AppFundSelector from '../../../components/admin/AppFundSelector';
import { PageContext } from '../../../contexts/PageContext';

interface AdminAccountPageState {
  fundTypes: FundType[];
  selectedFundTypeId: string | null;
  activeFundId: string | null;
  loading: boolean;
}

const initialState: AdminAccountPageState = {
  fundTypes: [],
  selectedFundTypeId: null,
  activeFundId: null,
  loading: true,
};

const AdminAccountPage: React.FC = () => {
  const { setPageName } = useContext(PageContext);
  const [state, setState] = useState<AdminAccountPageState>(initialState);

  useEffect(() => {
    setPageName('Аккаунт субъекта РФ');
  }, [setPageName]);

  // Получение списка фондов с сервера и восстановление сохраненных значений
  const getFunds = useCallback(async () => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const response = await api.get('/funds/available_funds');
      const fundTypes = response.data as FundType[];

      // Получаем сохраненные значения из localStorage
      const savedFundType = localStorage.getItem('selectedFundType');
      const savedFundId = localStorage.getItem('activeFundId');

      let selectedTypeId: string | null = null;
      let selectedFundId: string | null = null;

      // Auto-select if only one fund type and one fund available
      if (fundTypes.length === 1) {
        const singleFundType = fundTypes[0];
        selectedTypeId = String(singleFundType.id);

        if (singleFundType.funds?.length === 1) {
          selectedFundId = String(singleFundType.funds[0].id);
        } else {
          // If multiple funds, use saved selection
          selectedFundId = savedFundId || null;
        }
      } else {
        // Multiple fund types, use saved selection
        selectedTypeId =
          (savedFundType && fundTypes.find((type) => String(type.id) === savedFundType)?.id) ||
          null;
        selectedFundId = savedFundId || null;
      }

      // Save auto-selected values to localStorage
      if (selectedTypeId) localStorage.setItem('selectedFundType', selectedTypeId);
      if (selectedFundId) localStorage.setItem('activeFundId', selectedFundId);

      setState((prev) => ({
        ...prev,
        fundTypes,
        activeFundId: savedFundId,
        selectedFundTypeId: selectedTypeId,
        loading: false,
      }));
    } catch (error) {
      setState((prev) => ({ ...prev, loading: false }));
    }
  }, []);

  // Загрузка данных при монтировании компонента
  useEffect(() => {
    getFunds();
  }, [getFunds]);

  // Обработчик выбора типа фонда
  const handleFundTypeSelect = useCallback((fundTypeId: string) => {
    localStorage.setItem('selectedFundType', fundTypeId);
    setState((prev) => ({
      ...prev,
      selectedFundTypeId: fundTypeId,
      activeFundId: null,
    }));
  }, []);

  // Обработчик выбора конкретного фонда
  const handleFundSelect = useCallback((fund: Fund | null) => {
    if (!fund) return;

    const fundId = String(fund.id);
    localStorage.setItem('activeFundId', fundId);
    setState((prev) => ({ ...prev, activeFundId: fundId }));
  }, []);

  // Мемоизированные вычисляемые значения
  const selectedFundType = state.fundTypes.find((type) => type.id === state.selectedFundTypeId);
  const availableFunds: Fund[] = selectedFundType?.funds ?? [];

  // Если идет загрузка, показываем только контейнер
  if (state.loading) {
    return <Dashboard content={<div className="min-h-screen bg-slate-200" />} />;
  }

  return (
    <Dashboard
      content={
        <div className="min-h-screen px-4 pt-10 pb-4 account-view bg-slate-200">
          <div className="mb-6 space-y-4">
            <AppFundTypeSelector
              fundTypes={state.fundTypes}
              selectedFundTypeId={state.selectedFundTypeId}
              onSelect={handleFundTypeSelect}
            />

            {state.selectedFundTypeId && (
              <AppFundSelector
                key={state.selectedFundTypeId}
                funds={availableFunds}
                selectedFundId={state.activeFundId}
                onSelect={handleFundSelect}
              />
            )}
          </div>

          {state.activeFundId ? (
            <FundContainer
              key={`fund-${state.activeFundId}`}
              fundId={state.activeFundId}
              funds={availableFunds}
            />
          ) : (
            <div className="text-lg font-semibold text-center text-gray-500">
              Для отображения данных выберите фонд
            </div>
          )}
        </div>
      }
    />
  );
};

export default AdminAccountPage;
