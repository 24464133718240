import React from 'react';
import { Breadcrumbs, Link as MuiLink, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface BreadcrumbItem {
  title: string;
  path?: string;
}

interface AppBreadcrumbsProps {
  items: BreadcrumbItem[];
}

const AppBreadcrumbs: React.FC<AppBreadcrumbsProps> = ({ items }) => {
  return (
    <Breadcrumbs
      separator={<i className="far fa-chevron-right" data-testid="NavigateNextIcon"></i>}
      sx={{
        '& .MuiBreadcrumbs-separator': {
          color: '#0F094F',
        },
      }}
      className="!mb-4"
    >
      {items.map((item, index) => {
        const isLast = index === items.length - 1;

        if (isLast) {
          return (
            <Typography
              key={item.title}
              sx={{
                fontSize: '1.125rem',
                color: '#0F094F',
                fontWeight: 500,
              }}
              className="cursor-default"
            >
              {item.title}
            </Typography>
          );
        }

        return (
          <MuiLink
            key={item.title}
            component={RouterLink}
            to={item.path || '#'}
            sx={{
              backgroundColor: '#B02A2A',
              color: '#FFFFFF',
              padding: '0.375rem 0.75rem',
              borderRadius: '0.75rem',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'none',
                backgroundColor: '#8B2121',
              },
            }}
          >
            {item.title}
          </MuiLink>
        );
      })}
    </Breadcrumbs>
  );
};

export default AppBreadcrumbs;
