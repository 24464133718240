import React, { Component, useContext, useEffect } from 'react';
import Dashboard from '../../../containers/admin/Dashboard';
import AppInput from '../../../components/shared/AppInputV2';
import AppMaskedInput from '../../../components/shared/AppMaskedInput';
import AppInputStatus from '../../../components/shared/AppInputStatus';
import { ApplicationForm, DonationAccountInfo } from '../../../types/DonationRequest';
import AppInnDialog from '../../../components/company/AppInnDialog';
import { getDataByInnEgrul, getDataByInnSbis } from '../../../utils/getDataByInnEgrul';
import { UserContext } from '../../../store/user';
import dayjs from 'dayjs';
import AppBankDialog from '../../../components/company/AppBankDialog';
import { apiWrapper } from '../../../utils/api';
import AppAgentDialog from '../../../components/company/AppAgentDialog';
import { Organization } from '../../../types/Organization';
import AppCombobox from '../../../components/shared/AppCombobox';
import AppLegalFormSelect from '../../../components/company/AppLegalFormSelect';
import {
  ACCOUNT_MASK_0,
  BIK_MASK_0,
  INN_MASK_0,
  KPP_MASK_0,
  OGRN_MASK_0,
} from '../../../const/InputMasks';
import AppFileInput from '../../../components/shared/AppFileInput';
import { errorToast } from '../../../utils/errorToast';
import { findLegalForm } from '../../../utils/findLegalForm';
// import AppTextarea from '../../../components/shared/AppTextarea';
import {
  BANK_ACCOUNT_REGEX,
  BIK_REGEX,
  INN_REGEX,
  KPP_REGEX,
  OGRN_REGEX,
} from '../../../const/RegexValidations';
import { PageContext } from '../../../contexts/PageContext';

interface NewDonationRequestProps {
  setPageName?: (name: string) => void;
}

class AppNewDonationRequestPage extends Component<NewDonationRequestProps, ApplicationForm> {
  static contextType = UserContext;
  context!: React.ContextType<typeof UserContext>;

  constructor(props: {}) {
    super(props);
    this.state = {
      donation_request: {
        amount: 0,
        fund_id: null,
        is_fund: false,
        purpose: '',
        is_report_needed: false,
        donation_account_info_attributes: {
          payment_account: '',
          corr_account: '',
          bik: '',
          bank: '',
        },
        donation_organization_info_attributes: {
          legal_form: '',
          legal_address: '',
          actual_address: '',
          inn: '',
          kpp: '',
          ogrn: '',
          registration_date: '',
          authorized_person: '',
          full_name: '',
          short_name: '',
          document: '',
        },
      },
      hasPurpose: false,
      step: 1,
      bankInputManual: true,
      isInnModalShown: false,
      isAgentModalShown: false,
      isBankModalShown: false,
      message: '',
      funds: [],
      validationData: null,
      errors: {},
      loading: false,
    };
  }

  async componentDidMount() {
    if (this.props.setPageName) {
      this.props.setPageName('Оставить заявку на пожертвование');
    }

    await apiWrapper.get('/funds').then((response) => {
      this.setState({ funds: response.data });
    });

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get('fund')) {
      this.setState((prevState) => ({
        donation_request: {
          ...prevState.donation_request,
          is_fund: true,
        },
      }));
    }
  }

  handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    const { name, value } = event.target;
    const keys = name.split('.');

    if (keys.length === 1) {
      this.setState((prevState) => ({
        donation_request: {
          ...prevState.donation_request,
          [name]: value,
        },
      }));
    } else if (keys.length === 2) {
      const [parent, key] = keys;

      this.setState((prevState) => ({
        donation_request: {
          ...prevState.donation_request,
          [parent]: {
            // @ts-ignore
            ...prevState.donation_request[parent],
            [key]: value,
          },
        },
      }));
    }

    if (name === 'donation_organization_info_attributes.inn' && value.length >= 10) {
      this.getValidationData(value);
    }
  };

  handleStepChange = (newStep: number): void => {
    if (!this.validateSteps()) return;

    if (newStep < 1 || newStep > 4) return;

    this.setState({ step: newStep });
  };

  handleBankInputMode = (mode: boolean): void => {
    this.setState({ bankInputManual: mode });
    this.setState({ step: this.state.step + 1 });

    if (!mode) this.handleOpenBankModal();
  };

  handleOpenInnModal = (): void => {
    this.setState({ isInnModalShown: true });
  };

  handleCloseInnModal = (): void => {
    this.setState({ isInnModalShown: false });
  };

  findDataByInnEgrul = async (searchInn: string): Promise<void> => {
    const res = await getDataByInnEgrul(searchInn);

    if (res?.rows[0]) {
      this.setState((prevState) => {
        let updatedAttributes = {
          ...prevState.donation_request.donation_organization_info_attributes,
        };

        updatedAttributes = {
          ...updatedAttributes,
          legal_form: updatedAttributes.legal_form,
          legal_address: res?.rows[0]?.a || updatedAttributes.legal_address,
          actual_address: res?.rows[0]?.a || updatedAttributes.actual_address,
          inn: res?.rows[0]?.i || updatedAttributes.inn,
          kpp: res?.rows[0]?.p || updatedAttributes.kpp,
          ogrn: res?.rows[0]?.o || updatedAttributes.ogrn,
          registration_date:
            dayjs(res?.rows[0]?.r, 'MM.DD.YYYY').format('YYYY-MM-DD') ||
            dayjs(updatedAttributes.registration_date).format('YYYY-MM-DD'),
          authorized_person: res?.rows[0]?.g || updatedAttributes.authorized_person,
          full_name: res?.rows[0]?.n || updatedAttributes.full_name,
          short_name: res?.rows[0]?.c || updatedAttributes.short_name,
          document: updatedAttributes.document,
        };

        return {
          donation_request: {
            ...prevState.donation_request,
            donation_organization_info_attributes: updatedAttributes,
          },
        };
      });
    }

    this.handleCloseInnModal();
  };

  getValidationData = async (searchInn: string): Promise<void> => {
    const res = await getDataByInnSbis(searchInn);

    if (res) {
      const data = res[0];

      this.setState((prevState) => {
        return {
          ...prevState,
          validationData: JSON.parse(JSON.stringify(data)),
        };
      });
    }
  };

  findDataByInnSbis = async (searchInn: string): Promise<void> => {
    const res = await getDataByInnSbis(searchInn);
    if (res) {
      const data = res[0];
      this.setState((prevState) => {
        let updatedAttributes = {
          ...prevState.donation_request.donation_organization_info_attributes,
        };

        updatedAttributes = {
          ...updatedAttributes,
          legal_form: findLegalForm(data?.company_full_name) || updatedAttributes.legal_form,
          inn: data?.inn || updatedAttributes.inn,
          kpp: data?.kpp || updatedAttributes.kpp,
          ogrn: data?.ogrn || updatedAttributes.ogrn,
          full_name: data?.company_full_name || updatedAttributes.full_name,
          short_name: data?.company_short_name || updatedAttributes.short_name,
          legal_address: data?.address_legal || updatedAttributes.legal_address,
          actual_address:
            data?.address_actual || data?.address_legal || updatedAttributes.actual_address,
          authorized_person: `${data?.director_surname} ${data?.director_name} ${data?.director_patronymic}`,
          registration_date: dayjs(data?.registration_date).format('YYYY-MM-DD'),
        };

        return {
          ...prevState,
          validationData: JSON.parse(JSON.stringify(data)),
          donation_request: {
            ...prevState.donation_request,
            donation_organization_info_attributes: updatedAttributes,
          },
        };
      });
    }
  };

  handleUseRegData = (): void => {
    const organization = { ...this.context?.organization };

    this.setState((prevState) => {
      let updatedAttributes = {
        ...prevState.donation_request.donation_organization_info_attributes,
      };

      if (organization) {
        updatedAttributes = {
          ...updatedAttributes,
          legal_form: organization.legal_form || updatedAttributes.legal_form,
          legal_address: organization.legal_address || updatedAttributes.legal_address,
          actual_address: organization.actual_address || updatedAttributes.actual_address,
          inn: organization.inn || updatedAttributes.inn,
          kpp: organization.kpp || updatedAttributes.kpp,
          ogrn: organization.ogrn || updatedAttributes.ogrn,
          registration_date: organization.registration_date || updatedAttributes.registration_date,
          authorized_person: organization.authorized_person || updatedAttributes.authorized_person,
          full_name: organization.full_name || updatedAttributes.full_name,
          short_name: organization.short_name || updatedAttributes.short_name,
          document: organization.document || updatedAttributes.document,
        };
      }

      return {
        donation_request: {
          ...prevState.donation_request,
          donation_organization_info_attributes: updatedAttributes,
        },
      };
    });
  };

  handleOpenBankModal = (): void => {
    this.setState({ isBankModalShown: true });
  };

  handleCloseBankModal = (): void => {
    this.setState({ isBankModalShown: false });
  };

  fillBankData = (bank_data: DonationAccountInfo): void => {
    if (bank_data) {
      this.handleCloseBankModal();

      this.setState((prevState) => {
        let updatedAttributes = {
          ...prevState.donation_request.donation_account_info_attributes,
          payment_account: bank_data.payment_account,
          corr_account: bank_data.corr_account,
          bik: bank_data.bik,
          bank: bank_data.bank,
        };

        return {
          donation_request: {
            ...prevState.donation_request,
            donation_account_info_attributes: updatedAttributes,
          },
        };
      });
    }
  };

  handleOpenAgentModal = (): void => {
    this.setState({ isAgentModalShown: true });
  };

  handleCloseAgentModal = (): void => {
    this.setState({ isAgentModalShown: false });
  };

  fillAgentData = (organization_data: Organization): void => {
    if (organization_data) {
      this.handleCloseAgentModal();

      this.setState((prevState) => {
        let updatedAttributes = {
          ...prevState.donation_request.donation_organization_info_attributes,
          full_name: organization_data.full_name,
          short_name: organization_data.short_name,
          inn: organization_data.inn,
          kpp: organization_data.kpp,
          ogrn: organization_data.ogrn,
          legal_address: organization_data.legal_address,
          actual_address: organization_data.actual_address,
          authorized_person: organization_data.authorized_person,
          registration_date: organization_data.registration_date,
          document: organization_data.document,
          phone: organization_data.phone,
          email: organization_data.email,
          legal_form: findLegalForm(organization_data.full_name),
        };

        return {
          donation_request: {
            ...prevState.donation_request,
            donation_organization_info_attributes: updatedAttributes,
          },
        };
      });
    }
  };

  handleSelectFund = (selectedFund: any): void => {
    this.setState((prevState) => ({
      donation_request: {
        ...prevState.donation_request,
        fund_id: Number(selectedFund.id),
      },
    }));
  };

  handleSelectLegalForm = (selectedLegalForm: string): void => {
    this.setState((prevState) => ({
      ...prevState,
      donation_request: {
        ...prevState.donation_request,
        donation_organization_info_attributes: {
          ...prevState.donation_request.donation_organization_info_attributes,
          legal_form: selectedLegalForm,
        },
      },
    }));
  };

  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState((prevState) => ({
      donation_request: {
        ...prevState.donation_request,
        is_report_needed: event.target.checked,
      },
    }));
  };

  handlePurposeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState((prevState) => ({
      ...prevState,
      hasPurpose: event.target.checked,
    }));
  };

  handleSubmit = async (): Promise<void> => {
    if (!this.validateSteps()) return;

    this.setState({ loading: true }, async () => {
      await apiWrapper
        .post('/donation_requests', {
          donation_request: {
            amount: this.state.donation_request.amount,
            fund_id: this.state.donation_request.fund_id,
            is_fund: this.state.donation_request.is_fund,
            purpose: this.state.donation_request.purpose,
            is_report_needed: this.state.donation_request.is_report_needed,
            donation_account_info_attributes: {
              ...this.state.donation_request.donation_account_info_attributes,
            },
            donation_organization_info_attributes: {
              ...this.state.donation_request.donation_organization_info_attributes,
            },
          },
        })
        .then(async (response) => {
          this.setState({ message: response?.data?.message });
          this.setState({ step: 4 });

          if (this.state.charterFile) {
            const formData = new FormData();
            formData.append('file', this.state.charterFile);

            await apiWrapper.postFile(
              `/donation_requests/${response?.data.id}/upload_charter`,
              formData,
            );
          }

          if (this.state.decisionFile) {
            const formData = new FormData();
            formData.append('file', this.state.decisionFile);

            await apiWrapper.postFile(
              `/donation_requests/${response?.data.id}/upload_decision`,
              formData,
            );
          }

          if (this.state.minjustRegistrationFile) {
            const formData = new FormData();
            formData.append('file', this.state.minjustRegistrationFile);

            await apiWrapper.postFile(
              `/donation_requests/${response?.data.id}/upload_minjust_registration`,
              formData,
            );
          }

          if (this.state.taxRegistrationFile) {
            const formData = new FormData();
            formData.append('file', this.state.taxRegistrationFile);

            await apiWrapper.postFile(
              `/donation_requests/${response?.data.id}/upload_tax_registration`,
              formData,
            );
          }

          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log('post donation_requests', error);
        });
    });
  };

  handleFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    try {
      const files = event.target.files;
      if (files) {
        if (event.target.name === 'charterFile') this.setState({ charterFile: files[0] });
        if (event.target.name === 'decisionFile') this.setState({ decisionFile: files[0] });
        if (event.target.name === 'minjustRegistrationFile')
          this.setState({ minjustRegistrationFile: files[0] });
        if (event.target.name === 'taxRegistrationFile')
          this.setState({ taxRegistrationFile: files[0] });
      }
    } catch (e) {
      console.log(e);
      errorToast('Возникла ошибка. Что-то пошло не так');
    }
  };

  validateSteps = () => {
    // const { payment_account, corr_account, bik, bank } =
    //   this.state.donation_request.donation_account_info_attributes;

    if (this.state.step === 1) {
      const {
        inn,
        full_name,
        short_name,
        actual_address,
        legal_address,
        ogrn,
        kpp,
        registration_date,
        authorized_person,
      } = this.state.donation_request.donation_organization_info_attributes;

      if (
        !inn ||
        !full_name ||
        !short_name ||
        !actual_address ||
        !legal_address ||
        !ogrn ||
        !kpp ||
        !registration_date ||
        !authorized_person
      ) {
        this.setState({ step: 1 });
        errorToast('Заполните все обязательные поля');
        return false;
      }

      if (!INN_REGEX.test(inn)) {
        this.setState({ step: 1 });
        errorToast('ИНН: неправильный формат');
        return false;
      }

      if (!KPP_REGEX.test(kpp)) {
        this.setState({ step: 1 });
        errorToast('КПП: неправильный формат');
        return false;
      }

      if (!OGRN_REGEX.test(ogrn)) {
        this.setState({ step: 1 });
        errorToast('ОГРН: неправильный формат');
        return false;
      }

      // if (!this.state.minjustRegistrationFile) {
      //   this.setState({ step: 1 });
      //   errorToast('Выберите файл о регистрации в Минюсте');
      //   return false;
      // }

      // if (!this.state.taxRegistrationFile) {
      //   this.setState({ step: 1 });
      //   errorToast('Выберите файл о постановке на учет в ФНС');
      //   return false;
      // }
    }

    if (this.state.step === 2) {
      const { amount, fund_id, purpose } = this.state.donation_request;

      if (!fund_id) {
        this.setState({ step: 2 });
        errorToast('Получатель пожертвования: не выбран');
        return false;
      }

      if (!amount || amount <= 0) {
        this.setState({ step: 2 });
        errorToast('Сумма пожертвования: должна быть больше 0');
        return false;
      }

      if (this.state.hasPurpose && !purpose) {
        this.setState({ step: 2 });
        errorToast('Целевое назначение пожертвования: не заполнено');
        return false;
      }
    }

    if (this.state.step === 3) {
      const { payment_account, corr_account, bik, bank } =
        this.state.donation_request.donation_account_info_attributes;

      if (!payment_account || !corr_account || !bik || !bank) {
        this.setState({ step: 3 });
        errorToast('Банковские реквизиты: не заполнены');
        return false;
      }

      if (!BANK_ACCOUNT_REGEX.test(payment_account)) {
        this.setState({ step: 3 });
        errorToast('Банковский счет: неправильный формат');
        return false;
      }

      if (!BANK_ACCOUNT_REGEX.test(corr_account)) {
        this.setState({ step: 3 });
        errorToast('Корреспондентский счет: неправильный формат');
        return false;
      }

      if (!BIK_REGEX.test(bik)) {
        this.setState({ step: 3 });
        errorToast('БИК: неправильный формат');
        return false;
      }
    }

    return true;
  };

  render() {
    const { amount, purpose = '' } = this.state.donation_request;
    const { payment_account, corr_account, bik, bank } =
      this.state.donation_request.donation_account_info_attributes;
    const {
      legal_form,
      legal_address,
      actual_address,
      inn,
      kpp,
      ogrn,
      registration_date = '',
      authorized_person,
      full_name,
      short_name = '',
    } = this.state.donation_request.donation_organization_info_attributes;

    const { step, isInnModalShown, isBankModalShown, isAgentModalShown, message, funds } =
      this.state;

    if (this.state.loading) {
      return (
        <div
          className={`w-screen h-screen bg-primary z-10 flex flex-col items-center justify-center`}
        >
          <i className="text-white far fa-sync fa-spin fa-5x"></i>
          <p className={`text-center text-white font-semibold uppercase text-lg mt-10`}>Загрузка</p>
        </div>
      );
    }

    return (
      <Dashboard
        content={
          <div
            className={`bg-primary w-full overflow-y-scroll`}
            style={{ height: 'calc(100vh - 112px)' }}
          >
            <div className={`flex flex-col w-11/12 2xl:w-3/4 mx-auto gap-y-10 pt-10 h-full`}>
              <h1 className={`text-white uppercase text-2xl font-semibold mt-auto`}>
                Заявка на пожертвование
              </h1>

              <div
                className={`bg-[#F0F2F6] w-full rounded-t-2xl flex-1 flex flex-col justify-between`}
              >
                {step === 1 && (
                  <div>
                    <div
                      className={`grid grid-cols-12 space-x-2 px-5 pt-8 pb-5 items-center border-b border-b-[#C9C8D6]`}
                    >
                      <div className="flex flex-row flex-wrap items-center col-span-8 gap-x-2 gap-y-2">
                        <span className={`text-primary uppercase font-semibold text-2xl`}>
                          Заполните Данные
                        </span>
                        <button
                          className={`bg-[#B02A2A] text-white font-semibold text-base rounded-lg uppercase py-1 px-3 border-2 border-[#B02A2A]`}
                          aria-label={`Автозаполнение по ИНН`}
                          onClick={this.handleOpenInnModal}
                        >
                          <i className={`far fa-file-alt mr-3`}></i>
                          Автозаполнение по ИНН
                        </button>
                        <button
                          onClick={this.handleOpenAgentModal}
                          className={`bg-transparent text-primary font-semibold text-base rounded-lg uppercase py-1 px-3 border-2 border-primary`}
                        >
                          <i className={`far fa-file-alt mr-3`}></i>
                          загрузить файл
                        </button>
                        {!this.context?.is_moderator && (
                          <button
                            onClick={this.handleUseRegData}
                            className={`bg-transparent text-primary font-semibold text-base rounded-lg uppercase py-1 px-3 border-2 border-primary`}
                          >
                            <i className={`far fa-sign-in mr-3`}></i>
                            Использовать регистрационные данные
                          </button>
                        )}
                      </div>
                      <div className="col-span-4">
                        <p
                          className={`text-primary uppercase text-base font-semibold leading-tight`}
                        >
                          Результаты проверки сведений по ЕГРЮЛ
                        </p>
                      </div>
                    </div>
                    <div className={`grid grid-cols-12 px-5 mt-4 mb-2 space-x-2`}>
                      <div className="col-span-12 space-y-3">
                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-8">
                            <AppLegalFormSelect
                              selectedForm={legal_form}
                              onFormChange={this.handleInputChange}
                              name="donation_organization_info_attributes.legal_form"
                            />
                          </div>
                          <div className="col-span-4">
                            {this.state.validationData && <div className="h-[60px]"></div>}
                          </div>
                        </div>

                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-8">
                            <AppMaskedInput
                              id="donation_organization_info_attributes.inn"
                              name="donation_organization_info_attributes.inn"
                              label="ИНН"
                              type="text"
                              value={inn}
                              onChange={this.handleInputChange}
                              mask={INN_MASK_0}
                              fullWidth
                              color="primary"
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  backgroundColor: 'white',
                                },
                              }}
                            />
                          </div>
                          <div className="col-span-4">
                            {this.state.validationData && (
                              <AppInputStatus
                                refValue={this.state.validationData?.inn}
                                verifValue={inn}
                              />
                            )}
                          </div>
                        </div>

                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-8">
                            <AppMaskedInput
                              id="donation_organization_info_attributes.ogrn"
                              name="donation_organization_info_attributes.ogrn"
                              label="ОГРН"
                              type="text"
                              value={ogrn}
                              onChange={this.handleInputChange}
                              mask={OGRN_MASK_0}
                              fullWidth
                              color="primary"
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  backgroundColor: 'white',
                                },
                              }}
                            />
                          </div>
                          <div className="col-span-4">
                            {this.state.validationData && (
                              <AppInputStatus
                                refValue={this.state.validationData?.ogrn}
                                verifValue={ogrn}
                              />
                            )}
                          </div>
                        </div>

                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-8">
                            <AppMaskedInput
                              id="donation_organization_info_attributes.kpp"
                              name="donation_organization_info_attributes.kpp"
                              label="КПП"
                              type="text"
                              value={kpp}
                              onChange={this.handleInputChange}
                              mask={KPP_MASK_0}
                              fullWidth
                              color="primary"
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  backgroundColor: 'white',
                                },
                              }}
                            />
                          </div>
                          <div className="col-span-4">
                            {this.state.validationData && (
                              <AppInputStatus
                                refValue={this.state.validationData?.kpp}
                                verifValue={kpp}
                              />
                            )}
                          </div>
                        </div>

                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-8">
                            <AppInput
                              id="donation_organization_info_attributes.full_name"
                              name="donation_organization_info_attributes.full_name"
                              label="ПОЛНОЕ НАИМЕНОВАНИЕ"
                              type="text"
                              value={full_name}
                              onChange={this.handleInputChange}
                              fullWidth
                              color="primary"
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  backgroundColor: 'white',
                                },
                              }}
                            />
                          </div>
                          <div className="col-span-4">
                            {this.state.validationData && (
                              <AppInputStatus
                                refValue={this.state.validationData?.company_full_name}
                                verifValue={full_name}
                              />
                            )}
                          </div>
                        </div>

                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-8">
                            <AppInput
                              id="donation_organization_info_attributes.short_name"
                              name="donation_organization_info_attributes.short_name"
                              label="СОКРАЩЕННОЕ НАИМЕНОВАНИЕ"
                              type="text"
                              value={short_name}
                              onChange={this.handleInputChange}
                              fullWidth
                              color="primary"
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  backgroundColor: 'white',
                                },
                              }}
                            />
                          </div>
                          <div className="col-span-4">
                            {this.state.validationData && (
                              <AppInputStatus
                                refValue={this.state.validationData?.company_short_name}
                                verifValue={short_name}
                              />
                            )}
                          </div>
                        </div>

                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-8">
                            <AppInput
                              id="donation_organization_info_attributes.actual_address"
                              name="donation_organization_info_attributes.actual_address"
                              label="ФАКТИЧЕСКИЙ АДРЕС"
                              type="text"
                              value={actual_address}
                              onChange={this.handleInputChange}
                              fullWidth
                              color="primary"
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  backgroundColor: 'white',
                                },
                              }}
                            />
                          </div>
                          <div className="col-span-4">
                            {this.state.validationData && (
                              <AppInputStatus
                                refValue={
                                  this.state.validationData?.address_actual ||
                                  this.state.validationData?.address_legal
                                }
                                verifValue={actual_address}
                              />
                            )}
                          </div>
                        </div>

                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-8">
                            <AppInput
                              id="donation_organization_info_attributes.legal_address"
                              name="donation_organization_info_attributes.legal_address"
                              label="ЮРИДИЧЕСКИЙ АДРЕС"
                              type="text"
                              value={legal_address}
                              onChange={this.handleInputChange}
                              fullWidth
                              color="primary"
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  backgroundColor: 'white',
                                },
                              }}
                            />
                          </div>
                          <div className="col-span-4">
                            {this.state.validationData && (
                              <AppInputStatus
                                refValue={this.state.validationData?.address_legal}
                                verifValue={legal_address}
                              />
                            )}
                          </div>
                        </div>

                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-8">
                            <AppInput
                              id="donation_organization_info_attributes.registration_date"
                              name="donation_organization_info_attributes.registration_date"
                              label="ДАТА РЕГИСТРАЦИИ"
                              type="date"
                              value={registration_date}
                              onChange={this.handleInputChange}
                              fullWidth
                              color="primary"
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  backgroundColor: 'white',
                                },
                              }}
                            />
                          </div>
                          <div className="col-span-4">
                            {this.state.validationData && (
                              <AppInputStatus
                                refValue={this.state.validationData?.registration_date}
                                verifValue={dayjs(registration_date).format('YYYY-MM-DD')}
                              />
                            )}
                          </div>
                        </div>

                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-8">
                            <AppInput
                              id="donation_organization_info_attributes.authorized_person"
                              name="donation_organization_info_attributes.authorized_person"
                              label="УПОЛНОМОЧЕННОЕ ЛИЦО"
                              type="text"
                              value={authorized_person}
                              onChange={this.handleInputChange}
                              fullWidth
                              color="primary"
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  backgroundColor: 'white',
                                },
                              }}
                            />
                          </div>
                          <div className="col-span-4">
                            {this.state.validationData && (
                              <AppInputStatus
                                refValue={`${this.state.validationData?.director_surname} ${this.state.validationData?.director_name} ${this.state.validationData?.director_patronymic}`}
                                verifValue={authorized_person}
                              />
                            )}
                          </div>
                        </div>

                        {/* Bank account fields */}
                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-8">
                            <AppMaskedInput
                              id="donation_account_info_attributes.payment_account"
                              name="donation_account_info_attributes.payment_account"
                              label="РАСЧЕТНЫЙ СЧЕТ"
                              type="text"
                              value={payment_account}
                              onChange={this.handleInputChange}
                              mask={ACCOUNT_MASK_0}
                              fullWidth
                              color="primary"
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  backgroundColor: 'white',
                                },
                              }}
                            />
                          </div>
                          <div className="col-span-4"></div>
                        </div>

                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-8">
                            <AppMaskedInput
                              id="donation_account_info_attributes.corr_account"
                              name="donation_account_info_attributes.corr_account"
                              label="КОРРЕСПОНДЕНТСКИЙ СЧЕТ"
                              type="text"
                              value={corr_account}
                              onChange={this.handleInputChange}
                              mask={ACCOUNT_MASK_0}
                              fullWidth
                              color="primary"
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  backgroundColor: 'white',
                                },
                              }}
                            />
                          </div>
                          <div className="col-span-4"></div>
                        </div>

                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-8">
                            <AppMaskedInput
                              id="donation_account_info_attributes.bik"
                              name="donation_account_info_attributes.bik"
                              label="БИК"
                              type="text"
                              value={bik}
                              onChange={this.handleInputChange}
                              mask={BIK_MASK_0}
                              fullWidth
                              color="primary"
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  backgroundColor: 'white',
                                },
                              }}
                            />
                          </div>
                          <div className="col-span-4"></div>
                        </div>

                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-8">
                            <AppInput
                              id="bank"
                              name="donation_account_info_attributes.bank"
                              label="НАИМЕНОВАНИЕ БАНКА"
                              type="text"
                              value={bank}
                              onChange={this.handleInputChange}
                              fullWidth
                              color="primary"
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  backgroundColor: 'white',
                                },
                              }}
                            />
                          </div>
                          <div className="col-span-4"></div>
                        </div>

                        {/* File inputs */}
                        <div className="col-span-8">
                          <div
                            className={`my-2 bg-white border border-gray-300 rounded-lg px-4 py-3`}
                          >
                            <p className="mb-2 text-sm text-gray-500">
                              Выберите файл о регистрации в Минюсте (опционально)
                            </p>
                            <p className="mb-4 text-xs text-gray-400">
                              рекомендуемый размер файла не более 10 Мб (формат .pdf .jpg .jpeg
                              .png)
                            </p>
                            <AppFileInput
                              onChange={this.handleFile}
                              accept={'.pdf,.jpg,.jpeg,.png'}
                              name="minjustRegistrationFile"
                            />
                            {this.state.minjustRegistrationFile && (
                              <p className="text-sm text-gray-600 mt-2 ml-[42px]">
                                {this.state.minjustRegistrationFile.name}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-span-8">
                          <div
                            className={`my-2 bg-white border border-gray-300 rounded-lg px-4 py-3`}
                          >
                            <p className="mb-2 text-sm text-gray-500">
                              Выберите файл о постановке на учет в налоговой (опционально)
                            </p>
                            <p className="mb-4 text-xs text-gray-400">
                              рекомендуемый размер файла не более 10 Мб (формат .pdf .jpg .jpeg
                              .png)
                            </p>
                            <AppFileInput
                              onChange={this.handleFile}
                              accept={'.pdf,.jpg,.jpeg,.png'}
                              name="taxRegistrationFile"
                            />
                            {this.state.taxRegistrationFile && (
                              <p className="text-sm text-gray-600 mt-2 ml-[42px]">
                                {this.state.taxRegistrationFile.name}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-span-8">
                          <div
                            className={`my-2 bg-white border border-gray-300 rounded-lg px-4 py-3`}
                          >
                            <p className="mb-2 text-sm text-gray-500">
                              Выберите файл с уставом организации (опционально)
                            </p>
                            <p className="mb-4 text-xs text-gray-400">
                              рекомендуемый размер файла не более 10 Мб (формат .pdf .jpg .jpeg
                              .png)
                            </p>
                            <AppFileInput
                              onChange={this.handleFile}
                              accept={'.pdf,.jpg,.jpeg,.png'}
                              name="charterFile"
                            />
                            {this.state.charterFile && (
                              <p className="text-sm text-gray-600 mt-2 ml-[42px]">
                                {this.state.charterFile.name}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-span-8">
                          <div
                            className={`my-2 bg-white border border-gray-300 rounded-lg px-4 py-3`}
                          >
                            <p className="mb-2 text-sm text-gray-500">
                              Выберите файл с решением о назначении руководителя организации /
                              доверенность (опционально)
                            </p>
                            <p className="mb-4 text-xs text-gray-400">
                              рекомендуемый размер файла не более 10 Мб (формат .pdf .jpg .jpeg
                              .png)
                            </p>
                            <AppFileInput
                              onChange={this.handleFile}
                              accept={'.pdf,.jpg,.jpeg,.png'}
                              name="decisionFile"
                            />
                            {this.state.decisionFile && (
                              <p className="text-sm text-gray-600 mt-2 ml-[42px]">
                                {this.state.decisionFile.name}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {step === 2 && (
                  <div>
                    <div className={`grid grid-cols-12 px-5 pt-8 pb-5 items-center`}>
                      <div className="flex flex-row flex-wrap items-center col-span-12 gap-y-2">
                        <span className={`text-primary uppercase font-semibold text-2xl`}>
                          ДАННЫЕ ПОЖЕРТВОВАНИЯ
                        </span>
                      </div>
                      {funds && (
                        <div className={`col-span-12 mt-4 mb-2`}>
                          <AppCombobox
                            items={funds}
                            searchKey={'name'}
                            onUpdateValue={this.handleSelectFund}
                            placeholder={'Получатель пожертвования'}
                          />
                        </div>
                      )}

                      <div className="relative flex items-center col-span-12 mt-5">
                        <div className="flex items-center">
                          <input
                            id="hasPurpose"
                            name="hasPurpose"
                            type="checkbox"
                            className="w-8 h-8 border-2 border-gray-300 rounded focus:text-primary text-primary"
                            onChange={this.handlePurposeChange}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <span
                            id="comments-description"
                            className="text-lg font-semibold leading-tight uppercase text-gray-950"
                          >
                            Есть целевое назначение пожертвования
                          </span>
                        </div>
                      </div>

                      {this.state.hasPurpose && (
                        <div className={`col-span-12 mt-4 mb-2`}>
                          <AppInput
                            id="purpose"
                            name="purpose"
                            label="Целевое назначение пожертвования"
                            type="string"
                            value={purpose}
                            onChange={this.handleInputChange}
                            fullWidth={true}
                            color="primary"
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                backgroundColor: 'white',
                              },
                            }}
                          />
                        </div>
                      )}

                      <div className={`col-span-12 mt-4 mb-2`}>
                        <AppInput
                          id="amount"
                          name="amount"
                          label="Сумма пожертвования, руб"
                          type="number"
                          value={amount}
                          onChange={this.handleInputChange}
                          color="primary"
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              backgroundColor: 'white',
                            },
                          }}
                        />
                      </div>
                      <div className={`col-span-12 mt-4 mb-2`}>
                        <div className="relative flex items-center">
                          <div className="flex items-center">
                            <input
                              id="is_report_needed"
                              name="is_report_needed"
                              type="checkbox"
                              className="w-8 h-8 border-2 border-gray-300 rounded focus:text-primary text-primary"
                              onChange={this.handleCheckboxChange}
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <span
                              id="comments-description"
                              className="text-lg font-semibold leading-tight uppercase text-gray-950"
                            >
                              Нужен отчет о пожертвовании
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-row flex-wrap items-center col-span-12 mt-5 gap-y-2">
                        <span className={`text-primary uppercase font-semibold text-2xl`}>
                          Выберите способ заполнения данных банковских реквизитов
                        </span>
                      </div>
                      <div className={`col-span-12 lg:col-span-6 mt-4 mb-2`}>
                        <div
                          className={`flex flex-col flex-nowrap justify-center items-start gap-y-4`}
                        >
                          <div
                            className={`w-full bg-primary rounded-2xl flex flex-row items-center px-3 py-4 justify-start cursor-pointer`}
                            onClick={() => this.handleBankInputMode(true)}
                          >
                            <div className="text-center w-[50px]">
                              <i className="text-white fal fa-file-edit fa-2x"></i>
                            </div>
                            <div
                              className={`text-[#F0F2F7] font-bold text-xl text-center leading-tight ml-5`}
                            >
                              Ввести данные вручную
                            </div>
                          </div>
                          <div
                            className={`w-full bg-[#1A3256] rounded-2xl flex flex-row items-center px-3 py-4 justify-start cursor-pointer`}
                            onClick={() => this.handleBankInputMode(false)}
                          >
                            <div className="text-center w-[50px]">
                              <i className="text-white fas fa-long-arrow-alt-up fa-2x"></i>
                            </div>
                            <div
                              className={`text-[#F0F2F7] font-bold text-xl text-center leading-tight ml-5`}
                            >
                              Загрузка из документа
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {step === 3 && (
                  <div>
                    <div className={`grid grid-cols-12 space-x-2 px-5 pt-8 pb-5 items-center`}>
                      <div className="flex flex-row flex-wrap items-center col-span-12 gap-x-2 gap-y-2">
                        <span className={`text-primary uppercase font-semibold text-2xl`}>
                          Заполните данные
                        </span>
                      </div>
                    </div>
                    <div className={`grid grid-cols-12 px-5 mb-2 space-x-2`}>
                      <div className={`col-span-12 space-y-1.5`}>
                        <div className={`text-primary uppercase font-semibold pt-5`}>
                          БАНКОВСКИЕ РЕКВИЗИТЫ
                        </div>
                        <AppMaskedInput
                          id="payment_account"
                          name="donation_account_info_attributes.payment_account"
                          label="Расчетный счет"
                          type="text"
                          value={payment_account}
                          onChange={this.handleInputChange}
                          mask={ACCOUNT_MASK_0}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              backgroundColor: 'white',
                            },
                            '& .MuiInputLabel-root': {
                              color: '#1A3256',
                              '&.Mui-focused': {
                                color: '#1A3256',
                              },
                            },
                          }}
                        />
                        <AppMaskedInput
                          id="corr_account"
                          name="donation_account_info_attributes.corr_account"
                          label="Корреспондентский счет"
                          type="text"
                          value={corr_account}
                          onChange={this.handleInputChange}
                          mask={ACCOUNT_MASK_0}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              backgroundColor: 'white',
                            },
                            '& .MuiInputLabel-root': {
                              color: '#1A3256',
                              '&.Mui-focused': {
                                color: '#1A3256',
                              },
                            },
                          }}
                        />
                        <AppMaskedInput
                          id="bik"
                          name="donation_account_info_attributes.bik"
                          label="БИК"
                          type="text"
                          value={bik}
                          onChange={this.handleInputChange}
                          mask={BIK_MASK_0}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              backgroundColor: 'white',
                            },
                            '& .MuiInputLabel-root': {
                              color: '#1A3256',
                              '&.Mui-focused': {
                                color: '#1A3256',
                              },
                            },
                          }}
                        />
                        {/* Bank name input without mask */}
                        <AppInput
                          id="bank"
                          name="donation_account_info_attributes.bank"
                          label="НАИМЕНОВАНИЕ БАНКА"
                          type="text"
                          value={bank}
                          onChange={this.handleInputChange}
                          fullWidth
                        />
                      </div>
                    </div>
                  </div>
                )}

                {step === 4 && (
                  <div>
                    <div className={`flex flex-row items-center justify-center py-48 mx-auto`}>
                      <div
                        className={`bg-primary rounded-full h-36 w-36 flex items-center justify-center`}
                      >
                        <div
                          className={`bg-white rounded-full h-24 w-24 flex items-center justify-center`}
                        >
                          <i className="fas fa-check text-primary fa-4x"></i>
                        </div>
                      </div>
                      <div
                        className={`text-black font-semibold text-4xl leading-tight ml-10 w-3/5`}
                      >
                        {message}
                        <div className={`text-black text-base mt-5`}>
                          Результаты рассмотрения заявки будут направлены на указанную Вами
                          электронную почту в течение 3 рабочих дней
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* form footer */}
                <div className={`bg-white grid grid-cols-12 px-5 items-center py-2`}>
                  <div className={`col-span-4 xl:col-span-4`}>
                    <button
                      aria-label={`Назад`}
                      className={`rounded-full text-sm bg-[#F0F2F7] border-2 border-[#C9C8D6] text-[#8C89AC] uppercase px-3 py-2 xl:px-5 xl:py-3 font-semibold flex flex-row items-center`}
                      onClick={() => this.handleStepChange(step - 1)}
                    >
                      <i className={`far fa-chevron-left mr-2`}></i>
                      Назад
                    </button>
                  </div>
                  <div
                    className={`col-span-4 xl:col-span-4 text-center text-primary font-semibold text-xl xl:text-2xl`}
                  >
                    {step}/4
                  </div>
                  <div className={`col-span-4 xl:col-span-4`}>
                    {step < 3 && (
                      <button
                        aria-label={`Далее`}
                        className={`float-right text-sm rounded-full bg-[#F0F2F7] border-2 border-[#C9C8D6] text-[#8C89AC] uppercase px-3 py-2 xl:px-5 xl:py-3 font-semibold flex flex-row items-center`}
                        onClick={() => this.handleStepChange(step + 1)}
                      >
                        Далее
                        <i className={`far fa-chevron-right ml-2`}></i>
                      </button>
                    )}

                    {step === 1 && (
                      <button
                        aria-label={`Проверить`}
                        className={`mr-1 float-right text-sm rounded-full bg-[#F0F2F7] border-2 border-[#C9C8D6] text-[#8C89AC] uppercase px-3 py-2 xl:px-5 xl:py-3 font-semibold flex flex-row items-center`}
                        onClick={() => this.findDataByInnSbis(inn)}
                      >
                        Проверить
                      </button>
                    )}

                    {step === 3 && (
                      <button
                        className={`text-white float-right text-sm rounded-lg bg-primary uppercase px-3 py-2 xl:px-5 xl:py-3 font-semibold flex flex-row items-center`}
                        aria-label={`Отправить заявку на пожертвование`}
                        onClick={() => this.handleSubmit()}
                      >
                        Отправить заявку на пожертвование
                        <i className={`far fa-chevron-right ml-2`}></i>
                      </button>
                    )}

                    {step === 4 && (
                      <button
                        className={`text-white float-right text-sm rounded-lg bg-primary uppercase px-3 py-2 xl:px-5 xl:py-3 font-semibold flex flex-row items-center`}
                        aria-label={`Отправить заявку на пожертвование`}
                        onClick={() => window.location.replace('/')}
                      >
                        Вернуться
                        <i className={`far fa-chevron-right ml-2`}></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Dialogs */}
            <AppInnDialog
              isOpen={isInnModalShown}
              onClose={this.handleCloseInnModal}
              onSubmit={this.findDataByInnSbis}
            />

            <AppBankDialog
              isOpen={isBankModalShown}
              onClose={this.handleCloseBankModal}
              onSubmit={this.fillBankData}
            />

            <AppAgentDialog
              isOpen={isAgentModalShown}
              onClose={this.handleCloseAgentModal}
              onSubmit={this.fillAgentData}
            />
          </div>
        }
      ></Dashboard>
    );
  }
}

const AppNewDonationRequestWithPageContext: React.FC = () => {
  const { setPageName } = useContext(PageContext);

  useEffect(() => {
    setPageName('Оставить заявку на пожертвование');
  }, [setPageName]);

  return <AppNewDonationRequestPage setPageName={setPageName} />;
};

export default AppNewDonationRequestWithPageContext;
