import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Chip,
  LinearProgress,
} from '@mui/material';
import LimitCharts from '../../LimitCharts';

interface FundAnalysisTabProps {
  fundStatus: any;
  donorTypeDistribution: any[];
  INDIVIDUAL_LIMIT: number;
  CORPORATE_LIMIT: number;
  formatCurrency: (amount: number) => string;
  getLimitColor: (percent: number) => 'success' | 'warning' | 'error';
}

const FundAnalysisTab: React.FC<FundAnalysisTabProps> = ({
  fundStatus,
  donorTypeDistribution,
  INDIVIDUAL_LIMIT,
  CORPORATE_LIMIT,
  formatCurrency,
  getLimitColor,
}) => {
  if (!fundStatus) {
    return (
      <div className="flex justify-center items-center h-[300px]">
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box>
      <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-2">
        <Card>
          <CardContent>
            <Typography variant="h6" className="mb-2">
              Общая сумма пожертвований
            </Typography>

            <div className="flex justify-between mb-1">
              <Typography variant="body1" fontWeight="bold">
                {formatCurrency(fundStatus.totals.current)}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                из {formatCurrency(fundStatus.totals.limit)}
              </Typography>
            </div>

            <LinearProgress
              variant="determinate"
              value={fundStatus.totals.percentage}
              color={getLimitColor(fundStatus.totals.percentage)}
              sx={{ height: 10, borderRadius: 5 }}
            />

            <Typography variant="body2" className="mt-1 text-right">
              {fundStatus.totals.percentage.toFixed(1)}%
            </Typography>
          </CardContent>
        </Card>

        <div>
          <LimitCharts
            type="donor_distribution"
            title="Распределение пожертвований"
            data={donorTypeDistribution}
            height={200}
            formatCurrency={formatCurrency}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {/* Top individual donors */}
        <Card>
          <CardContent>
            <Typography variant="h6" className="mb-2">
              ТОП-5 физических лиц
            </Typography>

            <div className="max-h-[300px] overflow-y-auto">
              {fundStatus.top_individual_donors.slice(0, 5).map((donor: any, idx: number) => (
                <div key={idx} className="pb-2 mb-2 border-b">
                  <Typography variant="body2">
                    {donor.name || 'Аноним'}{' '}
                    {donor.limit_exceeded && (
                      <Chip label="Превышение" size="small" color="error" className="ml-2" />
                    )}
                  </Typography>

                  <div className="flex justify-between">
                    <Typography variant="body2" color="textSecondary">
                      {donor.account || donor.inn || '—'}
                    </Typography>
                    <Typography variant="body2" fontWeight="bold">
                      {formatCurrency(donor.total_amount)}
                    </Typography>
                  </div>

                  <LinearProgress
                    variant="determinate"
                    value={(donor.total_amount / INDIVIDUAL_LIMIT) * 100}
                    color={getLimitColor((donor.total_amount / INDIVIDUAL_LIMIT) * 100)}
                    sx={{ height: 4, borderRadius: 5, mt: 1 }}
                  />
                </div>
              ))}

              {fundStatus.top_individual_donors.length === 0 && (
                <Typography variant="body2" color="textSecondary" className="py-3 text-center">
                  Нет данных о пожертвованиях физических лиц
                </Typography>
              )}
            </div>
          </CardContent>
        </Card>

        {/* Top corporate donors */}
        <Card>
          <CardContent>
            <Typography variant="h6" className="mb-2">
              ТОП-5 юридических лиц
            </Typography>

            <div className="max-h-[300px] overflow-y-auto">
              {fundStatus.top_corporate_donors.slice(0, 5).map((donor: any, idx: number) => (
                <div key={idx} className="pb-2 mb-2 border-b">
                  <Typography variant="body2">
                    {donor.name || 'Аноним'}{' '}
                    {donor.limit_exceeded && (
                      <Chip label="Превышение" size="small" color="error" className="ml-2" />
                    )}
                  </Typography>

                  <div className="flex justify-between">
                    <Typography variant="body2" color="textSecondary">
                      {donor.account || donor.inn || '—'}
                    </Typography>
                    <Typography variant="body2" fontWeight="bold">
                      {formatCurrency(donor.total_amount)}
                    </Typography>
                  </div>

                  <LinearProgress
                    variant="determinate"
                    value={(donor.total_amount / CORPORATE_LIMIT) * 100}
                    color={getLimitColor((donor.total_amount / CORPORATE_LIMIT) * 100)}
                    sx={{ height: 4, borderRadius: 5, mt: 1 }}
                  />
                </div>
              ))}

              {fundStatus.top_corporate_donors.length === 0 && (
                <Typography variant="body2" color="textSecondary" className="py-3 text-center">
                  Нет данных о пожертвованиях юридических лиц
                </Typography>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    </Box>
  );
};

export default FundAnalysisTab;
