import React from 'react';
import { Card, CardContent, Typography, CircularProgress } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as ChartTooltip,
  Legend as ChartLegend,
  ArcElement,
  ChartOptions,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  ChartTooltip,
  ChartLegend,
);

interface RegionalBranchItem {
  name: string;
  fullName: string;
  value: number;
  limit: number;
  limitPercentage: number;
}

interface DonorDistributionItem {
  name: string;
  value: number;
}

interface LimitChartsProps {
  type: 'regional_branches' | 'donor_distribution';
  title: string;
  data: RegionalBranchItem[] | DonorDistributionItem[];
  loading?: boolean;
  height?: number;
  formatCurrency: (amount: number) => string;
}

const LimitCharts: React.FC<LimitChartsProps> = ({
  type,
  title,
  data,
  loading = false,
  height = 350,
  formatCurrency,
}) => {
  // Define chart colors
  const chartColors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

  // Bar chart options
  const barChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            if (type !== 'regional_branches') return tooltipItems[0].label;

            const index = tooltipItems[0].dataIndex;
            const branches = data as RegionalBranchItem[];
            return branches[index]?.fullName || '';
          },
          label: function (context) {
            return formatCurrency(context.raw as number);
          },
          footer: function (tooltipItems) {
            if (type !== 'regional_branches') return '';

            const index = tooltipItems[0].dataIndex;
            const branches = data as RegionalBranchItem[];
            const branch = branches[index] as RegionalBranchItem;
            return branch ? `${branch.limitPercentage.toFixed(1)}% от лимита` : '';
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return new Intl.NumberFormat('ru-RU', {
              notation: 'compact',
              compactDisplay: 'short',
            }).format(Number(value));
          },
        },
      },
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 45,
        },
      },
    },
  };

  // Pie chart options
  const pieChartOptions: ChartOptions<'pie'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || '';
            const value = context.raw as number;

            // Calculate total manually from all dataset values
            const dataset = context.chart.data.datasets[0];
            const total = Array.isArray(dataset.data)
              ? dataset.data.reduce((sum: number, val: any) => sum + (Number(val) || 0), 0)
              : 0;

            const percentage = total > 0 ? Math.round((value / total) * 100) : 0;
            return `${label}: ${formatCurrency(value)} (${percentage}%)`;
          },
        },
      },
    },
  };

  const prepareRegionalBranchesData = () => {
    const branches = data as RegionalBranchItem[];

    return {
      labels: branches.map((branch) => branch.name),
      datasets: [
        {
          label: 'Сумма пожертвований',
          data: branches.map((branch) => branch.value),
          backgroundColor: '#0088FE',
          borderColor: '#0066BB',
          borderWidth: 1,
          barPercentage: 0.7,
        },
      ],
    };
  };

  const prepareDonorDistributionData = () => {
    const donors = data as DonorDistributionItem[];

    return {
      labels: donors.map((donor) => donor.name),
      datasets: [
        {
          data: donors.map((donor) => donor.value),
          backgroundColor: donors.map((_, index) => chartColors[index % chartColors.length]),
          borderColor: donors.map((_, index) =>
            chartColors[index % chartColors.length].replace('FE', 'BB'),
          ),
          borderWidth: 1,
        },
      ],
    };
  };

  const renderChart = () => {
    if (loading) {
      return (
        <div className="flex items-center justify-center h-full">
          <CircularProgress />
        </div>
      );
    }

    if (!data || data.length === 0) {
      return (
        <div className="flex items-center justify-center h-full">
          <Typography variant="body2" color="textSecondary">
            Нет данных для отображения
          </Typography>
        </div>
      );
    }

    if (type === 'regional_branches') {
      return <Bar data={prepareRegionalBranchesData()} options={barChartOptions} />;
    }

    if (type === 'donor_distribution') {
      return <Pie data={prepareDonorDistributionData()} options={pieChartOptions} />;
    }

    return null;
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" className="mb-3">
          {title}
        </Typography>
        <div style={{ height }}>{renderChart()}</div>
      </CardContent>
    </Card>
  );
};

export default LimitCharts;
