import React from 'react';
import { Button, ButtonProps, styled } from '@mui/material';

interface AppSberButtonProps extends Omit<ButtonProps, 'onClick'> {
  onClick: () => void;
  isLoading?: boolean;
}

const StyledButton = styled(Button)(({ theme }) => ({
  height: 32,
  minWidth: 32,
  padding: theme.spacing(0, 1),
  borderRadius: theme.shape.borderRadius * 1.5,
  '&:hover': {
    backgroundColor: 'rgba(17, 191, 106, 0.04)',
  },
  '&.loading': {
    animation: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
  },
  '@keyframes pulse': {
    '0%, 100%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.5,
    },
  },
}));

const AppSberButton: React.FC<AppSberButtonProps> = ({ onClick, isLoading, ...props }) => {
  return (
    <StyledButton
      variant="text"
      className={isLoading ? 'loading' : ''}
      onClick={onClick}
      // onClick={importUsingSber}
      {...props}
    >
      <img
        src={`${process.env.PUBLIC_URL}/logos/logo-sber.png`}
        alt="sber.svg"
        style={{ height: '80%', width: 'auto' }}
      />
    </StyledButton>
  );
};

export default AppSberButton;
