import React, { useState, useContext, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Drawer,
  List,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Typography,
  Box,
  Divider,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../store/user';
import theme from '../../../theme/theme';
import AppSignOutBtn from '../AppSignOutBtn';

const DRAWER_WIDTH = 300;

interface MenuItem {
  to: string;
  title: string;
  icon: string;
}

const AppDrawerNavigation = () => {
  const { role, is_authed, email, is_moderator } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const menuItemsForModerator = useMemo(
    () => [
      { to: '/admin', title: 'Обработка заявок', icon: 'far fa-tasks' },
      { to: '/admin/summary_registry', title: 'Сводный реестр', icon: 'far fa-folder-open' },
      { to: '/admin/limits', title: 'Проверка лимитов', icon: 'far fa-folder-open' },
      { to: '/admin/debt', title: 'Учет долговых обязательств', icon: 'far fa-folder-open' },
      { to: '/admin/account', title: 'Аккаунт субъекта РФ', icon: 'far fa-chart-area' },
    ],
    [],
  );

  const menuItemsForOrganization = useMemo(
    () => [
      { to: '/', title: 'Главная страница', icon: 'far fa-home' },
      { to: '/my_applications', title: 'Заявки на пожертвование', icon: 'far fa-history' },
      { to: '/new_application', title: 'Оставить заявку на пожертвование', icon: 'far fa-edit' },
      { to: '/account', title: 'Профиль', icon: 'far fa-user' },
    ],
    [],
  );

  const roleBasedMenu = useMemo(
    () => ({
      to: '/admin/transactions_map',
      title: 'Карта транзакций',
      icon: 'far fa-map',
    }),
    [],
  );

  const handleToggleDrawer = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const isActive = useCallback(
    (path: string) => {
      return location.pathname === path;
    },
    [location.pathname],
  );

  const renderMenuItem = useCallback(
    ({ to, title, icon }: MenuItem) => {
      const active = isActive(to);

      return (
        <ListItemButton
          role="button"
          key={to}
          component={Link}
          to={to}
          sx={{
            textTransform: 'uppercase',
            backgroundColor: active ? theme.palette.appOrange.main : 'transparent',
            '&:hover': {
              backgroundColor: theme.palette.appOrange.light,
            },
          }}
        >
          <ListItemIcon>
            <i
              className={`text-lg ${icon}`}
              style={{
                color: active ? theme.palette.appOrange.contrastText : 'inherit',
              }}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              sx={{
                fontWeight: '600',
                color: active ? theme.palette.appOrange.contrastText : 'inherit',
              }}
            >
              {title}
            </Typography>
          </ListItemText>
        </ListItemButton>
      );
    },
    [isActive],
  );

  return is_authed ? (
    <>
      <Button
        role="button"
        aria-label="menu"
        sx={{ background: 'transparent', color: 'white' }}
        size="large"
        onClick={handleToggleDrawer}
      >
        <i className="far fa-bars fa-2x" />
      </Button>

      <Drawer
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { width: DRAWER_WIDTH },
        }}
        slots={{
          backdrop: () => (
            <div
              data-testid="backdrop"
              onClick={handleClose}
              className="MuiBackdrop-root"
              style={{
                position: 'fixed',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                right: 0,
                bottom: 0,
                top: 0,
                left: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                WebkitTapHighlightColor: 'transparent',
                zIndex: -1,
              }}
            />
          ),
        }}
      >
        <Box
          role="presentation"
          onClick={handleClose}
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          {is_authed && (
            <Box
              sx={{
                p: 2,
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                textAlign: 'center',
              }}
              className="!cursor-default"
            >
              <Typography variant="caption">Вы вошли под учетной записью</Typography>
              <Typography variant="h6" className="!text-sm">
                {email}
              </Typography>
            </Box>
          )}
          <Divider />
          <List>
            {is_moderator
              ? menuItemsForModerator.map(renderMenuItem)
              : menuItemsForOrganization.map(renderMenuItem)}
            {role === 'federal' && renderMenuItem(roleBasedMenu)}
          </List>
          {is_authed && (
            <Box sx={{ p: 2, mt: 'auto' }}>
              <AppSignOutBtn />
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  ) : (
    <> </>
  );
};

export default React.memo(AppDrawerNavigation);
