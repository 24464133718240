import { toast } from 'react-toastify';
import { apiWrapper } from './api';

export const uploadDonationRequestFile = async (
  id: number,
  file: File,
  upload_type: string,
  uploaded_by: string,
): Promise<any> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('upload_type', upload_type);
  formData.append('uploaded_by', uploaded_by);

  await apiWrapper.postFile(`/donation_requests/${id}/upload`, formData).then((response: any) => {
    toast(response?.data.message, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 5000,
      type: toast.TYPE.INFO,
      pauseOnHover: true,
    });
  });
};

export const downloadUploading = async (id: number): Promise<any> => {
  await apiWrapper.get(`/donation_requests/${id}/download`).then((response) => {
    const fileData = atob(response.data.file_data);
    const byteArray = new Uint8Array(fileData.length);
    for (let i = 0; i < fileData.length; i++) {
      byteArray[i] = fileData.charCodeAt(i);
    }

    const blob = new Blob([byteArray.buffer], {
      type: 'application/octet-stream',
    });

    // Create a link element
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = response.data.file_name;
    document.body.appendChild(a);
    a.click();
    a.remove();
  });
};
