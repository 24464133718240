import React, { Component } from 'react';
import { apiWrapper } from '../../../utils/api';
import './app-table.scss';
import TagsContainer from './TagsContainer';
import { TransactionCategory } from '../../../types/TransactionCategory';
import { FormControl, InputLabel, MenuItem, Select, IconButton } from '@mui/material';

interface AppTableProps {
  headers: {
    localize?: boolean;
    name: string;
    title: string;
    align: string;
    tagView?: boolean;
    enableSort?: boolean;
    width?: string;
  }[];
  // data: { [key: string]: any }[];
  onChange?: (_signal: string, _dStart: string, _dEnd: string, inn: string) => void;
  onClick?: (_data: any, _dataIndex: number) => void;
  data: any[];
  selectable?: boolean;
  enableSort?: boolean;
  categories?: TransactionCategory[];
  onCategoryChange?: (transactionId: number, categoryIds: number[]) => void;
  tableClass?: string;
}

interface AppTableState {
  sortMode: 'asc' | 'desc';
  sortField: any | null;
  items: any[];
  selectedCategories: { [transactionId: number]: number[] };
}

class AppTable extends Component<AppTableProps, AppTableState> {
  constructor(_props: AppTableProps) {
    super(_props);

    this.state = {
      items: [],
      sortField: null,
      sortMode: 'asc',
      selectedCategories: {},
    };

    this.setData = this.setData.bind(this);
  }

  componentDidMount() {
    this.setData(this.props.data);
  }

  parseDateFromString(_str: string) {
    if (_str.indexOf('.') > -1) {
      let dateArr = _str.split('.');
      return new Date(Date.parse([dateArr[1], dateArr[0], dateArr[2]].join('-')));
    }
    let dateArr = _str.split('-');
    return new Date(Date.parse([dateArr[0], dateArr[1], dateArr[2]].join('-')));
  }

  setData(_data: any[]) {
    let me = this;
    let sortedData = _data;
    if (this.props.enableSort && this.state.sortField !== null) {
      sortedData = _data.sort((_a: any, _b: any) => {
        if (!me.state.sortField) return 0;

        let valA = _a[me.state.sortField.name];
        let valB = _b[me.state.sortField.name];

        if (me.state.sortField.is_number) {
          valA = parseFloat(valA);
          valB = parseFloat(valB);
        }

        if (me.state.sortField.is_date) {
          valA = me.parseDateFromString(valA);
          valB = me.parseDateFromString(valB);
        }

        if (valA === valB) return 0;

        let whatToDoIfLarger = this.state.sortMode === 'asc' ? 1 : -1;

        return _a[me.state.sortField.name] < _b[me.state.sortField.name]
          ? whatToDoIfLarger
          : -whatToDoIfLarger;
      });
    }

    const selectedCategories = {};

    sortedData.forEach((item) => {
      // @ts-ignore
      selectedCategories[item.id] = item.transaction_categories
        ? item.transaction_categories.map((cat: TransactionCategory) => cat.id)
        : [];
    });

    me.setState({
      items: sortedData,
      selectedCategories,
    });
  }

  render() {
    let me = this;
    const { headers, tableClass } = this.props;

    return (
      <table className={`app-table w-full text-[#2C2C2C] ${!!tableClass ? tableClass : ''}`}>
        <thead className={`text-center font-semibold uppercase text-xs`}>
          <tr className="border-b border-[#CCC]">
            {me.props.selectable ? <th></th> : <></>}
            <th className={`py-1.5 px-2`} style={{ minWidth: '60px' }}>
              П/П
            </th>
            {headers.map((header, index) => (
              <th
                className={`py-1.5 px-2`}
                style={{
                  position: 'relative',
                  fontWeight: me.state.sortField === header ? 900 : undefined,
                  cursor: me.props.enableSort ? 'pointer' : 'default',
                  width: header.width,
                }}
                onClick={() => {
                  // alert(header.title);
                  if (me.state.sortField && me.state.sortField.name === header.name) {
                    me.setState(
                      {
                        sortMode: me.state.sortMode === 'asc' ? 'desc' : 'asc',
                      },
                      () => {
                        me.setData(me.state.items);
                      },
                    );
                  } else {
                    me.setState({ sortField: header, sortMode: 'asc' }, () => {
                      me.setData(me.state.items);
                    });
                  }
                }}
                key={index}
              >
                <div className={'flex flex-row items-center justify-center content-center w-full'}>
                  {me.state.sortField && me.state.sortField.name === header.name ? (
                    <span className={'relative'}>
                      <i
                        style={{ opacity: 0.2 }}
                        className={'absolute top-0 left-0 fal fa-2x fa-sort-up'}
                      ></i>
                      <i
                        style={{ opacity: 0.2 }}
                        className={'absolute top-0 left-0 fal fa-2x fa-sort-down'}
                      ></i>
                      {me.state.sortMode === 'asc' ? (
                        <i className={'sort-mode fal fa-2x fa-sort-up'}></i>
                      ) : (
                        <i className={'sort-mode fal fa-2x fa-sort-down'}></i>
                      )}
                    </span>
                  ) : (
                    <></>
                  )}
                  {/*{me.state.sortField &&  me.state.sortField === header ? <br/> : <></>}*/}
                  <span>{header.title}</span>
                </div>
              </th>
            ))}
            {me.props.categories && (
              <th className={`py-1.5 px-2`} style={{ minWidth: '200px' }}>
                Категории транзакции
              </th>
            )}
          </tr>
        </thead>
        <tbody key={`${JSON.stringify(me.state.items)}-${me.state.sortField}-${me.state.sortMode}`}>
          {me.state.items.map((item: any, index: number) => (
            <tr
              key={`item-${index}-${headers.join(';')}`}
              className={`odd:bg-[#E8ECF4]`}
              style={{ fontSize: '12px', cursor: me.props.onClick ? 'pointer' : undefined }}
              onClick={() => {
                if (me.props.onClick) me.props.onClick(item, index);
              }}
            >
              {me.props.selectable && (
                <td>
                  <input type={'checkbox'} style={{ margin: '5px' }} />
                </td>
              )}
              <td className={`py-1.5 px-2 text-center`}>{index + 1}</td>
              {headers.map((header) => (
                <td className={`py-1.5 px-2 text-${header.align || 'center'}`} key={header.name}>
                  {header.name === 'file_id_and_transaction_id' && (
                    <span>{`${item?.other_data?.sber_upload_file_id}.${item.id}`}</span>
                  )}
                  {header.tagView ? (
                    <div
                      style={{ minWidth: '120px' }}
                      onClick={(_e) => {
                        _e.stopPropagation();
                      }}
                    >
                      <TagsContainer
                        fieldName={header.name}
                        header={header}
                        myIndex={index}
                        onChange={(_v: any) => {
                          let prevItems = [...me.state.items];
                          prevItems[index][header.name] = _v.join(';');
                          // if (_v.join(';').length === 0) return;
                          apiWrapper
                            .post(`/funds/update_tag_cloud`, {
                              id: item.id,
                              value: _v.join(';'),
                            })
                            .then(() => {
                              // console.log("I am");
                              if (me.props.onChange) me.props.onChange('tags', '', '', '');
                            });
                        }}
                        item={item}
                        onRemoved={(_v: string) => {
                          let prevItems = [...me.state.items];
                          // alert(JSON.stringify(prevItems[index]));
                          let newItemTagCloud = prevItems[index][header.name].split(';');
                          let indexOfItem = newItemTagCloud.indexOf(_v);
                          // alert(indexOfItem);
                          newItemTagCloud.splice(indexOfItem, 1);
                          prevItems[index][header.name] = newItemTagCloud.join(';');

                          me.setState({ items: prevItems }, () => {
                            apiWrapper
                              .post(`/funds/update_tag_cloud`, {
                                id: item.id,
                                value: newItemTagCloud.join(';'),
                              })
                              .then(() => {
                                // console.log("I am");
                                if (me.props.onChange) me.props.onChange('tags', '', '', '');
                              });
                          });
                        }}
                      />
                    </div>
                  ) : (
                    <span
                      className={'mr-1 ml-1'}
                      style={{
                        whiteSpace: `${
                          header.name.toLowerCase().includes('amount') ? 'nowrap' : 'normal'
                        }`,
                      }}
                    >
                      {header.name.toLowerCase().includes('amount') ||
                      header.name.toLowerCase().includes('balance') ||
                      header.name.toLowerCase().includes('total_donated') ? (
                        <>{Math.abs(item[header.name]).toLocaleString() + ' ₽'}</>
                      ) : (
                        <>
                          {header.localize
                            ? item[header.name].toLocaleString()
                            : item[header.name]
                            ? item[header.name]
                                .toString()
                                .replace('ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ', 'ООО')
                            : ''}
                        </>
                      )}
                    </span>
                  )}
                </td>
              ))}
              {me.props.categories && (
                <td
                  className={`py-1.5 px-2 text-center`}
                  onClick={(_e) => {
                    _e.stopPropagation();
                  }}
                >
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id={`category-select-label-${item.id}`}>Категории</InputLabel>
                    <Select
                      variant="outlined"
                      labelId={`category-select-label-${item.id}`}
                      id={`category-select-${item.id}`}
                      value={me.state.selectedCategories[item.id]?.[0] || ''}
                      onChange={(e) => {
                        const selectedId = e.target.value as number | '';
                        me.setState((prevState) => ({
                          selectedCategories: {
                            ...prevState.selectedCategories,
                            [item.id]: selectedId ? [selectedId] : [], // Allow empty array for no selection
                          },
                        }));
                        if (me.props.onCategoryChange) {
                          me.props.onCategoryChange(item.id, selectedId ? [selectedId] : []); // Pass empty array when clearing
                        }
                      }}
                      label="Категории"
                      renderValue={(selected) => {
                        // @ts-ignore
                        const category = me?.props?.categories.find((cat) => cat.id === selected);
                        // @ts-ignore
                        return category?.name?.length > 30
                          ? // @ts-ignore
                            `${category?.name?.slice(0, 30)}...`
                          : category?.name || '';
                      }}
                      IconComponent={(props) => (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {me.state.selectedCategories[item.id]?.[0] && (
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                me.setState((prevState) => ({
                                  selectedCategories: {
                                    ...prevState.selectedCategories,
                                    [item.id]: [],
                                  },
                                }));
                                if (me.props.onCategoryChange) {
                                  me.props.onCategoryChange(item.id, []);
                                }
                              }}
                            >
                              <i className="far fa-times"></i>
                            </IconButton>
                          )}
                        </div>
                      )}
                    >
                      {me.props.categories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.code} {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </td>
              )}
              {/*<td>{JSON.stringify(item)}</td>*/}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default AppTable;
