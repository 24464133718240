export const DONATION_REQUEST_VALIDATIONS = [
  {
    name: 'egrul_check',
    display_name: 'Данные заявки соответствуют сведениям ЕГРЮЛ',
  },
  {
    name: 'year_check',
    display_name: 'Возраст организации: не менее года',
  },
  {
    name: 'foreign_agent_check',
    display_name: 'Проверка юридического лица на отсутствие в реестре иностранных агентов',
  },
  {
    name: 'gov_capital_check',
    display_name: 'Не является государственным или муниципальным органом власти',
  },
  {
    name: 'bankrupt_check',
    display_name: 'Проверка юридического лица на отсутствие в ЕФРСБ',
  },
  {
    name: 'bankrupt_check',
    display_name: 'Проверка ФЛ на отсутствие в реестре дисквалифицированных лиц',
  },
  {
    name: 'religion_organization_check',
    display_name: 'Проверка юридического лица на отсутствие в реестре религиозных организаций',
  },
  {
    name: 'charity_organization_check',
    display_name: 'Проверка юридического лица на присутствие в реестре благотворительных организаций',
  },
  // {
  //   name: 'sonco_registry_check',
  //   display_name: 'Проверка по реестру СОНКО',
  // },
  {
    name: 'founders_foreign_agents_check',
    display_name: 'Проверка учредителей юридического лица на отсутствие иностранного гражданства',
  },

  // {
  //   name: 'is_gov_capital',
  //   display_name: 'Проверка на Государственное участие в капитале',
  // },
  //
  // {
  //   name: 'is_sub_gov',
  //   display_name: 'Проверка на дочерность Гос организаций',
  // },
  //
  //
  // {
  //   name: 'is_political_party_organization',
  //   display_name: 'Не является политической партией',
  // },
  // {
  //   name: 'foreign_agent_check',
  //   display_name: 'Проверка по реестру иностранных агентов',
  // },
  // {
  //   name: 'gov_capital_check',
  //   display_name: 'Проверка на Государственное участие в капитале',
  // },
  // {
  //   name: 'bankrupt_check',
  //   display_name: 'Проверка по реестру банкротов',
  // },
  // {
  //   name: 'sonco_registry_check',
  //   display_name: 'Проверка по реестру СОНКО',
  // },
  // {
  //   name: 'foreign_in_founders_check',
  //   display_name: 'Проверка наличия иностарнных граждан в учредителях',
  // },
  // {
  //   name: 'sub_gov_check',
  //   display_name: 'Проверка на дочерность Гос организаций',
  // },
  // {
  //   name: 'religion_organization_check',
  //   display_name: 'Проверка на участие в реестре религиозных организаций',
  // },
  // {
  //   name: 'charity_organization_check',
  //   display_name: 'Проверка на участие в реестре благотворительных организаций',
  // },
  // {
  //   name: 'political_party_organization_check',
  //   display_name: 'Не является политической партией',
  // },
  // {
  //   name: 'founders_foreign_agents_check',
  //   display_name: 'Проверка основателей по реестру иностранных агентов ',
  // },
  // {
  //   name: 'founders_sonco_registry_check',
  //   display_name: 'Проверка основателей по реестру СОНКО',
  // },
  // {
  //   name: 'founders_bankrupt_check',
  //   display_name: 'Проверка основателей по реестру банкротов',
  // },
  // {
  //   name: 'founders_gov_capital_check',
  //   display_name: 'Проверка основателей на Государственное участие в капитале',
  // },
  // {
  //   name: 'founders_religion_organization_check',
  //   display_name: 'Проверка основателей на участие в реестре религиозных организаций',
  // },
  // {
  //   name: 'founders_charity_check',
  //   display_name: 'Проверка основателей на участие в реестре благотворительных организаций',
  // },
  // {
  //   name: 'founders_political_party_check',
  //   display_name: 'Проверка основателей на членство в политических партиях',
  // },
];
