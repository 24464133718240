import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  CircularProgress,
  TextField,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
} from '@mui/material';
import Dashboard from '../../../containers/admin/Dashboard';
import { PageContext } from '../../../contexts/PageContext';
import { Fund } from '../../../types/Fund';
import { BankAccount } from '../../../types/BankAccount';
import { SberTransaction } from '../../../types/SberTransaction';
import { apiWrapper } from '../../../utils/api';
import FundAnalysisTab from '../../../components/admin/Limits/Tabs/FundAnalysisTab';
import OverviewTab from '../../../components/admin/Limits/Tabs/OverviewTab';
import TransactionsTab from '../../../components/admin/Limits/Tabs/TransactionsTab';

const MAX_ANNUAL_LIMIT = 43_300_000;
const INDIVIDUAL_LIMIT = 4_330_000;
const CORPORATE_LIMIT = 43_300_000;
// const PARTY_LIMIT = 4_330_000_000;
const REGIONAL_LIMIT = 86_600_000;

const CIK_VALUES = {
  inn: ['7702330228'],
  account: ['40703810638090109172'],
};

const LimitPage: React.FC = () => {
  const { setPageName } = useContext(PageContext);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [funds, setFunds] = useState<Fund[]>([]);
  const [filteredFunds, setFilteredFunds] = useState<Fund[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedFund, setSelectedFund] = useState<Fund | null>(null);
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [selectedAccountIds, setSelectedAccountIds] = useState<number[]>([]);
  const [transactions, setTransactions] = useState<SberTransaction[]>([]);
  const [activeTransacton, setActiveTransacton] = useState<SberTransaction | null>(null);
  const [total, setTotal] = useState<number>(0);
  const [isCikFilterEnabled, setIsCikFilterEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [transactionMode, setTransactionMode] = useState<'income' | 'outcome'>('outcome');

  const [activeTab, setActiveTab] = useState<string>('overview');
  const [limitsOverview, setLimitsOverview] = useState<any>(null);
  const [fundStatus, setFundStatus] = useState<any>(null);

  // Fetch available funds on component mount
  useEffect(() => {
    setPageName('Проверка лимитов');

    const fetchFunds = async () => {
      try {
        // Clear previous funds first to show loading state
        setFilteredFunds([]);
        setFunds([]);
        setSelectedFund(null);
        setLoading(true);

        const response = await apiWrapper.get(`/limits/available_funds?mode=${transactionMode}`);
        const fetchedFunds = response.data;

        setFunds(fetchedFunds);
        setFilteredFunds(fetchedFunds);

        // Set the first fund as selected if funds are available
        if (fetchedFunds && fetchedFunds.length > 0) {
          setSelectedFund(fetchedFunds[0]);
        }
      } catch (err: any) {
        console.error('Failed to fetch funds:', err);
        setError(err.response?.data?.message || 'Не удалось загрузить доступные фонды');
      } finally {
        setLoading(false);
      }
    };

    fetchFunds();
  }, [setPageName, transactionMode]);

  // Filter funds based on search text
  useEffect(() => {
    if (!funds.length) return;

    const filtered = funds.filter((fund) => {
      const nameMatch = fund.name?.toLowerCase().includes(searchText.toLowerCase());
      const innMatch = fund.inn?.toString().includes(searchText);
      return nameMatch || innMatch;
    });

    setFilteredFunds(filtered);
  }, [funds, searchText]);

  // Fetch bank accounts when a fund is selected
  useEffect(() => {
    if (!selectedFund) return;

    const fetchBankAccounts = async () => {
      try {
        setLoading(true);

        // First fetch the accounts for the current fund
        const response = await apiWrapper.get(`/limits/bank_accounts?fund_id=${selectedFund.id}`);
        setBankAccounts(response.data);

        if (response.data.length > 0) {
          // Get all valid account IDs for THIS fund
          const allAccountIds = response.data.map((account: BankAccount) => account.id);

          // THEN check localStorage for saved account selection
          const storageKey = `selected_accounts_fund_${selectedFund.id}`;
          const savedAccounts = localStorage.getItem(storageKey);

          if (savedAccounts) {
            try {
              const savedAccountIds = JSON.parse(savedAccounts);

              // IMPORTANT: Filter to only include accounts that actually exist in THIS fund
              const validSavedAccounts = savedAccountIds.filter((id: number) =>
                allAccountIds.includes(id),
              );

              if (validSavedAccounts.length > 0) {
                console.log('Using valid saved account selection:', validSavedAccounts);
                setSelectedAccountIds(validSavedAccounts);
              } else {
                // If no VALID saved accounts for this fund, select all accounts
                console.log('No valid saved accounts for this fund, selecting all:', allAccountIds);
                setSelectedAccountIds(allAccountIds);
                localStorage.setItem(storageKey, JSON.stringify(allAccountIds));
              }
            } catch (e) {
              console.error('Error parsing saved account selection:', e);
              // If parsing fails, select all accounts
              setSelectedAccountIds(allAccountIds);
              localStorage.setItem(storageKey, JSON.stringify(allAccountIds));
            }
          } else {
            // If no saved selection exists, select all accounts
            console.log('No saved selection, selecting all accounts:', allAccountIds);
            setSelectedAccountIds(allAccountIds);
            localStorage.setItem(storageKey, JSON.stringify(allAccountIds));
          }
        } else {
          setSelectedAccountIds([]);
        }
      } catch (err: any) {
        console.error('Failed to fetch bank accounts:', err);
        setError(err.response?.data?.message || 'Не удалось загрузить банковские счета');
      } finally {
        setLoading(false);
      }
    };

    fetchBankAccounts();
  }, [selectedFund, transactionMode, selectedYear]);

  // Fetch transactions for selected accounts
  useEffect(() => {
    if (!selectedAccountIds || selectedAccountIds.length === 0) {
      setTransactions([]);
      return;
    }

    const fetchTransactions = async () => {
      try {
        const url =
          transactionMode === 'income'
            ? '/limits/income_transactions'
            : '/limits/expense_transactions';
        setLoading(true);
        const accountIdsString = selectedAccountIds.join(',');
        const response = await apiWrapper.get(
          `${url}?bank_accounts=${accountIdsString}&year=${selectedYear}`,
        );
        setTransactions(response.data?.transactions);
        setTotal(response.data?.total);
      } catch (err: any) {
        console.error('Failed to fetch transactions:', err);
        setError(err.response?.data?.message || 'Не удалось загрузить транзакции');
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [selectedAccountIds, transactionMode, selectedYear]);

  // Save selected accounts to localStorage when they change
  useEffect(() => {
    // Skip if no fund selected or selection is empty
    if (!selectedFund || selectedAccountIds.length === 0) return;

    // Only save if there are actual selections
    const storageKey = `selected_accounts_fund_${selectedFund.id}`;

    // Get the current bank accounts for this fund to validate
    const currentFundAccountIds = bankAccounts.map((account: BankAccount) => account.id);

    // Filter selectedAccountIds to only include those that are valid for this fund
    const validSelections = selectedAccountIds.filter((id) => currentFundAccountIds.includes(id));

    if (validSelections.length > 0) {
      localStorage.setItem(storageKey, JSON.stringify(validSelections));
      console.log(`Saved valid selections for fund ${selectedFund.id}:`, validSelections);
    }
  }, [selectedAccountIds, selectedFund, bankAccounts]);

  // Handle fund selection change
  const handleFundChange = (fund: Fund) => {
    // First clear any existing selections and transactions to prevent
    // them from being inadvertently saved to the new fund
    setSelectedAccountIds([]);
    setTransactions([]);

    // Only then set the new fund
    setSelectedFund(fund);
  };

  // Handle search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  // Toggle account selection
  const toggleAccountSelection = (accountId: number) => {
    const newSelection = selectedAccountIds.includes(accountId)
      ? selectedAccountIds.filter((id) => id !== accountId)
      : [...selectedAccountIds, accountId];

    setSelectedAccountIds(newSelection);

    // Save to localStorage immediately
    if (selectedFund) {
      const storageKey = `selected_accounts_fund_${selectedFund.id}`;
      localStorage.setItem(storageKey, JSON.stringify(newSelection));
    }
  };

  // Select all accounts
  const selectAllAccounts = () => {
    const allAccountIds = bankAccounts.map((account) => Number(account.id));
    setSelectedAccountIds(allAccountIds);

    // Save to localStorage immediately
    if (selectedFund) {
      const storageKey = `selected_accounts_fund_${selectedFund.id}`;
      localStorage.setItem(storageKey, JSON.stringify(allAccountIds));
    }
  };

  // Deselect all accounts
  const deselectAllAccounts = () => {
    setSelectedAccountIds([]);

    // Save to localStorage immediately
    if (selectedFund) {
      const storageKey = `selected_accounts_fund_${selectedFund.id}`;
      localStorage.setItem(storageKey, JSON.stringify([]));
    }
  };

  // Get shortened account number for display
  const getShortenedAccountNumber = (accountNumber: string) => {
    if (!accountNumber) return '';
    if (accountNumber.length <= 10) return accountNumber;

    // Show first 4 and last 4 digits
    return `${accountNumber.substring(0, 4)}......${accountNumber.substring(
      accountNumber.length - 4,
    )}`;
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('ru-RU', {
      style: 'currency',
      currency: 'RUB',
    }).format(amount);
  };

  const percentFilled = Math.min(100, (total / MAX_ANNUAL_LIMIT) * 100);
  const formatLargeNumber = (num: number) => {
    return new Intl.NumberFormat('ru-RU', {
      style: 'currency',
      currency: 'RUB',
      maximumFractionDigits: 0,
      notation: 'compact',
      compactDisplay: 'short',
    }).format(num);
  };

  // Add this inside the return statement, before the transactions table
  const getLimitColor = (percent: number) => {
    if (percent < 70) return 'success';
    if (percent < 90) return 'warning';
    return 'error';
  };

  const filteredTransactions = useMemo(() => {
    if (!transactions) return [];

    if (!isCikFilterEnabled) return transactions;

    return transactions.filter((transaction) => {
      const isCikPayer =
        CIK_VALUES.inn.includes(String(transaction?.payer_inn)) ||
        CIK_VALUES.account.includes(String(transaction?.payer_account));
      const isCikPayee =
        CIK_VALUES.inn.includes(String(transaction?.payee_inn)) ||
        CIK_VALUES.account.includes(String(transaction?.payee_account));

      return isCikPayer || isCikPayee;
    });
  }, [transactions, isCikFilterEnabled]);

  const filteredTotal = useMemo(() => {
    return filteredTransactions.reduce((sum, t) => sum + Math.abs(t.amount), 0);
  }, [filteredTransactions]);

  const handleTransactionModeChange = (_event: React.MouseEvent<HTMLElement>, newMode: string) => {
    setTransactionMode(newMode as 'income' | 'outcome');
  };

  const availableYears = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let year = 2024; year <= currentYear; year++) {
      years.push(year);
    }

    return years;
  }, []);

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    const newYear = Number(event.target.value);
    setSelectedYear(newYear);

    // Clear transactions when year changes to avoid showing old data
    setTransactions([]);
    setTotal(0);

    // Reset CIK filter when year changes
    setIsCikFilterEnabled(false);
  };

  useEffect(() => {
    // Fetch overview data when year changes
    if (selectedYear) {
      const fetchLimitsOverview = async () => {
        try {
          const response = await apiWrapper.get(`/limits/limits_overview?year=${selectedYear}`);
          setLimitsOverview(response.data);
        } catch (err: any) {
          console.error('Failed to fetch limits overview:', err);
        }
      };

      fetchLimitsOverview();
    }
  }, [selectedYear]);

  useEffect(() => {
    // Fetch fund status data when fund and year change
    if (selectedFund && selectedYear) {
      const fetchFundStatus = async () => {
        try {
          const response = await apiWrapper.get(
            `/limits/fund_limits_status?fund_id=${selectedFund.id}&year=${selectedYear}`,
          );
          setFundStatus(response.data);
        } catch (err: any) {
          console.error('Failed to fetch fund status:', err);
        }
      };

      fetchFundStatus();
    }
  }, [selectedFund, selectedYear]);

  const regionalBranchesChartData = useMemo(() => {
    if (!limitsOverview?.regional_branches) return [];

    return limitsOverview.regional_branches
      .sort((a: any, b: any) => b.total_donations - a.total_donations)
      .slice(0, 10)
      .map((branch: any) => ({
        name: branch.name.length > 15 ? `${branch.name.substring(0, 15)}...` : branch.name,
        fullName: branch.name,
        value: branch.total_donations,
        limit: REGIONAL_LIMIT,
        limitPercentage: branch.limit_percentage,
      }));
  }, [limitsOverview]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const donorTypeDistribution = useMemo(() => {
    if (!fundStatus) return [];

    // Calculate sum of donations for individual donors
    const individualSum =
      fundStatus.top_individual_donors?.reduce(
        (sum: number, donor: any) => sum + donor.total_amount,
        0,
      ) || 0;

    // Calculate sum of donations for corporate donors
    const corporateSum =
      fundStatus.top_corporate_donors?.reduce(
        (sum: number, donor: any) => sum + donor.total_amount,
        0,
      ) || 0;

    // Format data for the pie chart
    return [
      { name: 'Физические лица', value: individualSum },
      { name: 'Юридические лица', value: corporateSum },
    ];
  }, [fundStatus]);

  return (
    <Dashboard
      content={
        <Box className="w-full h-full p-4">
          <div className="grid h-[calc(100vh-120px)] grid-cols-1 gap-4 md:grid-cols-12">
            <Paper className="flex flex-col h-full overflow-hidden md:col-span-3">
              <div className="p-4 border-b">
                <Typography variant="h6" className="!mb-2">
                  Доступные фонды
                </Typography>

                <FormControl fullWidth size="small" className="!mb-3">
                  <InputLabel id="year-select-label">Год</InputLabel>
                  <Select
                    labelId="year-select-label"
                    id="year-select"
                    value={selectedYear}
                    onChange={handleYearChange}
                    label="Год"
                  >
                    {availableYears.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  fullWidth
                  size="small"
                  placeholder="Поиск по названию или ИНН"
                  value={searchText}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <i className="far fa-search"></i>
                      </InputAdornment>
                    ),
                  }}
                  className="!mb-3"
                />

                <div className="mt-3">
                  <ToggleButtonGroup
                    value={transactionMode}
                    exclusive
                    onChange={handleTransactionModeChange}
                    size="small"
                    fullWidth
                    color="primary"
                  >
                    <ToggleButton value="income" className="py-1">
                      <i className="mr-1 fas fa-arrow-down"></i> Получатели
                    </ToggleButton>
                    <ToggleButton value="outcome" className="py-1">
                      <i className="mr-1 fas fa-arrow-up"></i> Жертвователи
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>

              {/* Scrollable Fund List */}
              <div className="flex-grow overflow-y-auto">
                {loading && funds.length === 0 ? (
                  <div className="flex items-center justify-center h-full">
                    <CircularProgress />
                  </div>
                ) : error ? (
                  <div className="p-4">
                    <Typography color="error">{error}</Typography>
                  </div>
                ) : filteredFunds.length === 0 ? (
                  <div className="p-4">
                    <Typography color="textSecondary">Фонды не найдены</Typography>
                  </div>
                ) : (
                  <div className="p-4">
                    {filteredFunds
                      .slice()
                      .sort((a, b) => a.name.localeCompare(b.name, 'ru'))
                      .map((fund) => (
                        <div
                          key={fund.id}
                          className={`p-3 mb-2 cursor-pointer rounded ${
                            selectedFund?.id === fund.id ? 'bg-[#EDEEF5]' : 'hover:bg-gray-100'
                          }`}
                          onClick={() => handleFundChange(fund)}
                        >
                          <Typography variant="subtitle1" className="!leading-tight">
                            {fund.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            ИНН: {fund?.inn}
                          </Typography>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </Paper>

            <Paper className="flex flex-col h-full p-4 md:col-span-9">
              <Box className="p-4 border-b">
                <Typography variant="h6" className="mb-2">
                  Аналитика по лимитам пожертвований
                </Typography>

                <Tabs value={activeTab} onChange={handleTabChange} variant="scrollable">
                  <Tab label="Обзор" value="overview" />
                  <Tab label="Транзакции" value="transactions" />
                  {selectedFund && transactionMode === 'income' && (
                    <Tab label="Анализ фонда" value="fund" />
                  )}
                </Tabs>
              </Box>

              <Box className="flex-grow p-4 overflow-auto">
                {activeTab === 'overview' && (
                  <OverviewTab
                    limitsOverview={limitsOverview}
                    regionalBranchesChartData={regionalBranchesChartData}
                    formatCurrency={formatCurrency}
                    getLimitColor={getLimitColor}
                  />
                )}

                {activeTab === 'fund' && selectedFund && (
                  <FundAnalysisTab
                    fundStatus={fundStatus}
                    donorTypeDistribution={donorTypeDistribution}
                    INDIVIDUAL_LIMIT={INDIVIDUAL_LIMIT}
                    CORPORATE_LIMIT={CORPORATE_LIMIT}
                    formatCurrency={formatCurrency}
                    getLimitColor={getLimitColor}
                  />
                )}

                {activeTab === 'transactions' && (
                  <TransactionsTab
                    transactions={transactions}
                    filteredTransactions={filteredTransactions}
                    filteredTotal={filteredTotal}
                    bankAccounts={bankAccounts}
                    selectedAccountIds={selectedAccountIds}
                    toggleAccountSelection={toggleAccountSelection}
                    selectAllAccounts={selectAllAccounts}
                    deselectAllAccounts={deselectAllAccounts}
                    getShortenedAccountNumber={getShortenedAccountNumber}
                    isCikFilterEnabled={isCikFilterEnabled}
                    setIsCikFilterEnabled={setIsCikFilterEnabled}
                    transactionMode={transactionMode}
                    activeTransacton={activeTransacton}
                    setActiveTransacton={setActiveTransacton}
                    formatCurrency={formatCurrency}
                    formatLargeNumber={formatLargeNumber}
                    total={total}
                    MAX_ANNUAL_LIMIT={MAX_ANNUAL_LIMIT}
                    percentFilled={percentFilled}
                    getLimitColor={getLimitColor}
                  />
                )}
              </Box>
            </Paper>
          </div>
        </Box>
      }
    />
  );
};

export default LimitPage;
