import React, { Component, useContext, useEffect } from 'react';
import Dashboard from '../../../containers/admin/Dashboard';
import dayjs from 'dayjs';
import AppNumberWithSpaces from '../../../components/shared/AppNumberWithSpaces';
import { convertStatusStringToEnum } from '../../../types/DonationRequestTranslations';
import AppDonationRequestStatusLabel from '../../../components/shared/AppDonationRequestStatusLabel';
import styles from './MyDonationRequests.module.scss';
import { apiWrapper } from '../../../utils/api';
import AppUploadsDialog from '../../../components/shared/AppUploadsDialog';
import { uploadDonationRequestFile } from '../../../utils/donationRequestUploads';
import DocumentsDialog from './DocumentsDialog';
import { DonationRequest } from '../../../types/DonationRequest';
import { PageContext } from '../../../contexts/PageContext';

// interface MyDonationRequests {
//   debet: number;
//   id: number | string;
//   code: number | string;
//   amount: string;
//   created_at: string;
//   status: string;
// }

interface MyDonationRequestsState {
  donation_requests: DonationRequest[];
  loading: boolean;
  status: string;
  page: number;
  total_pages: number;
  start_date: Date | null;
  end_date: Date | null;
  uploadDialog: boolean;
  current_donation_request_id: number | null;
  documentsDialog: boolean;
  current_donation_request: DonationRequest | null;
}

interface MyDonationRequestsProps {
  setPageName?: (name: string) => void;
}

class AppMyDonationRequests extends Component<MyDonationRequestsProps, MyDonationRequestsState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      donation_requests: [],
      loading: false,
      status: 'all',
      page: 1,
      total_pages: 0,
      start_date: null,
      end_date: null,
      uploadDialog: false,
      current_donation_request_id: null,
      documentsDialog: false,
      current_donation_request: null,
    };
  }

  prepareDate() {
    let start_date,
      end_date = '';

    if (this.state.start_date) {
      start_date = dayjs(this.state.start_date).format('YYYY-MM-DD');
    }

    if (this.state.end_date) {
      end_date = dayjs(this.state.end_date).format('YYYY-MM-DD');
    }

    return { start_date, end_date };
  }

  async searchHandler() {
    this.setState({ donation_requests: [] });
    const { start_date, end_date } = this.prepareDate();
    await this.getDonationRequests(0, this.state.status, start_date, end_date);
  }

  handleDateChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<
      MyDonationRequestsState,
      keyof MyDonationRequestsState
    >);
  };

  handleChangeStatus = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<
      MyDonationRequestsState,
      keyof MyDonationRequestsState
    >);
  };

  async onPageChange(page: number) {
    this.setState({ donation_requests: [] });
    this.setState({ page });
    const { start_date, end_date } = this.prepareDate();
    await this.getDonationRequests(page - 1, this.state.status, start_date, end_date);
  }

  async getDonationRequests(
    page: number = 0,
    status: string = 'all',
    start_date: string = '',
    end_date: string = '',
  ) {
    await apiWrapper
      .get(
        `/donation_requests/my?page=${page}&status=${status}&start_date=${start_date}&end_date=${end_date}`,
      )
      .then((response) => {
        this.setState(() => ({
          donation_requests: [...response.data.data],
          total_pages: response.data.pages,
        }));
      });
  }

  async componentDidMount() {
    if (this.props.setPageName) {
      this.props.setPageName('Заявки на пожертвование');
    }

    this.setState({ loading: true });
    await this.getDonationRequests();
    this.setState({ loading: false });
  }

  closeUploadDialog = (): void => {
    this.setState({ uploadDialog: false, current_donation_request_id: null });
  };

  openUploadDialog = (current_donation_request_id: number): void => {
    this.setState({ uploadDialog: true, current_donation_request_id: current_donation_request_id });
  };

  onSubmitUpload = async (files: { agreement: File; letter: File }): Promise<void> => {
    console.log(files);
    if (this.state.current_donation_request_id) {
      await uploadDonationRequestFile(
        Number(this.state.current_donation_request_id),
        files.agreement,
        'agreement',
        'org',
      );
      await uploadDonationRequestFile(
        Number(this.state.current_donation_request_id),
        files.letter,
        'letter',
        'org',
      );
    }
  };

  openDocumentsDialog = (current_donation_request: DonationRequest): void => {
    this.setState({ documentsDialog: true, current_donation_request: current_donation_request });
  };

  closeDocumentsDialog = (): void => {
    this.setState({ documentsDialog: false, current_donation_request: null });
  };

  render() {
    const { loading, donation_requests } = this.state;
    const pages = Array.from({ length: this.state.total_pages }, (_, i) => i + 1);

    const getClassName = (isPaid: boolean): string => {
      if (isPaid) {
        return 'bg-greenGradient';
      }

      return 'bg-redGradient';
    };

    return (
      <Dashboard
        content={
          <div
            className={`bg-primary w-full overflow-y-scroll`}
            style={{ height: 'calc(100vh - 112px)' }}
          >
            {!loading && (
              <div
                className={`flex flex-col h-full w-full items-center justify-center px-5 lg:px-10 ${styles.tableWrapper}`}
              >
                <div className="flex flex-row flex-wrap items-center w-full py-2 mb-2 rounded-lg bg-primary gap-x-4 gap-y-2">
                  <div className={`text-white uppercase font-semibold text-sm`}>Фильтрация:</div>
                  <div className={``}>
                    <select
                      id="status"
                      name="status"
                      className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      defaultValue="Все категории"
                      onChange={this.handleChangeStatus}
                    >
                      <option value="all">Все</option>
                      {/*<option value="fund">Заявки от рег. фондов</option>*/}
                      <option value="approved">Заявка утверждена</option>
                      <option value="rejected">Заявка отклонена</option>
                      <option value="paid">Заявка оплачена</option>
                      <option value="by_not_paid">Заявка не оплачена</option>
                    </select>
                  </div>
                  <div className={`text-sm`}>
                    <span className={`mr-4 text-white font-semibold`}>В период с</span>
                    <input
                      type={'date'}
                      name="start_date"
                      max={dayjs().format('YYYY-MM-DD')}
                      onChange={this.handleDateChange}
                    />
                  </div>
                  <div className={`text-sm`}>
                    <span className={`mr-4 text-white font-semibold`}>по</span>
                    <input
                      type={'date'}
                      name="end_date"
                      min={dayjs(this.state.start_date).format('YYYY-MM-DD')}
                      onChange={this.handleDateChange}
                    />
                  </div>
                  <div className={`ml-2`}>
                    <button
                      className={`bg-white text-primary rounded-lg font-semibold px-6 py-2`}
                      onClick={() => this.searchHandler()}
                    >
                      Поиск
                    </button>
                  </div>
                </div>

                <div className={`w-full overflow-y-scroll h-3/4`}>
                  <table className={`w-full bg-white`}>
                    <thead className={`text-[#2C2C2C] text-sm font-semibold leading-tight`}>
                      <tr>
                        <th>№</th>
                        <th>ДАТА СОЗДАНИЯ</th>
                        <th>СУММА</th>
                        <th>ЦЕЛЕВОЕ НАЗНАЧЕНИЕ</th>
                        <th>ПОЛУЧАТЕЛЬ ПОЖЕРТВОВАНИЯ</th>
                        <th>СТАТУС РАССМОТРЕНИЯ</th>
                        <th>СТАТУС ОПЛАТЫ</th>
                        <th>ЮРИДИЧЕСКИЕ ДОКУМЕНТЫ</th>
                      </tr>
                    </thead>
                    <tbody className={`text-center`}>
                      {donation_requests.map((val, index) => {
                        return (
                          <tr key={index}>
                            <td>{val.code || val.id}</td>
                            <td>{dayjs(val.created_at).format('DD.MM.YYYY')}</td>
                            <td>
                              <AppNumberWithSpaces value={Number(val.amount)} />
                              &nbsp;₽
                            </td>
                            <td>{val.purpose}</td>
                            <td>{val?.fund?.name}</td>
                            <td>
                              <AppDonationRequestStatusLabel
                                status={convertStatusStringToEnum(val.status)}
                              />
                            </td>
                            <td className={`text-center`}>
                              {
                                <div
                                  className={`
                                  py-1 px-2
                                  rounded-lg 
                                  min-h-[33px] max-w-[240px] mx-auto 
                                  flex items-center justify-center 
                                  text-white leading-tight
                                  ${getClassName(Number(val?.debet) === Number(val.amount))}
                                `}
                                >
                                  {Number(val?.debet) === Number(val.amount)
                                    ? 'Заявка оплачена'
                                    : 'Заявка не оплачена'}
                                </div>
                              }
                            </td>
                            <td style={{ width: '350px', maxWidth: '350px' }}>
                              {['approved', 'paid'].indexOf(val.status) > -1 && (
                                <>
                                  <button
                                    className={`border-none bg-primaryGradient text-white rounded-lg text-sm font-semibold p-2 w-full`}
                                    onClick={() => this.openDocumentsDialog(val)}
                                  >
                                    Просмотреть сформированные документы
                                  </button>

                                  <button
                                    className={`mt-2 border-none bg-primaryGradient text-white rounded-lg text-sm font-semibold p-2 w-full`}
                                    onClick={() => this.openUploadDialog(Number(val.id))}
                                  >
                                    Загрузить подписанный пакет документов
                                  </button>
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <AppUploadsDialog
                  isOpen={this.state.uploadDialog}
                  onClose={this.closeUploadDialog}
                  onSubmit={this.onSubmitUpload}
                />

                {this.state.current_donation_request && (
                  <DocumentsDialog
                    isOpen={this.state.documentsDialog}
                    onClose={this.closeDocumentsDialog}
                    current_donation_request={this.state.current_donation_request}
                  />
                )}

                <div className="flex justify-center mt-5">
                  {pages.map((page) => (
                    <button
                      key={page}
                      className={`h-8 w-8 mx-1 flex justify-center items-center border-gray-300 border rounded-md ${
                        page === this.state.page
                          ? 'bg-primary text-white'
                          : 'bg-white text-gray-700'
                      }`}
                      onClick={() => this.onPageChange(page)}
                    >
                      {page}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        }
      />
    );
  }
}

const AppMyDonationRequestsWithContext: React.FC = () => {
  const { setPageName } = useContext(PageContext);

  useEffect(() => {
    setPageName('Заявки на пожертвование');
  }, [setPageName]);

  return <AppMyDonationRequests setPageName={setPageName} />;
};

export default AppMyDonationRequestsWithContext;
