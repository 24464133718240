import { Organization } from './Organization';
import { User } from './User';
import { Fund } from './Fund';

export interface DonationRequest {
  id?: number | string;
  amount: string;
  purpose?: string;
  donation_account_info: DonationAccountInfo;
  donation_organization_info: Organization;
  status: string;
  user: User;
  created_at: string;
  is_fund?: boolean;
  fund?: Fund;
  is_foreign_agent: boolean | null;
  is_gov_capital: boolean | null;
  is_bankrupt: boolean | null;
  is_sonco_registry: boolean | null;
  is_foreign_in_founders: boolean | null;
  is_sub_gov: boolean | null;
  is_relig_or_charity_registry: boolean | null;
  code?: string;
  is_report_needed?: boolean;
  verification_items?: any[];
  donation_request_documents?: any[];
  status_updated_at: string;
  debet?: number;
  main_status_updater?: string;
  revenue_recognitions?: RevenueRecognition[];
}

export enum DonationRequestStatus {
  Rejected = -1,
  InProgress,
  Approved,
  Paid,
  NotPaid,
}

export interface ApplicationMiniCard {
  id: number;
  date: string;
  amount: number;
  organization?: string;
  fund_name: string;
  status: DonationRequestStatus;
  code?: string;
  debet?: number;
}

export interface ApplicationForm {
  donation_request: {
    amount: number;
    fund_id: null | number;
    is_fund?: boolean;
    is_report_needed?: boolean;
    purpose?: string;
    donation_account_info_attributes: DonationAccountInfo;
    donation_organization_info_attributes: Organization;
  };
  hasPurpose: boolean;
  step: number;
  bankInputManual: boolean;
  isInnModalShown: boolean;
  isBankModalShown: boolean;
  isAgentModalShown: boolean;
  message: string;
  funds: Fund[];
  validationData: any;
  charterFile?: File | null;
  decisionFile?: File | null;
  minjustRegistrationFile?: File | null;
  taxRegistrationFile?: File | null;
  errors: { [key: string]: string };
  loading: boolean;
}

export interface DonationAccountInfo {
  payment_account: string;
  corr_account: string;
  bik: string;
  bank: string;
}

export interface RevenueRecognition {
  amount: number;
  created_at: string;
  updated_at: string;
  donation_request_id: number;
  id: number;
  sber_transaction_id: number;
}
