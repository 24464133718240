import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { UserContext, UserContextType, UserState } from '../store/user';
import { apiWrapper } from '../utils/api';

interface UserProviderProps {
  children: ReactNode;
}

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [state, setState] = useState<UserContextType>({
    id: -1,
    email: '',
    phone: '',
    is_moderator: false,
    is_authed: false,
    loading: true,
    role: '',
    setUser: (user: UserState) => setState((prev) => ({ ...prev, ...user })),
  });

  const redirectUser = useCallback(() => {
    const isAdminPage = window.location.pathname.includes('admin');
    const redirectUrl = isAdminPage ? '/admin/login' : '/login';

    if (window.location.pathname !== redirectUrl) {
      window.location.replace(redirectUrl);
    }
  }, []);

  const loginUser = useCallback(async () => {
    const token = localStorage.getItem('auth_token');

    if (token) {
      try {
        const response = await apiWrapper.get('/users/me');
        const { data } = response;

        setState((prev) => ({
          ...prev,
          id: data.user.id,
          email: data.user.email,
          phone: data.user.phone || '',
          is_moderator: data.user.is_moderator,
          full_name: data.user.full_name || '',
          is_authed: true,
          organization: data.organization || null,
          loading: false,
          role: data.user.role,
        }));
      } catch (error) {
        console.error('Error fetching user data:', error);
        redirectUser();
      }
    } else {
      redirectUser();
    }
  }, [redirectUser]);

  useEffect(() => {
    if (!state.is_authed) {
      setState((prev) => ({ ...prev, loading: true }));
      loginUser().finally(() => {
        setState((prev) => ({ ...prev, loading: false }));
      });
    }
  }, [state.is_authed, loginUser]);

  if (state.loading) {
    return (
      <div className="z-10 flex flex-col items-center justify-center w-screen h-screen bg-primary">
        <i className="text-white far fa-sync fa-spin fa-5x"></i>
        <p className="mt-10 text-lg font-semibold text-center text-white uppercase">Загрузка</p>
      </div>
    );
  }

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};

export default UserProvider;
