import React from 'react';
import AppRoutes from './routes';
import GlobalProvider from './providers/global';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme/theme';
import { PageProvider } from './contexts/PageContext';

function App() {
  return (
    <div className="App">
      <GlobalProvider>
        <PageProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppRoutes />
          </ThemeProvider>
        </PageProvider>
      </GlobalProvider>
      <ToastContainer />
    </div>
  );
}

export default App;
