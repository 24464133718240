import { createTheme } from '@mui/material/styles';

export const appOrange = {
  light: '#FF8A50',
  main: '#E65100',
  dark: '#AC1900',
  contrastText: '#FFFFFF',
};

export const appGreen = {
  light: '#4AD66D',
  main: '#17A63F',
  dark: '#0B7728',
  contrastText: '#FFFFFF',
};

declare module '@mui/material/styles' {
  interface Palette {
    appOrange: typeof appOrange;
    appGreen: typeof appGreen;
  }

  interface PaletteOptions {
    appOrange?: typeof appOrange;
    appGreen?: typeof appGreen;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    appOrange: true;
    appGreen: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    border: {
      main: string;
    };
  }
  interface PaletteOptions {
    border: {
      main: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#0F094F',
    },
    appOrange,
    appGreen,
    border: {
      main: '#e0e0e0',
    },
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '1px solid #c5c4d7',
          boxShadow: 'none',
        },
      },
      defaultProps: {
        elevation: 0,
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            borderRadius: '0.5rem',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '0.5rem',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '0.5rem',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // Globally disable ripple effect
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none', // Remove elevation (shadow)
          '&:hover': {
            boxShadow: 'none', // Ensure no shadow on hover
          },
          borderRadius: '0.5rem',
          fontWeight: 600,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '0.5rem',
            fontWeight: 600, // semibold
          },
          '& .MuiInputLabel-root': {
            fontWeight: 600,
          },
          '& .MuiInputBase-input': {
            fontWeight: 600,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
          fontWeight: 600,
        },
        input: {
          fontWeight: 600,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
          fontWeight: 600,
          '&:before': {
            borderRadius: '0.5rem',
          },
          '&:after': {
            borderRadius: '0.5rem',
          },
        },
        input: {
          fontWeight: 600,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
          fontWeight: 600,
        },
        input: {
          fontWeight: 600,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
  },
});

export default theme;
