import * as React from 'react';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { TextField, TextFieldProps } from '@mui/material';

interface CustomProps {
  onChange: (event: any) => void;
  name: string;
  mask: string;
  'data-testid'?: string;
}

interface AppMaskedInputProps extends Omit<TextFieldProps, 'onChange'> {
  onChange: (event: any) => void;
  mask: string;
  name: string;
  'data-testid'?: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(
  props,
  ref,
) {
  const { onChange, mask, name, 'data-testid': testId, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask={mask}
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name, value } })}
      overwrite
      data-testid={testId}
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  mask: PropTypes.string.isRequired,
};

const AppMaskedInput: React.FC<AppMaskedInputProps> = ({
  onChange,
  mask,
  size = 'medium',
  variant = 'outlined',
  name,
  'data-testid': testId,
  ...props
}) => {
  return (
    <TextField
      {...props}
      name={name}
      onChange={onChange}
      size={size}
      variant={variant}
      slotProps={{
        input: {
          inputComponent: TextMaskCustom as any,
          inputProps: {
            mask,
            name,
            onChange,
            'data-testid': testId,
          },
        },
      }}
    />
  );
};

export default AppMaskedInput;
