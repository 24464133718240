import dayjs from 'dayjs';
import 'dayjs/locale/ru';

/**
 * Formats a date string or Date object to localized format
 * @param date - Date string or Date object
 * @param format - Optional format string (defaults to 'DD.MM.YYYY')
 * @returns Formatted date string
 */
export const formatDate = (date: string | Date | null, format: string = 'DD.MM.YYYY'): string => {
  if (!date) return '-';

  return dayjs(date).locale('ru').format(format);
};

export default formatDate;
