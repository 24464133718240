import React from 'react';
import { Card, CardHeader, CardContent, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import AppCard from '../../../shared/AppCard';
// import IncomeDistributionTop from '../IncomeDistributionTop';
import ExpenseDistribution from '../ExpenseDistribution';
import AppCardTitle from '../../../shared/AppCardTitle';
import TransactionsLogChart from '../TransactionsLogChart';
import { SberTransaction } from '../../../../types/SberTransaction';

interface AnalyticsReportsCardProps {
  dataNotOk: boolean;
  noDataEl: React.ReactNode;
  activeFundId: number | null;
  incomeTransactions: SberTransaction[];
  outcomeTransactions: SberTransaction[];
}

const AnalyticsReportsCard: React.FC<AnalyticsReportsCardProps> = ({
  dataNotOk,
  noDataEl,
  activeFundId,
  incomeTransactions,
  outcomeTransactions,
}) => {
  return (
    <Card
      elevation={0}
      sx={{
        mt: 3,
        border: 1,
        borderColor: 'border.main',
        borderRadius: '0.5rem',
      }}
    >
      <CardHeader
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <AppCardTitle>Аналитические отчеты</AppCardTitle>
          </Box>
        }
        sx={{ pb: 0 }}
      />

      <CardContent>
        {dataNotOk ? (
          noDataEl
        ) : (
          <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
              <AppCard
                noPadding={true}
                title="Движение средств"
                content={
                  <TransactionsLogChart
                    incomeTransactions={incomeTransactions}
                    outcomeTransactions={outcomeTransactions}
                  />
                }
              />
            </Grid>

            {!!activeFundId && (
              <>
                {/* <Grid size={{ xs: 12, md: 6 }}>
                  <Box sx={{ '& > *': { mb: 2 } }}>
                    <IncomeDistributionTop fundId={activeFundId} />
                  </Box>
                </Grid> */}

                {/* <Grid size={{ xs: 12, md: 12 }}> */}
                {/* <Box sx={{ '& > *': { mb: 2 } }}> */}
                <ExpenseDistribution fundId={activeFundId} />
                {/* </Box> */}
                {/* </Grid> */}
              </>
            )}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default AnalyticsReportsCard;
