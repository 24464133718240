import React, { Component } from 'react';
import AppHeader from '../../../components/shared/AppHeader';

interface DashboardProps {
  content: React.ReactNode;
}

class Dashboard extends Component<DashboardProps> {
  render() {
    const { content } = this.props;

    return (
      <div className="flex flex-col max-h-screen">
        <AppHeader />
        <main className="flex-1">
          {/* Show stub for mobile */}
          <div
            className="flex items-center justify-center text-lg font-semibold text-center text-gray-700 bg-gray-100 lg:hidden"
            style={{ height: ' 100dvh' }}
          >
            Мобильная версия программного продукта находится на модернизации. Пожалуйста,
            воспользуйтесь программным продуктом с персонального компьютера или ноутбука.
          </div>
          <div className="hidden lg:block">{content}</div>
        </main>
      </div>
    );
  }
}

export default Dashboard;
