import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import AppMaskedInput from '../../shared/AppMaskedInput';
import { INN_MASK_0 } from '../../../const/InputMasks';

interface AppInnDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (inn: string) => void;
}

interface AppInnDialogState {
  inn: string;
}

class AppInnDialog extends Component<AppInnDialogProps, AppInnDialogState> {
  constructor(props: AppInnDialogProps) {
    super(props);
    this.state = {
      inn: '',
    };
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, parent = ''): void => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<AppInnDialogState, keyof AppInnDialogState>);
  };

  handleSubmit = (): void => {
    this.props.onSubmit(this.state.inn);
  };

  render() {
    const { isOpen, onClose } = this.props;
    const { inn } = this.state;

    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Автозаполнение по ИНН
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="mb-5 text-sm text-gray-500">
                      Введите ИНН чтобы мы могли найти ваши данные
                    </p>

                    <AppMaskedInput
                      id="inn"
                      name="inn"
                      label="ИНН"
                      type="text"
                      value={inn}
                      onChange={this.handleInputChange}
                      mask={INN_MASK_0}
                      fullWidth={true}
                      color="primary"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: 'white',
                        },
                      }}
                    />
                  </div>

                  <div className="flex flex-row w-full mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-[#C9C8D6] px-4 py-2 text-sm font-medium text-[#8C89AC] focus:outline-none"
                      onClick={onClose}
                    >
                      Закрыть
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 ml-auto text-sm font-medium text-white rounded-md bg-primary focus:outline-none"
                      onClick={this.handleSubmit}
                    >
                      Найти
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
}

export default AppInnDialog;
