import React, { Component, Fragment } from 'react';
import { BankAccount } from '../../../types/BankAccount';
import { Dialog, Transition } from '@headlessui/react';
import { ACCOUNT_MASK_0, BIK_MASK_0, INN_MASK_0 } from '../../../const/InputMasks';
import { validateInput } from '../../../utils/validations';
import { BANK_ACCOUNT_REGEX, BIK_REGEX, INN_REGEX } from '../../../const/RegexValidations';
import { apiWrapper } from '../../../utils/api';
import AppMaskedInput from '../../shared/AppMaskedInput';
import { Button } from '@mui/material';
import AppInputV2 from '../../shared/AppInputV2';
import { toast } from 'react-toastify';

interface AppNewBankAccountDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  fundId?: number | string;
}

interface AppNewBankAccountDialogState extends BankAccount {
  errors: { [key: string]: string };
}

class AppNewBankAccountDialog extends Component<
  AppNewBankAccountDialogProps,
  AppNewBankAccountDialogState
> {
  constructor(props: AppNewBankAccountDialogProps) {
    super(props);

    this.state = {
      bank_name: '',
      corr_account: '',
      payment_account: '',
      inn: '',
      bank_bik: '',
      opening_date: '',
      errors: {},
      fund_id: -1,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<
      AppNewBankAccountDialogState,
      keyof AppNewBankAccountDialogState
    >);
  };

  onCloseHandler = (): void => {
    this.setState({
      bank_name: '',
      corr_account: '',
      payment_account: '',
      inn: '',
      bank_bik: '',
      opening_date: '',
      fund_id: Number(this.props.fundId),
    });
    this.props.onClose();
  };

  handleSubmit = async (): Promise<void> => {
    let me = this;
    let payload = { ...me.state } as any;
    payload['fund_id'] = Number(me.props.fundId);

    if (!payload['fund_id']) {
      toast.error('Не указан идентификатор фонда');
      return;
    }

    const { bank_name, payment_account, inn, bank_bik, opening_date } = this.state;

    let errors: { [key: string]: string } = {};

    const fields = {
      bank_name,
      // corr_account,
      payment_account,
      inn,
      bank_bik,
      opening_date,
    };

    const regexps = {
      inn: INN_REGEX,
      bank_bik: BIK_REGEX,
      // corr_account: BANK_ACCOUNT_REGEX,
      payment_account: BANK_ACCOUNT_REGEX,
    };

    for (let field in fields) {
      // @ts-ignore
      const error = validateInput(field, fields[field], regexps[field]);
      if (error) {
        errors[field] = error;
      }
    }

    this.setState({ errors });

    if (Object.keys(errors).length > 0) {
      return;
    }

    await apiWrapper.post('/bank_accounts', payload).then(() => {
      this.props.onSubmit();
      this.onCloseHandler();
    });
  };

  render() {
    const { isOpen } = this.props;
    const { errors } = this.state;
    const today = new Date().toISOString().split('T')[0];

    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={this.onCloseHandler}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-screen-lg p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-[28px] font-semibold leading-6 text-gray-900"
                  >
                    Добавление банковского счета
                  </Dialog.Title>

                  <div className="grid grid-cols-12 mt-10 gap-x-5">
                    <div className="col-span-12 space-y-4 lg:col-span-6">
                      <AppInputV2
                        id="bank_name"
                        name="bank_name"
                        label="НАИМЕНОВАНИЕ БАНКА"
                        type="text"
                        value={this.state.bank_name}
                        onChange={this.handleInputChange}
                        fullWidth
                        helperText={errors?.bank_name}
                        error={!!errors.bank_name}
                      />

                      <AppMaskedInput
                        id="inn"
                        name="inn"
                        label="ИНН"
                        type="text"
                        value={this.state.inn}
                        onChange={this.handleInputChange}
                        mask={INN_MASK_0}
                        fullWidth
                        helperText={errors?.inn}
                        error={!!errors.inn}
                      />

                      <AppInputV2
                        id="opening_date"
                        name="opening_date"
                        label="ДАТА ОТКРЫТИЯ"
                        type="date"
                        value={this.state.opening_date}
                        onChange={this.handleInputChange}
                        fullWidth
                        helperText={errors?.opening_date}
                        error={!!errors.opening_date}
                        slotProps={{
                          input: {
                            inputProps: {
                              max: today,
                            },
                          },
                          inputLabel: { shrink: true },
                        }}
                      />
                    </div>

                    <div className="col-span-12 space-y-4 lg:col-span-6">
                      <AppMaskedInput
                        id="payment_account"
                        name="payment_account"
                        label="РАСЧЕТНЫЙ СЧЕТ"
                        type="text"
                        value={this.state.payment_account}
                        onChange={this.handleInputChange}
                        mask={ACCOUNT_MASK_0}
                        fullWidth
                        helperText={errors?.payment_account}
                        error={!!errors.payment_account}
                      />

                      <AppMaskedInput
                        id="corr_account"
                        name="corr_account"
                        label="КОРРЕСПОНДЕНТСКИЙ СЧЕТ"
                        type="text"
                        value={this.state.corr_account}
                        onChange={this.handleInputChange}
                        mask={ACCOUNT_MASK_0}
                        fullWidth
                        // helperText={errors?.corr_account}
                        // error={!!errors.corr_account}
                      />

                      <AppMaskedInput
                        id="bank_bik"
                        name="bank_bik"
                        label="БИК"
                        type="text"
                        value={this.state.bank_bik}
                        onChange={this.handleInputChange}
                        mask={BIK_MASK_0}
                        fullWidth
                        helperText={errors?.bank_bik}
                        error={!!errors.bank_bik}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row justify-between w-full mt-4">
                    <div className={`ml-auto`}>
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleSubmit()}
                        className="!font-semibold"
                        size="large"
                      >
                        Добавить банковский счет
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
}

export default AppNewBankAccountDialog;
