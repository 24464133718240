import React from 'react';
import { BankAccount } from '../../../../types/BankAccount';

interface BalanceDisplayProps {
  bankAccounts?: BankAccount[];
  isLoading?: boolean;
  error?: boolean;
}

const AppBalanceDisplay: React.FC<BalanceDisplayProps> = ({ bankAccounts, isLoading, error }) => {
  const calculateTotalBalance = () => {
    if (!bankAccounts?.length) return '-';

    return bankAccounts.reduce((sum, account) => {
      return sum + (Number(account?.balance) || 0);
    }, 0);
  };

  const totalBalance = calculateTotalBalance();

  return (
    <div className={`font-semibold py-2 px-5 rounded-xl uppercase text-sm text-black`}>
      Баланс&nbsp;
      {isLoading
        ? 'Вычисляется'
        : error
        ? '-'
        : totalBalance !== null
        ? `${totalBalance.toLocaleString()} руб`
        : 'Вычисляется'}
    </div>
  );
};

export default AppBalanceDisplay;
