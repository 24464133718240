export const PHONE_MASK = '+7 (999) 999-99-99';
export const INN_MASK = '999999999999';
export const KPP_MASK = '999999999';
export const OGRN_MASK = '999999999999999';
export const PAYMENT_ACCOUNT_MASK = '99999999999999999999';
export const CORR_ACCOUNT_MASK = '99999999999999999999';
export const BIK_MASK = '999999999';

export const PHONE_MASK_0 = '+7 (000) 000-00-00';
export const INN_MASK_0 = '000000000000';
export const ACCOUNT_MASK_0 = '00000000000000000000';
export const BIK_MASK_0 = '000000000';
export const KPP_MASK_0 = '000000000';
export const OGRN_MASK_0 = '000000000000000';
