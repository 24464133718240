import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { apiWrapper } from '../../../utils/api';
import { Dialog, Transition } from '@headlessui/react';
import dayjs from 'dayjs';
import AppNumberWithSpaces from '../../shared/AppNumberWithSpaces';

interface LoanRepayment {
  id: number;
  amount: number;
  repayment_date: string;
}

interface LoanRepaymentsModalProps {
  isOpen: boolean;
  onClose: () => void;
  loanId: number | null;
}

const LoanRepaymentsModal: React.FC<LoanRepaymentsModalProps> = ({ isOpen, onClose, loanId }) => {
  const [repayments, setRepayments] = useState<LoanRepayment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [amount, setAmount] = useState<number | ''>('');
  const [repaymentDate, setRepaymentDate] = useState<string>('');
  const [loan, setLoan] = useState<any>([]);

  useEffect(() => {
    if (loanId && isOpen) {
      loadRepayments().then(() => {});
    }
    // eslint-disable-next-line
  }, [loanId, isOpen]);

  const loadRepayments = async () => {
    setLoading(true);
    try {
      const response = await apiWrapper.get(`/loans/${loanId}`);
      // @ts-ignore
      setRepayments(response.data.loan_repayments);
      setLoan(response.data);
    } catch (error) {
      console.error('Failed to load repayments:', error);
      toast.error('Ошибка при загрузке платежей');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateRepayment = async () => {
    if (!amount || !repaymentDate) {
      toast.error('Введите сумму и дату платежа');
      return;
    }

    try {
      await apiWrapper.post(`/loan_repayments?loan_id=${loanId}`, {
        loan_repayment: { amount, repayment_date: repaymentDate },
      });
      // setRepayments(response.loan_repayments);
      setAmount('');
      setRepaymentDate('');
      toast.success('Платеж успешно добавлен');
      await loadRepayments();
    } catch (error) {
      console.error('Failed to create repayment:', error);
      toast.error('Ошибка при добавлении платежа');
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[1200px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 uppercase"
                >
                  определение сроков выплат и платежей по задолженности
                </Dialog.Title>

                {loading ? (
                  <div className="mt-4 text-center">Загрузка...</div>
                ) : (
                  <>
                    <div className="mt-4 space-y-4">
                      <span className="text-base font-medium leading-6 text-gray-900">
                        Общий займ
                      </span>
                      <br />
                      <span className="text-xl font-semibold text-[#AC0003]">
                        -<AppNumberWithSpaces value={loan.amount} />
                        &nbsp;₽
                      </span>
                    </div>
                    <div className="grid grid-cols-3 gap-4 mt-4">
                      {repayments.map((repayment) => (
                        <React.Fragment key={repayment.id}>
                          {/* Column 1: Date */}
                          <div className="p-2 text-left">
                            <span className="text-base font-medium leading-6 text-gray-900">
                              Дата возврата займа
                            </span>
                            <br />
                            {dayjs(repayment.repayment_date).format('DD.MM.YYYY')}
                          </div>

                          {/* Column 2: Amount */}
                          <div className="p-2 text-left">
                            <span className="text-base font-medium leading-6 text-gray-900">
                              Сумма возврата займа
                            </span>
                            <br />
                            {repayment.amount} ₽
                          </div>

                          {/* Column 3: Empty */}
                          <div className="p-2 text-left">
                            <span className="text-base font-medium leading-6 text-gray-900">
                              Остаток займа после возврата
                            </span>
                            <br />

                            <span
                              className={
                                loan?.remaining_balance > 0 ? 'text-[#AC0003]' : ' text-green-500'
                              }
                            >
                              {loan?.remaining_balance > 0 && <>-</>}
                              <AppNumberWithSpaces value={loan.amount - repayment.amount} /> ₽
                            </span>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="mt-6">
                      <h4 className="text-lg font-medium">Добавить новый платеж</h4>
                      <div className="grid grid-cols-3 gap-4 mt-2">
                        <div>
                          <input
                            type="number"
                            placeholder="Сумма"
                            value={amount}
                            onChange={(e) => setAmount(Number(e.target.value))}
                            className="block w-full border-gray-300 rounded-md shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                          />
                        </div>
                        <div>
                          <input
                            type="date"
                            placeholder="Дата платежа"
                            value={repaymentDate}
                            onChange={(e) => setRepaymentDate(e.target.value)}
                            className="block w-full border-gray-300 rounded-md shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                          />
                        </div>
                        <div>
                          <button
                            className=" bg-primary text-white rounded-md px-4 py-1.5"
                            onClick={handleCreateRepayment}
                          >
                            Добавить платеж
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="flex justify-end mt-6">
                  <button
                    className="px-4 py-2 text-gray-800 bg-gray-300 rounded-md"
                    onClick={onClose}
                  >
                    Закрыть
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LoanRepaymentsModal;
