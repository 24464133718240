import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';

interface AppExpandButtonProps {
  to: string;
  className?: string;
}

const AppExpandButton: React.FC<AppExpandButtonProps> = ({ to, className }) => {
  return (
    <IconButton component={Link} to={to} color="primary" size="small" className={className}>
      <i className="far fa-expand-arrows text-[#06F] text-[24px]" data-testid={`expand-icon`}></i>
    </IconButton>
  );
};

export default AppExpandButton;
