import React, { useEffect, useState } from 'react';
import AppCard from '../../../shared/AppCard';
import { apiWrapper } from '../../../../utils/api';
import AppEntityPercentageDoughnutChart from '../../../shared/AppEntityPercentageDoughnutChart';
import colors from '../../../../style/colors.json';
import AppNumberWithSpaces from '../../../shared/AppNumberWithSpaces';
import Grid from '@mui/material/Grid2';
import { Box } from '@mui/material';

interface TopExpense {
  inn: string | null;
  label: string | null;
  total_amount: number;
}

interface ExpenseDistributionProps {
  fundId: number;
}

const TOP = 8;

const ExpenseDistribution: React.FC<ExpenseDistributionProps> = ({ fundId }) => {
  const [expenses, setExpenses] = useState<TopExpense[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchExpenseDistribution = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await apiWrapper.get(`/funds/${fundId}/top_expenses`);
      setExpenses((response?.data as TopExpense[]) || []);
    } catch (error) {
      setError('Произошла ошибка при загрузке данных');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (fundId) {
      fetchExpenseDistribution();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundId]);

  if (loading) {
    return (
      <AppCard
        title="Распределение расходов (Топ 8)"
        content={<div className="flex justify-center py-5">Загрузка</div>}
      />
    );
  }

  if (error) {
    return (
      <AppCard
        title="Распределение расходов (Топ 8)"
        content={<div className="py-5 text-center text-red-500">{error}</div>}
      />
    );
  }

  const top = expenses.slice(0, TOP);

  if (!top.length) {
    return (
      <>
        <Grid size={{ xs: 12, md: 6 }}>
          <Box sx={{ '& > *': { mb: 2 } }}>
            <AppCard
              title={`Распределение расходов (Топ ${TOP})`}
              content={<div className="flex justify-center py-5 text-gray-500">Нет данных</div>}
            />
          </Box>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Box sx={{ '& > *': { mb: 2 } }}>
            <AppCard
              title={`Топ расходов (Топ ${TOP})`}
              content={<div className="flex justify-center py-5 text-gray-500">Нет данных</div>}
            />
          </Box>
        </Grid>
      </>
    );
  }

  const labels = top.map((item) =>
    item.label
      ? item.label.replace('ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ', 'ООО')
      : 'Неизвестный получатель',
  );
  const data = top.map((item) => item.total_amount);

  return (
    <>
      <Grid size={{ xs: 12, md: 6 }}>
        <Box sx={{ '& > *': { mb: 2 } }}>
          <AppCard
            title={`Распределение расходов (Топ ${TOP})`}
            content={
              <AppEntityPercentageDoughnutChart
                data={{
                  labels,
                  datasets: [
                    {
                      label: 'руб',
                      data,
                      backgroundColor: colors.slice(0, top.length),
                      borderColor: colors.slice(0, top.length).map(() => '#ffffff'),
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            }
          />
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Box sx={{ '& > *': { mb: 2 } }}>
          <AppCard
            title={`Топ расходов (Топ ${TOP})`}
            content={
              <div className="flex flex-col w-full space-y-2">
                {top?.map((item, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-12 text-lg font-semibold border border-[#C9C8D6] rounded p-2 gap-x-2"
                  >
                    <div className="col-span-2 text-center md:col-span-1 text-primary">
                      #{index + 1}
                    </div>
                    <div className="col-span-6 md:col-span-7 text-[#2C2C2C]">
                      {item.label
                        ? item.label.replace('ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ', 'ООО')
                        : 'Неизвестный получатель'}
                    </div>
                    <div className="col-span-4 text-right md:col-span-4 text-primary">
                      <AppNumberWithSpaces value={Number(item.total_amount)} />
                      &nbsp;₽
                    </div>
                  </div>
                ))}
              </div>
            }
          />
        </Box>
      </Grid>
    </>
  );
};

export default ExpenseDistribution;
