import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import LimitCharts from '../../LimitCharts';

interface OverviewTabProps {
  limitsOverview: any;
  regionalBranchesChartData: any[];
  formatCurrency: (amount: number) => string;
  getLimitColor: (percent: number) => 'success' | 'warning' | 'error';
}

const OverviewTab: React.FC<OverviewTabProps> = ({
  limitsOverview,
  regionalBranchesChartData,
  formatCurrency,
  getLimitColor,
}) => {
  return (
    <Box>
      {/* Overview dashboard */}
      <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-2">
        {/* Party total card */}
        <Card>
          <CardContent>
            <Typography variant="h6" className="mb-2">
              Общая сумма пожертвований Партии
            </Typography>

            {limitsOverview ? (
              <>
                <div className="flex justify-between mb-1">
                  <Typography variant="body2" color="textSecondary">
                    Текущая сумма:
                  </Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {formatCurrency(limitsOverview.party_total.current)}
                  </Typography>
                </div>

                <div className="flex justify-between mb-2">
                  <Typography variant="body2" color="textSecondary">
                    Лимит:
                  </Typography>
                  <Typography variant="body1">
                    {formatCurrency(limitsOverview.party_total.limit)}
                  </Typography>
                </div>

                <LinearProgress
                  variant="determinate"
                  value={limitsOverview.party_total.percentage}
                  color={getLimitColor(limitsOverview.party_total.percentage)}
                  sx={{ height: 10, borderRadius: 5 }}
                />

                <Typography variant="body2" className="mt-1 text-right">
                  {limitsOverview.party_total.percentage.toFixed(1)}%
                </Typography>
              </>
            ) : (
              <CircularProgress size={24} />
            )}
          </CardContent>
        </Card>

        {/* Regional branches status card */}
        <Card>
          <CardContent>
            <Typography variant="h6" className="mb-2">
              Региональные отделения с превышением лимита
            </Typography>

            {limitsOverview ? (
              <div className="overflow-y-auto max-h-40">
                {limitsOverview.regional_branches
                  .filter((branch: any) => branch.limit_exceeded)
                  .map((branch: any, index: number) => (
                    <div key={branch.id || index} className="pb-2 mb-2 border-b">
                      <Typography variant="body2">{branch.name}</Typography>
                      <div className="flex items-center justify-between">
                        <Typography variant="caption" color="error">
                          {branch.limit_percentage.toFixed(1)}% от лимита
                        </Typography>
                        <Typography variant="caption">
                          {formatCurrency(branch.total_donations)}
                        </Typography>
                      </div>
                    </div>
                  ))}

                {limitsOverview.regional_branches.filter((b: any) => b.limit_exceeded).length ===
                  0 && (
                  <Typography variant="body2" color="textSecondary" className="py-4 text-center">
                    Нет отделений с превышением лимита
                  </Typography>
                )}
              </div>
            ) : (
              <CircularProgress size={24} />
            )}
          </CardContent>
        </Card>
      </div>

      {/* Regional branches chart */}
      <div className="mb-4">
        <LimitCharts
          type="regional_branches"
          title="ТОП-10 региональных отделений по сумме пожертвований"
          data={regionalBranchesChartData}
          loading={!limitsOverview}
          formatCurrency={formatCurrency}
        />
      </div>
    </Box>
  );
};

export default OverviewTab;
