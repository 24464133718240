import React, { Component } from 'react';
import styles from './AppLogo.module.scss';

interface AppLogoProps {}

interface AppLogoState {
  withAdminTitle: boolean;
}

class AppLogo extends Component<AppLogoProps, AppLogoState> {
  constructor(props: AppLogoProps) {
    super(props);
    this.state = {
      withAdminTitle: window.location.pathname.includes('admin'),
    };
  }

  render() {
    const { withAdminTitle } = this.state;

    return (
      <div className="flex flex-row items-center uppercase cursor-default">
        <div className="flex flex-row h-full flex-nowrap">
          <div className="h-full px-2 text-4xl font-semibold bg-white border-2 border-white rounded-lg text-primary">
            АИС
          </div>
          <div
            className={`
                        text-xs
                        font-bold
                        flex
                        flex-col
                        h-auto
                        justify-center
                        border-2
                        border-white
                        border-l-0
                        rounded-r-lg
                        pl-4 pr-3
                        ${styles.moveLeft}
                    `}
          >
            <div>Цифровой</div>
            <div>фонд</div>
          </div>
        </div>
        {withAdminTitle && (
          <div className="flex-col justify-center hidden h-full ml-3 text-xs font-bold text-center xl:flex">
            <div>Администратор</div>
            <div>системы</div>
          </div>
        )}
      </div>
    );
  }
}

export default AppLogo;
