import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { SberTransaction } from '../../../../types/SberTransaction';
import formatDate from '../../../../utils/formatDate';
import AppNumberWithSpaces from '../../../shared/AppNumberWithSpaces';
import AppCopyableField from '../../../shared/AppCopyableField';

interface AppTransactionModalProps {
  transaction: SberTransaction | null;
  open: boolean;
  onClose: () => void;
}

const FieldRow: React.FC<{ label: string; value: string | number; copyable?: boolean }> = ({
  label,
  value,
  copyable = true,
}) => (
  <Box display="flex" py={1}>
    <Typography variant="body2" color="text.secondary" sx={{ width: 160, fontWeight: 600 }}>
      {label}
    </Typography>
    <AppCopyableField value={value} showCopyButton={copyable} />
  </Box>
);

const PartySection: React.FC<{
  title: string;
  isHighlighted: boolean;
  inn: string;
  bankName: string;
  bankBic: string;
  account: string;
}> = ({ title, isHighlighted, inn, bankName, bankBic, account }) => {
  const theme = useTheme();

  return (
    <Paper variant="outlined" sx={{ p: 2, mt: 2 }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          fontWeight: isHighlighted ? 600 : 600,
          color: isHighlighted ? theme.palette.primary.main : theme.palette.appGreen.dark,
        }}
      >
        {title}
      </Typography>
      <Box>
        <FieldRow label="ИНН" value={inn} />
        <FieldRow label="Банк" value={bankName} />
        <FieldRow label="БИК Банка" value={bankBic} />
        <FieldRow label="Счет" value={account} />
      </Box>
    </Paper>
  );
};

const AppTransactionModal: React.FC<AppTransactionModalProps> = ({
  transaction,
  open,
  onClose,
}) => {
  const theme = useTheme();
  const isPositive = !!transaction?.amount && Number(transaction.amount) > 0;
  const amount = transaction?.amount ? Math.abs(transaction.amount) : 0;

  if (!transaction) return null;

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      disableEscapeKeyDown
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" component="span" sx={{ fontWeight: 600 }}>
            Детали транзакции
          </Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <i className="far fa-times"></i>
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12 }}>
            <Box sx={{ mb: 3 }}>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  color: isPositive ? theme.palette.success.main : theme.palette.error.main,
                  fontWeight: 600,
                }}
              >
                {isPositive ? '+' : '-'} <AppNumberWithSpaces value={amount} /> ₽
              </Typography>
              <Typography variant="subtitle1" sx={{ mt: 1 }}>
                {formatDate(transaction.date_of_execution)}
              </Typography>
            </Box>

            <Typography variant="body1" sx={{ mb: 3 }}>
              {transaction.payment_purpose || 'Назначение платежа не указано'}
            </Typography>

            <Divider sx={{ my: 3 }} />

            <PartySection
              title="Плательщик"
              isHighlighted={!isPositive}
              inn={!!transaction?.payer_inn ? String(transaction?.payer_inn) : ''}
              bankName={!!transaction?.payer_bank_name ? String(transaction?.payer_bank_name) : ''}
              bankBic={!!transaction?.payer_bank_bic ? String(transaction?.payer_bank_bic) : ''}
              account={!!transaction?.payer_account ? String(transaction?.payer_account) : ''}
            />

            <PartySection
              title="Получатель"
              isHighlighted={isPositive}
              inn={!!transaction?.payee_inn ? String(transaction?.payee_inn) : ''}
              bankName={!!transaction?.payee_bank_name ? String(transaction?.payee_bank_name) : ''}
              bankBic={!!transaction?.payee_bank_bic ? String(transaction?.payee_bank_bic) : ''}
              account={!!transaction?.payee_account ? String(transaction?.payee_account) : ''}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AppTransactionModal;
