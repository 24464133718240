import React, { createContext, useState, ReactNode } from 'react';

interface PageContextProps {
  pageName: string;
  setPageName: (name: string) => void;
}

export const PageContext = createContext<PageContextProps>({
  pageName: '',
  setPageName: () => {},
});

export const PageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [pageName, setPageName] = useState('');

  return <PageContext.Provider value={{ pageName, setPageName }}>{children}</PageContext.Provider>;
};
