import React from 'react';
import { Typography } from '@mui/material';

interface AppCardTitleProps {
  children: React.ReactNode;
}

const AppCardTitle: React.FC<AppCardTitleProps> = ({ children }) => (
  <Typography
    variant="subtitle2"
    className="!font-semibold uppercase cursor-default text-white rounded-lg px-5 py-2 bg-primary"
  >
    {children}
  </Typography>
);

export default AppCardTitle;
