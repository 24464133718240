import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Fund } from '../../../types/Fund';

interface AppFundSelectorProps {
  funds: Fund[];
  selectedFundId: string | null;
  onSelect: (fund: Fund | null) => void;
}

const AppFundSelector: React.FC<AppFundSelectorProps> = ({ funds, selectedFundId, onSelect }) => {
  const selectedFund = selectedFundId ? funds.find((f) => String(f.id) === selectedFundId) : null;

  return (
    <div className="p-4 bg-white rounded-lg">
      <Autocomplete
        options={funds.slice().sort((a, b) => a.name.localeCompare(b.name, 'ru'))}
        getOptionLabel={(option) => option.name}
        value={selectedFund}
        onChange={(_, newValue) => onSelect(newValue)}
        renderInput={(params) => <TextField {...params} label="Выберите фонд" variant="outlined" />}
        noOptionsText="Фонды не найдены"
        loadingText="Загрузка..."
        clearText="Очистить"
        openText="Открыть"
        closeText="Закрыть"
      />
    </div>
  );
};

export default AppFundSelector;
