import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import AppFileInput from '../../../shared/AppFileInput';
import { errorToast } from '../../../../utils/errorToast';
import { BankAccount } from '../../../../types/BankAccount';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import BankAccountFastViewer from '../bank_account_viewer/BankAccountFastViewer';
import { apiWrapper } from '../../../../utils/api';
import { CircularProgress } from '@mui/material';

const STORAGE_KEY = 'lastSelectedBankAccount';

interface AppImportTransactionsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  accounts: BankAccount[];
}

interface AppImportTransactionsDialogState {
  selectedAccount: BankAccount | null;
  uploadedTransactions: null | any[];
  selectedBankType: 'sber' | 'akbars' | 'rnkb' | 'fora';
  isLoading: boolean;
}

class AppImportTransactionsDialog extends Component<
  AppImportTransactionsDialogProps,
  AppImportTransactionsDialogState
> {
  constructor(props: AppImportTransactionsDialogProps) {
    super(props);

    this.state = {
      selectedAccount: props.accounts.length ? props.accounts[0] : null,
      uploadedTransactions: null,
      selectedBankType: 'sber',
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps: AppImportTransactionsDialogProps) {
    if (this.props.accounts !== prevProps.accounts && this.props.accounts.length) {
      const lastSelectedAccountNumber = localStorage.getItem(STORAGE_KEY);
      const accountToSelect = lastSelectedAccountNumber
        ? this.props.accounts.find((acc) => acc.payment_account === lastSelectedAccountNumber)
        : this.props.accounts[0];

      this.setState({ selectedAccount: accountToSelect || this.props.accounts[0] });
    }
  }

  setCurrentAccount = (account: BankAccount): void => {
    this.setState({ selectedAccount: account, uploadedTransactions: null });
    // Save to localStorage
    localStorage.setItem(STORAGE_KEY, account.payment_account);
  };

  handleFilesOnChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    let me = this;
    this.setState({ isLoading: true });

    try {
      const files = event.target.files;

      if (files) {
        for (let i = 0; i < files.length; i++) {
          const formData = new FormData();
          formData.append('file_object', files[i]);

          if (me.state.selectedAccount && me.state.selectedAccount.id) {
            formData.append('bank_account_id', String(me.state.selectedAccount.id));
          }

          formData.append('bank_type', me.state.selectedBankType);

          await apiWrapper.postFile(`/users/sber/upload_file`, formData).then((response) => {
            toast(response.data.status, {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 5000,
              type: toast.TYPE.INFO,
              pauseOnHover: true,
            });

            try {
              me.setState({
                uploadedTransactions: response.data.progress.transactions,
              });
            } catch (e) {}
          });
        }
      }
    } catch (e) {
      console.log(e);
      errorToast('Возникла ошибка. Что-то пошло не так');
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleBankTypeChange = (bankType: 'sber' | 'akbars' | 'rnkb' | 'fora'): void => {
    this.setState({ selectedBankType: bankType });
  };

  render() {
    let me = this;
    const { isOpen, onClose } = this.props;
    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-screen-xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <div>
                    <p className={`m-0 uppercase font-semibold mb-3`}>
                      Выбрать банковский счёт для импорта транзакций
                    </p>
                    <div className={`flex flex-row w-full overflow-x-scroll flex-nowrap gap-x-3`}>
                      {this.props.accounts.map((account: BankAccount, index: number) => (
                        <div
                          key={index}
                          className={`text-white rounded-lg py-1.5 px-3 cursor-pointer ${
                            this.state?.selectedAccount?.id === account.id
                              ? 'bg-primary'
                              : 'bg-[#9C9C9C]'
                          }`}
                          onClick={() => this.setCurrentAccount(account)}
                        >
                          {account.payment_account}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <p className={`m-0 mb-4 mt-8 font-semibold text-2xl`}>
                      Импорт банковских транзакций
                    </p>

                    <div
                      className={`border border-[#BDBDBD] rounded-2xl drop-shadow-lg w-full p-5`}
                    >
                      <table className={`w-full`}>
                        <thead>
                          <tr>
                            <th className={`text-[14px] font-medium`}>Банк</th>
                            <th className={`text-[14px] font-medium`}>Номер счета</th>
                            <th className={`text-[14px] font-medium`}>Дата открытия</th>
                            <th className={`text-[14px] font-medium`}>
                              Дата последнего импорта данных
                            </th>
                            <th className={`text-[14px] font-medium`}>Текущий баланс</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className={`text-[16px] font-semibold`}>
                              {this.state.selectedAccount?.bank_name}
                            </td>
                            <td className={`text-[16px] font-semibold`}>
                              {this.state.selectedAccount?.payment_account}
                            </td>
                            <td className={`text-[16px] font-semibold`}>
                              {!!this.state.selectedAccount?.opening_date
                                ? dayjs(this.state.selectedAccount?.opening_date).format(
                                    'DD.MM.YYYY',
                                  )
                                : 'Не указана'}
                            </td>
                            <td className={`text-[16px] font-semibold`}>
                              {this.state.selectedAccount?.last_file_upload_date}
                            </td>
                            <td className={`text-[16px] font-semibold`}>
                              {this.state.selectedAccount && (
                                <BankAccountFastViewer bankAccount={this.state.selectedAccount} />
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="mt-6 mb-4">
                      <p className="mb-2 font-semibold">Выберите тип банка для импорта:</p>
                      <div className="flex space-x-4">
                        <div
                          className={`flex items-center px-4 py-2 rounded-md cursor-pointer transition-colors font-semibold ${
                            this.state.selectedBankType === 'sber'
                              ? 'bg-[#056636] text-white'
                              : 'bg-gray-100 text-gray-700'
                          }`}
                          onClick={() => this.handleBankTypeChange('sber')}
                        >
                          <span>Сбербанк</span>
                        </div>

                        <div
                          className={`flex items-center px-4 py-2 rounded-md cursor-pointer transition-colors font-semibold ${
                            this.state.selectedBankType === 'akbars'
                              ? 'bg-[#009B3A] text-white'
                              : 'bg-gray-100 text-gray-700'
                          }`}
                          onClick={() => this.handleBankTypeChange('akbars')}
                        >
                          <span>Ак Барс Банк</span>
                        </div>
                        <div
                          className={`flex items-center px-4 py-2 rounded-md cursor-pointer transition-colors font-semibold ${
                            this.state.selectedBankType === 'rnkb'
                              ? 'bg-[#08A8C1] text-white'
                              : 'bg-gray-100 text-gray-700'
                          }`}
                          onClick={() => this.handleBankTypeChange('rnkb')}
                        >
                          <span>РНКБ</span>
                        </div>
                        <div
                          className={`flex items-center px-4 py-2 rounded-md cursor-pointer transition-colors font-semibold ${
                            this.state.selectedBankType === 'fora'
                              ? 'bg-[#F70300] text-white'
                              : 'bg-gray-100 text-gray-700'
                          }`}
                          onClick={() => this.handleBankTypeChange('fora')}
                        >
                          <span>ФОРАБАНК</span>
                        </div>
                      </div>
                    </div>

                    <div className={`mt-5`}>
                      <div className="flex items-center mb-2">
                        <i className="pl-3 mb-2 mr-2 text-xl text-green-600 far fa-file-excel"></i>
                        <p className={`mb-0 text-[#222222] font-semibold`}>
                          Выберите файлы{' '}
                          <span className="font-normal text-gray-500">
                            (только Excel-файлы формата .xls, .xlsx)
                          </span>
                          <p className="text-sm text-gray-900">
                            Обратите внимание: обрабатывается только первый лист файла
                          </p>
                        </p>
                      </div>
                      <AppFileInput
                        onChange={this.handleFilesOnChange}
                        multiple={true}
                        disabled={this.state.isLoading}
                      />

                      {this.state.isLoading && (
                        <div className="flex items-center justify-center mt-4 mb-4">
                          <CircularProgress color="primary" size={24} />
                          <span className="ml-2 text-gray-600">Загрузка файла...</span>
                        </div>
                      )}

                      {me.state.uploadedTransactions && (
                        <table
                          className={'mt-4 app-import-upload-result rounded-2xl'}
                          style={{
                            width: '100%',
                            border: '1px solid #C9C8D6',
                            overflow: 'hidden',
                            padding: '30px',
                            background: '#E8ECF4',
                            fontSize: '10px',
                          }}
                        >
                          <tr style={{ fontWeight: 600, borderBottom: '1px solid #DDDDDD' }}>
                            <td style={{ padding: '8px', textTransform: 'uppercase' }}>Тип</td>
                            <td style={{ padding: '8px', textTransform: 'uppercase' }}>
                              Плательщик
                            </td>
                            <td style={{ padding: '8px', textTransform: 'uppercase' }}>Сумма</td>
                            <td style={{ padding: '8px', textTransform: 'uppercase' }}>Дата</td>
                            <td style={{ padding: '8px', textTransform: 'uppercase' }}>
                              Назначение
                            </td>
                            <td style={{ padding: '8px', textTransform: 'uppercase' }}>Статус</td>
                          </tr>
                          {me.state.uploadedTransactions.map((_tr, _trIndex) => {
                            let style = { padding: '8px' };
                            return (
                              <tr style={{}}>
                                <td style={style}>{_tr.amount > 0 ? 'Поступление' : 'Расход'}</td>
                                <td style={style}>
                                  {_tr.amount > 0 ? _tr.payer_name : _tr.payee_name}
                                </td>
                                <td style={style}>
                                  {Math.abs(_tr.amount).toLocaleString()}&nbsp;руб
                                </td>
                                <td style={style}>{_tr.date_str}</td>
                                <td style={style}>{_tr.payment_purpose}</td>
                                <td style={style}>{_tr.is_new ? 'Загружен' : 'Дубль'}</td>
                                {/*<td>{JSON.stringify(_tr)}</td>*/}
                              </tr>
                            );
                          })}
                        </table>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-row flex-wrap justify-between w-full mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 ml-auto text-sm font-medium text-white rounded-md bg-primary focus:outline-none"
                      onClick={this.props.onClose}
                    >
                      Завершить
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
}

export default AppImportTransactionsDialog;
