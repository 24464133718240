import axios, {
  AxiosError,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { errorToast } from './errorToast';

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  isFile?: boolean;
}

export const api = axios.create({
  baseURL: `/api`,
});

// Request interceptor to add the auth token to headers
api.interceptors.request.use(
  (config: CustomAxiosRequestConfig) => {
    const token = localStorage.getItem('auth_token');

    if (token) {
      (config.headers as AxiosRequestHeaders)['Authorization'] = `${token}`;
    }

    // Handle file uploads (FormData)
    if (config.isFile) {
      // Let Axios set the Content-Type to multipart/form-data
      delete (config.headers as AxiosRequestHeaders)['Content-Type'];
    } else {
      // Ensure the Content-Type is application/json for JSON payloads
      (config.headers as AxiosRequestHeaders)['Content-Type'] = 'application/json';
    }

    return config;
  },
  (error: any) => Promise.reject(error),
);

// Response interceptor to handle errors and store auth token
api.interceptors.response.use(
  (response: AxiosResponse) => {
    // Store auth token from response headers if present
    const authHeader = response.headers['authorization'];
    if (authHeader) {
      const token = authHeader.split(' ')[1];
      localStorage.setItem('auth_token', token);
    }

    // Check if responseType is 'blob' or 'arraybuffer'
    if (
      response.config.responseType &&
      ['blob', 'arraybuffer'].includes(response.config.responseType)
    ) {
      // Return the full response for blob or arraybuffer
      return response;
    }

    return response; // Return response data directly for other types
  },
  (error: AxiosError) => {
    if (error.response) {
      const resp = error.response.data as any;
      errorToast(resp.errors || resp.error || resp.message || 'Возникла ошибка');
    } else if (error.request) {
      errorToast('No response received from server');
    } else {
      errorToast(error.message);
    }
    return Promise.reject(error);
  },
);

// Wrapper methods for HTTP verbs
export const apiWrapper = {
  get: async <T = any>(url: string, headers?: AxiosRequestHeaders) => {
    return await api.get<T>(url, { headers });
  },

  post: async <T = any>(url: string, payload?: any, headers?: AxiosRequestHeaders) => {
    return await api.post<T>(url, payload, { headers });
  },

  put: async <T = any>(url: string, payload?: any, headers?: AxiosRequestHeaders) => {
    return await api.put<T>(url, payload, { headers });
  },

  patch: async <T = any>(url: string, payload?: any, headers?: AxiosRequestHeaders) => {
    return await api.patch<T>(url, payload, { headers });
  },

  delete: async <T = any>(url: string, headers?: AxiosRequestHeaders) => {
    return await api.delete<T>(url, { headers });
  },

  postFile: async <T = any>(url: string, payload: any, headers?: AxiosRequestHeaders) => {
    return await api.post<T>(url, payload, {
      headers,
      isFile: true,
    } as CustomAxiosRequestConfig);
  },

  getBlob: async (url: string, headers?: AxiosRequestHeaders): Promise<Blob> => {
    try {
      const response = await api.get(url, {
        headers,
        responseType: 'blob',
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching blob:', error);
      throw error;
    }
  },
};
