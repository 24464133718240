import React, { useContext } from 'react';
import AppLogo from '../AppLogo';
import AppDrawerNavigation from '../AppDrawerNavigation';
import { PageContext } from '../../../contexts/PageContext';

const AppHeader: React.FC = () => {
  const { pageName } = useContext(PageContext);

  return (
    <header className="z-10 drop-shadow-md bg-primary">
      <div className="px-5 text-white">
        <div className="flex items-center justify-between h-28">
          <div className="flex items-center">
            <AppDrawerNavigation />
            <div className="text-lg font-semibold">{pageName}</div>
          </div>

          <div className="flex items-center">
            <AppLogo />
          </div>
        </div>
      </div>
    </header>
  );
};

export default AppHeader;
