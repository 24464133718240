import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@mui/material';

interface SberBankStubModalProps {
  open: boolean;
  onClose: () => void;
}

const SberBankStubModal: React.FC<SberBankStubModalProps> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      slots={{
        backdrop: () => (
          <div
            data-testid="backdrop"
            onClick={onClose}
            className="MuiBackdrop-root"
            style={{
              position: 'fixed',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              right: 0,
              bottom: 0,
              top: 0,
              left: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              WebkitTapHighlightColor: 'transparent',
              zIndex: -1,
            }}
          />
        ),
      }}
    >
      <DialogTitle component="div">
        <Typography variant="h5" component="h2" fontWeight="500">
          СБЕРБАНК БИЗНЕС ОНЛАЙН
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" color="text.secondary">
            Для автоматической загрузки данных по указанному счёту из сервиса «СБЕРБАНК БИЗНЕС
            ОНЛАЙН» необходимо продление действия ключа обмена данными между системами по решению
            федерального руководства ПАО «Сбербанк»
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          color="primary"
          sx={{
            borderRadius: '0.5rem',
            textTransform: 'none',
            px: 3,
          }}
        >
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SberBankStubModal;
