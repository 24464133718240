import { Box, Tab, Tabs } from '@mui/material';

interface AppTransactionsTabsProps {
  mode: 'income' | 'outcome';
  onModeChange: (mode: 'income' | 'outcome') => void;
  income?: number;
  outcome?: number;
}

const AppTransactionsTabs: React.FC<AppTransactionsTabsProps> = ({
  mode,
  onModeChange,
  income,
  outcome,
}) => {
  const activeTab = mode === 'income' ? 0 : 1;

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    onModeChange(newValue === 0 ? 'income' : 'outcome');
  };

  return (
    <Box className="bg-[#EDEEF5] p-1 rounded-lg">
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="fullWidth"
        aria-label="transaction tabs"
        sx={{
          '& .MuiTab-root': {
            textTransform: 'none',
            fontWeight: 600,
            minHeight: '48px',
            '&.Mui-selected': {
              backgroundColor: 'white',
              color: 'inherit',
              borderRadius: '0.5rem',
              border: '1px solid #C5C4D7',
            },
          },
        }}
        TabIndicatorProps={{ sx: { display: 'none' } }}
      >
        <Tab
          label={
            <Box display="flex" flexDirection="column" alignItems="center">
              <span>Реестр поступлений</span>
              <span className="text-appGreen">
                {!!income ? `+${Number(income).toLocaleString()} руб` : 'Не указано'}
              </span>
            </Box>
          }
          id="income-tab"
          aria-controls="income-panel"
        />
        <Tab
          label={
            <Box display="flex" flexDirection="column" alignItems="center">
              <span>Реестр расходов</span>
              <span className="text-appRed">
                {!!outcome ? `${Number(outcome).toLocaleString()} руб` : 'Не указано'}
              </span>
            </Box>
          }
          id="outcome-tab"
          aria-controls="outcome-panel"
        />
      </Tabs>
    </Box>
  );
};

export default AppTransactionsTabs;
