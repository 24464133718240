import React, { useState, useContext, FormEvent, ChangeEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Paper, Typography } from '@mui/material';
import AppInput from '../AppInputV2';
import { UserContext } from '../../../store/user';
import { api, apiWrapper } from '../../../utils/api';
import AppMaskedInput from '../AppMaskedInput';
import { toast } from 'react-toastify';

interface LoginFormProps {
  showSignupBtn?: boolean;
}

interface FormState {
  email: string;
  phone: string;
  // emailCode: string;
  smsCode: string;
  errors: {
    email?: string;
    phone?: string;
    // emailCode?: string;
    smsCode?: string;
  };
}

const AppLogin: React.FC<LoginFormProps> = ({ showSignupBtn }) => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const [isSendingSms, setIsSendingSms] = useState<boolean>(false);
  const [formState, setFormState] = useState<FormState>({
    email: '',
    phone: '',
    // emailCode: '',
    smsCode: '',
    errors: {},
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
      errors: {
        ...prev.errors,
        [name]: '',
      },
    }));
  };

  const handleSendSmsCode = async (): Promise<void> => {
    try {
      setIsSendingSms(true);
      const cleanPhone = formState.phone.replace(/\D/g, '');
      const formattedPhone = cleanPhone.startsWith('7') ? `${cleanPhone}` : `7${cleanPhone}`;
      const response = await apiWrapper.post('/users/send_code', {
        phone: formattedPhone,
      });

      if (response.data.message) {
        toast.success(response.data.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          pauseOnHover: true,
        });
        setFormState((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            phone: '',
          },
        }));
      }
    } catch (error: any) {
      setFormState((prev) => ({
        ...prev,
        errors: {
          ...prev.errors,
          phone: error.response?.data?.error || 'Ошибка при отправке кода',
        },
      }));
    } finally {
      setIsSendingSms(false);
    }
  };

  // temp disabled
  // const handleSendEmailCode = async (): Promise<void> => {
  //   try {
  //     const response = await apiWrapper.post('/users/send_email_code', {
  //       email: formState.email,
  //     });

  //     if (response.data.message) {
  //       toast.success(response.data.message, {
  //         position: toast.POSITION.BOTTOM_CENTER,
  //         autoClose: 5000,
  //         type: toast.TYPE.SUCCESS,
  //         pauseOnHover: true,
  //       });
  //       setFormState((prev) => ({
  //         ...prev,
  //         errors: {
  //           ...prev.errors,
  //           email: '',
  //         },
  //       }));
  //     }
  //   } catch (error: any) {
  //     setFormState((prev) => ({
  //       ...prev,
  //       errors: {
  //         ...prev.errors,
  //         email: error.response?.data?.error || 'Ошибка при отправке кода',
  //       },
  //     }));
  //   }
  // };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    const { email, phone, smsCode } = formState;
    const errors: { [key: string]: string } = {};

    if (!email) errors.email = 'Email обязателен';

    // Check that at least one verification code is provided
    if (!smsCode) {
      // errors.emailCode = 'Необходимо ввести код подтверждения из email или SMS';
      errors.smsCode = 'Необходимо ввести код подтверждения из SMS';
    }

    if (Object.keys(errors).length > 0) {
      setFormState((prev) => ({ ...prev, errors }));
      return;
    }

    try {
      const response = await apiWrapper.post('/users/sign_in', {
        user: { email: email, phone: phone },
        // email_code: emailCode,
        sms_code: smsCode,
      });

      const { data } = response;

      if (data.token) {
        userContext.setUser({
          id: data.user.id,
          is_authed: true,
          is_moderator: data.user.is_moderator,
          full_name: data.user.full_name,
          phone: data.user.phone,
          email: data.user.email,
          organization: data.organization,
          loading: false,
          role: data.user.role,
        });

        localStorage.setItem('auth_token', data.token);
        api.defaults.headers['Authorization'] = `Bearer ${data.token}`;
        navigate(showSignupBtn ? '/' : '/admin');
      }
    } catch (error: any) {
      setFormState((prev) => ({
        ...prev,
        errors: {
          email: error.response?.data?.error || 'Ошибка авторизации',
          // emailCode: error.response?.data?.error || 'Ошибка авторизации',
          smsCode: error.response?.data?.error || 'Ошибка авторизации',
        },
      }));
    }
  };

  return (
    <Paper
      elevation={3}
      className="flex flex-col items-center justify-center p-5 mt-7 bg-container rounded-2xl xs:w-full sm:w-96 md:w-1/2 lg:w-1/3 xl:w-1/3"
    >
      <Typography
        variant="h6"
        className="w-full !mb-5 !text-base !font-semibold text-center uppercase text-blue-gray-400"
      >
        Заполните Данные
      </Typography>

      <form className="space-y-4" onSubmit={handleSubmit} data-testid="login-form">
        <AppInput
          id="email"
          name="email"
          label="Email"
          type="email"
          value={formState.email}
          onChange={handleInputChange}
          error={!!formState.errors.email}
          helperText={formState.errors.email}
          fullWidth
          data-testid="email-input"
        />

        {/* <Button
          variant="contained"
          color="primary"
          onClick={handleSendEmailCode}
          fullWidth
          disabled={!formState.email}
          data-testid="send-email-button"
        >
          Отправить код на Email
        </Button> */}

        {/* <AppMaskedInput
          name="emailCode"
          label="Код из Email"
          type="text"
          value={formState.emailCode}
          onChange={handleInputChange}
          mask="00000000"
          error={!!formState.errors.emailCode}
          helperText={formState.errors.emailCode}
          fullWidth
          data-testid="email-code-input"
        /> */}

        {/* <Typography variant="subtitle2" className="text-center text-gray-600">
          - или -
        </Typography> */}

        <AppMaskedInput
          name="phone"
          label="Телефон"
          type="tel"
          value={formState.phone}
          onChange={handleInputChange}
          mask="+7 (000) 000-00-00"
          error={!!formState.errors.phone}
          helperText={formState.errors.phone}
          fullWidth
          data-testid="phone-input"
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSendSmsCode}
          fullWidth
          disabled={!formState.phone || isSendingSms}
          data-testid="send-sms-button"
        >
          {isSendingSms ? (
            <>
              <CircularProgress size={20} color="inherit" className="mr-2" />
              Отправка...
            </>
          ) : (
            'Отправить код на телефон'
          )}
        </Button>

        <AppMaskedInput
          name="smsCode"
          label="Код из СМС"
          type="text"
          value={formState.smsCode}
          onChange={handleInputChange}
          mask="000000"
          error={!!formState.errors.smsCode}
          helperText={formState.errors.smsCode}
          fullWidth
          data-testid="sms-code-input"
        />

        <Button
          type="submit"
          variant="contained"
          fullWidth
          className="mt-5 bg-primaryGradient"
          size="large"
          data-testid="submit-button"
        >
          Войти
          <i className="ml-5 fas fa-chevron-right" />
        </Button>
      </form>

      {showSignupBtn && (
        <Link to="/registration" className="w-full mt-5" data-testid="registration-link">
          <Button variant="outlined" fullWidth size="large" data-testid="registration-button">
            Регистрация
            <i className="ml-5 fas fa-chevron-right" />
          </Button>
        </Link>
      )}
    </Paper>
  );
};

export default AppLogin;
