import { Component, Fragment } from 'react';
import { inputStatusTranslation } from '../../../types/InputStatus';

interface Props {
  refValue?: string | any;
  verifValue?: string | any;
}

interface State {
  key: number;
}

class AppInputStatus extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      key: 0,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.refValue !== prevProps.refValue ||
      this.props.verifValue !== prevProps.verifValue
    ) {
      this.setState((prevState) => ({ key: prevState.key + 1 }));
    }
  }

  isValid = (): boolean => {
    const refValueCleaned = (this.props.refValue || '')
      .toString()
      .toLowerCase()
      .replace(/[^а-яa-z0-9]/g, '');
    const verifValueCleaned = this.props.verifValue
      .toString()
      .toLowerCase()
      .replace(/[^а-яa-z0-9]/g, '');

    return refValueCleaned === verifValueCleaned;
  };

  render() {
    const refValue =
      this.props.refValue !== undefined
        ? this.props.refValue
            .toString()
            .toLowerCase()
            .replace(/[^а-яa-z0-9]/g, '')
        : '';
    const verifValue =
      this.props.verifValue !== undefined
        ? this.props.verifValue
            .toString()
            .toLowerCase()
            .replace(/[^а-яa-z0-9]/g, '')
        : '';

    return (
      <Fragment key={this.state.key}>
        {(refValue.length === 0 || verifValue.length === 0) && (
          <div
            className={`border-2 py-1 px-3 rounded-lg grid grid-cols-12 h-full items-center border-gray-300 bg-[#c9c8d60c]`}
          >
            <div className={`col-span-1`}>
              <div
                className={`w-6 h-6 2xl:w-8 2xl:h-8 rounded-full flex items-center justify-center`}
              >
                <i className="text-2xl text-gray-400 fas fa-spinner fa-spin"></i>
              </div>
            </div>
            <div className="col-span-11 lg:pl-2">
              <div className={`text-neutral-900 leading-none font-semibold`}>
                {inputStatusTranslation[0]}
              </div>
            </div>
          </div>
        )}

        {(refValue.length !== 0 || verifValue.length !== 0) && this.isValid() && (
          <div
            className={`border-2 py-1 px-3 rounded-lg grid grid-cols-12 h-full items-center border-green-500 bg-[#2eba550c]`}
          >
            <div className={`col-span-1`}>
              <div
                className={`w-6 h-6 2xl:w-8 2xl:h-8 rounded-full flex items-center justify-center bg-greenGradient`}
              >
                <i className="text-lg text-white fas fa-check"></i>
              </div>
            </div>
            <div className="col-span-11 lg:pl-2">
              <div className={`text-neutral-900 leading-none font-semibold`}>
                {inputStatusTranslation[1]}
              </div>
            </div>
          </div>
        )}
        {refValue.length !== 0 && verifValue.length !== 0 && !this.isValid() && (
          <div
            className={`border-2 py-1 px-3 rounded-lg grid grid-cols-12 h-full items-center border-red-800 bg-[#a72c2c0c]`}
          >
            <div className={`col-span-1`}>
              <div
                className={`w-6 h-6 2xl:w-8 2xl:h-8 rounded-full flex items-center justify-center bg-redGradient`}
              >
                <i className="text-lg text-white fas fa-times"></i>
              </div>
            </div>
            <div className="col-span-11 lg:pl-2">
              <div className={`text-neutral-900 leading-none font-semibold`}>
                {inputStatusTranslation[-1]}
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default AppInputStatus;
