import React, { useContext, useEffect } from 'react';
import Dashboard from '../../../containers/admin/Dashboard';
import { PageContext } from '../../../contexts/PageContext';

const TransactionsMapPage: React.FC = () => {
  const { setPageName } = useContext(PageContext);

  useEffect(() => {
    setPageName('Карта транзакций');
  }, [setPageName]);

  return (
    <Dashboard
      content={
        <div className={`account-view pt-10 pb-4 px-4 bg-slate-200 min-h-screen`}>
          <p className="mb-4 text-2xl font-semibold text-center text-red-800">
            Видео демонстрация дизайн-прототипа: требует согласования до разработки
          </p>
          <video src={process.env.PUBLIC_URL + `/tr_map.mp4`} autoPlay={true} controls={true} />
        </div>
      }
    />
  );
};

export default TransactionsMapPage;
