import React, { useEffect, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import dayjs from 'dayjs';
import { apiWrapper } from '../../../utils/api';
import AppInput from '../../../components/shared/AppInput';

interface BankAccountBalance {
  id?: number;
  balance: number;
  balance_date: string;
}

interface AppBankAccountBalancesModalProps {
  isOpen: boolean;
  onClose: () => void;
  bankAccountId: number;
}

const AppBankAccountBalancesModal: React.FC<AppBankAccountBalancesModalProps> = ({
  isOpen,
  onClose,
  bankAccountId,
}) => {
  const [balances, setBalances] = useState<BankAccountBalance[]>([]);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentBalanceId, setCurrentBalanceId] = useState<number | null>(null);
  const [formData, setFormData] = useState<BankAccountBalance>({
    balance: 0,
    balance_date: '',
  });
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    if (isOpen) {
      fetchBalances();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const fetchBalances = async () => {
    setLoading(true);
    try {
      const response: any = await apiWrapper.get(`/bank_accounts/${bankAccountId}/balances`);
      setBalances(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenCreateForm = () => {
    setEditMode(false);
    setCurrentBalanceId(null);
    setFormData({ balance: 0, balance_date: '' });
    setErrors({});
  };

  const handleOpenEditForm = (balance: BankAccountBalance) => {
    setEditMode(true);
    setCurrentBalanceId(balance.id || null);
    setFormData({
      balance: balance.balance,
      balance_date: dayjs(balance.balance_date).format('YYYY-MM-DD'),
    });
    setErrors({});
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    setErrors({});
    try {
      let response: any;
      if (editMode && currentBalanceId) {
        response = await apiWrapper.patch(
          `/bank_accounts/${bankAccountId}/balances/${currentBalanceId}`,
          { bank_account_balance: formData },
        );
        setBalances((prevBalances) =>
          prevBalances.map((b) => (b.id === currentBalanceId ? response.data : b)),
        );
      } else {
        response = await apiWrapper.post(`/bank_accounts/${bankAccountId}/balances`, {
          bank_account_balance: formData,
        });
        setBalances((prevBalances) => [response.data, ...prevBalances]);
      }
      setFormData({ balance: 0, balance_date: '' });
      setEditMode(false);
      setCurrentBalanceId(null);
    } catch (err: any) {
      console.error(err);
      // if (err?.errors) {
      //   const errorObj: Record<string, string> = Array.isArray(err.errors)
      //     ? err.errors.reduce((acc, message) => {
      //         const [field, ...rest] = message.split(" ");
      //         acc[field.toLowerCase()] = rest.join(" ");
      //         return acc;
      //       }, {} as Record<string, string>)
      //     : err.errors;
      //   setErrors(errorObj);
      // }
    }
    setLoading(false);
  };

  const handleDelete = async (id: number) => {
    if (!window.confirm('Вы уверены, что хотите удалить запись с остатками?')) return;

    setLoading(true);
    try {
      await apiWrapper.delete(`/bank_accounts/${bankAccountId}/balances/${id}`);
      setBalances((prev) => prev.filter((b) => b.id !== id));
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-75"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-75"
            >
              <Dialog.Panel className="w-full max-w-4xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title className="flex justify-between mb-4 text-lg font-semibold leading-6 text-gray-900">
                  <span>Входящий остаток</span>
                  <button
                    type="button"
                    onClick={onClose}
                    className="text-gray-400 hover:text-gray-600 focus:outline-none"
                  >
                    <i className="text-xl far fa-times"></i>
                  </button>
                </Dialog.Title>

                {(editMode || currentBalanceId === null) && (
                  <div className="p-3 mb-4 border rounded bg-gray-50">
                    <div className="grid grid-cols-2 gap-4">
                      <AppInput
                        id="balance"
                        name="balance"
                        label="Остаток"
                        type="number"
                        value={String(formData.balance)}
                        onChange={handleInputChange}
                      />
                      {errors.balance && <p className="text-sm text-red-500">{errors.balance}</p>}
                      <AppInput
                        id="balance_date"
                        name="balance_date"
                        label="Дата"
                        type="date"
                        value={formData.balance_date}
                        onChange={handleInputChange}
                      />
                      {errors.balance_date && (
                        <p className="text-sm text-red-500">{errors.balance_date}</p>
                      )}
                    </div>
                    <div className="flex gap-2 mt-3">
                      <button
                        onClick={handleSubmit}
                        className="px-4 py-2 text-white rounded-lg bg-appGreen hover:bg-green-700"
                      >
                        {editMode ? 'Обновить' : 'Сохранить'}
                      </button>
                      {editMode && (
                        <button
                          onClick={handleOpenCreateForm}
                          className="px-4 py-2 text-gray-800 bg-gray-300 rounded-lg hover:bg-gray-400"
                        >
                          Отмена
                        </button>
                      )}
                    </div>
                  </div>
                )}

                {loading && (
                  <div className="text-center text-primary">
                    <i className="far fa-sync fa-spin fa-2x"></i>
                  </div>
                )}
                {!loading && balances?.length === 0 && (
                  <div className="py-4 font-semibold text-center">Остатки не найдены.</div>
                )}
                {!loading && (
                  <table className="min-w-full text-black border divide-y divide-gray-200">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="px-4 py-2 font-semibold text-left">ID</th>
                        <th className="px-4 py-2 font-semibold text-left">Остаток</th>
                        <th className="px-4 py-2 font-semibold text-left">На дату</th>
                        <th className="px-4 py-2 font-semibold text-center">Действия</th>
                      </tr>
                    </thead>
                    <tbody>
                      {balances?.map((bal) => (
                        <tr key={bal.id}>
                          <td className="px-4 py-2">{bal.id}</td>
                          <td className="px-4 py-2">{bal.balance?.toLocaleString('ru-RU')}</td>
                          <td className="px-4 py-2">
                            {dayjs(bal.balance_date).format('DD.MM.YYYY')}
                          </td>
                          <td className="px-4 py-2 text-center">
                            <button
                              className="text-primary"
                              onClick={() => handleOpenEditForm(bal)}
                            >
                              Редактировать
                            </button>
                            <button
                              className="ml-2 text-red-600"
                              onClick={() => handleDelete(bal.id!)}
                            >
                              Удалить
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AppBankAccountBalancesModal;
