import { FC } from 'react';
import { Button } from '@mui/material';
import { red } from '@mui/material/colors';

const AppSignOutBtn: FC = () => {
  const handleSignOut = (): void => {
    localStorage.removeItem('auth_token');
    window.location.assign('/login');
  };

  return (
    <Button
      data-testid="signout-btn"
      variant="contained"
      onClick={handleSignOut}
      fullWidth
      sx={{
        backgroundColor: red[500],
        '&:hover': {
          backgroundColor: red[700],
        },
        textTransform: 'uppercase',
        fontWeight: 600,
      }}
    >
      Выход
    </Button>
  );
};

export default AppSignOutBtn;
