import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { apiWrapper } from '../../../utils/api';
import { UserContext } from '../../../store/user';

const FundReportDownload = ({ activeFundId }: { activeFundId: string | number }) => {
  const { role } = useContext(UserContext);

  const [startDateMonthly, setStartDateMonthly] = useState<string>(
    new Date().toISOString().split('T')[0],
  );
  const [endDateMonthly, setEndDateMonthly] = useState<string>(
    new Date().toISOString().split('T')[0],
  );

  const [startDateAnnual, setStartDateAnnual] = useState<string>(
    new Date().toISOString().split('T')[0],
  );
  const [endDateAnnual, setEndDateAnnual] = useState<string>(
    new Date().toISOString().split('T')[0],
  );

  const handleDownloadReport = async (url: string, fileName: string) => {
    try {
      const response = await apiWrapper.getBlob(url);

      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error downloading the report:', error);
      toast.error('Возникла ошибка при генерации отчета. Попробуйте еще раз');
    }
  };

  const handleMonthlyReportDownload = () => {
    const url = `/funds/${activeFundId}/report_by_transaction_categories?start_date=${startDateMonthly}&end_date=${endDateMonthly}`;
    handleDownloadReport(url, `отчет_${startDateMonthly}_${endDateMonthly}.xlsx`).then(() => {});
  };

  const handleAnnualSummaryReportDownload = () => {
    const url = `/funds/${activeFundId}/annual_summary_report_fprsr?start_date=${startDateAnnual}&end_date=${endDateAnnual}`;
    handleDownloadReport(url, `сводный_отчет_${startDateAnnual}_${endDateAnnual}.xlsx`).then(
      () => {},
    );
  };

  return (
    <div className="border border-[#C5C4D7] rounded-lg p-5 mt-4">
      {/* Monthly Report Section */}
      <div className="mb-6">
        <div className="flex flex-row items-center justify-between mb-4">
          <div className="uppercase text-white bg-primary font-semibold py-2 px-5 rounded-xl text-sm">
            Отчет о деятельности фонда (Произвольный период)
          </div>
        </div>

        <div className="flex flex-row items-center justify-start w-3/4 gap-4">
          {/* Start Date Selector for Monthly */}
          <div className="flex flex-col items-start">
            <label className="block text-sm font-medium text-gray-700">Начало периода</label>
            <input
              type="date"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
              value={startDateMonthly}
              onChange={(e) => setStartDateMonthly(e.target.value)}
            />
          </div>

          {/* End Date Selector for Monthly */}
          <div className="flex flex-col items-start">
            <label className="block text-sm font-medium text-gray-700">Конец периода</label>
            <input
              type="date"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
              value={endDateMonthly}
              onChange={(e) => setEndDateMonthly(e.target.value)}
            />
          </div>

          {/* Download Button for Monthly */}
          <button
            onClick={handleMonthlyReportDownload}
            className="bg-primary text-white font-semibold py-2 px-6 rounded-lg hover:bg-primary-dark self-end"
          >
            Скачать
          </button>
        </div>
      </div>

      {/* Annual Summary Report Section */}
      {role === 'federal' && (
        <div>
          <div className="flex flex-row items-center justify-between mb-4">
            <div className="uppercase text-white bg-primary font-semibold py-2 px-5 rounded-xl text-sm">
              Годовой сводный отчет о деятельности фондов регионального сотрудничества и развития
            </div>
          </div>

          <div className="flex flex-row items-center justify-start w-3/4 gap-4">
            {/* Start Date Selector for Annual */}
            <div className="flex flex-col items-start">
              <label className="block text-sm font-medium text-gray-700">Начало периода</label>
              <input
                type="date"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                value={startDateAnnual}
                onChange={(e) => setStartDateAnnual(e.target.value)}
              />
            </div>

            {/* End Date Selector for Annual */}
            <div className="flex flex-col items-start">
              <label className="block text-sm font-medium text-gray-700">Конец периода</label>
              <input
                type="date"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                value={endDateAnnual}
                onChange={(e) => setEndDateAnnual(e.target.value)}
              />
            </div>

            {/* Download Button for Annual */}
            <button
              onClick={handleAnnualSummaryReportDownload}
              className="bg-primary text-white font-semibold py-2 px-6 rounded-lg hover:bg-primary-dark self-end"
            >
              Скачать
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FundReportDownload;
