import React, { useState } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';

interface AppCopyableFieldProps {
  value: string | number;
  showCopyButton?: boolean;
}

const AppCopyableField: React.FC<AppCopyableFieldProps> = ({ value, showCopyButton = true }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(String(value));
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  if (!value || !showCopyButton) {
    return <Typography sx={{ flex: 1, fontSize: '1rem' }}>Не указано</Typography>;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        flex: 1,
        '&:hover .copy-button': {
          opacity: 1,
        },
      }}
    >
      <Typography sx={{ fontSize: '1rem' }}>{value}</Typography>
      <Tooltip title={copied ? 'Скопировано!' : 'Копировать'}>
        <IconButton
          size="small"
          onClick={handleCopy}
          className="copy-button"
          sx={{
            ml: 1,
            opacity: 0,
            transition: 'opacity 0.2s',
          }}
        >
          {copied ? (
            <i className="fas fa-check text-appGreen"></i>
          ) : (
            <i className="fas fa-clipboard"></i>
          )}
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default AppCopyableField;
