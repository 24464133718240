import React, { useState } from 'react';
import { Card, CardHeader, CardContent, Box, Button } from '@mui/material';
import { BankAccount } from '../../../types/BankAccount';
import AppCardTitle from '../../shared/AppCardTitle';
import AppExpandButton from '../../shared/AppExpandButton';
import AppNewBankAccountDialog from '../AppNewBankAccountDialog';
import BankAccountsTable from './BankAccountsTable';

interface BankAccountsCardProps {
  accounts: BankAccount[];
  fundId: number;
  onAddAccount: () => void;
}

const BankAccountsCard: React.FC<BankAccountsCardProps> = ({ accounts, fundId, onAddAccount }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const hasAccounts = accounts.length > 0;

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleSubmit = () => {
    document.location.reload();
  };

  return (
    <>
      <Card
        elevation={0}
        sx={{
          mt: 3,
          border: 1,
          borderColor: 'border.main',
        }}
        className="!rounded-lg"
      >
        <CardHeader
          title={
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <AppCardTitle>Расчетные счета в банках</AppCardTitle>
              <AppExpandButton to={`/admin/bank_accounts?fund_id=${fundId}`} />
            </Box>
          }
          sx={{ pb: 0 }}
        />

        <CardContent>
          {!hasAccounts && (
            <Box mb={2}>
              <Button
                variant="contained"
                color="success"
                size="small"
                onClick={() => setIsDialogOpen(true)}
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 600,
                  fontSize: '0.75rem',
                  borderRadius: '0.5rem',
                }}
              >
                Добавить банковский счет
              </Button>
            </Box>
          )}
          {hasAccounts && (
            <Box>
              <BankAccountsTable accounts={accounts} />
            </Box>
          )}
        </CardContent>
      </Card>

      <AppNewBankAccountDialog
        isOpen={isDialogOpen}
        fundId={fundId}
        onClose={handleClose}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default BankAccountsCard;
