import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { Box, Paper, Typography } from '@mui/material';
import { apiWrapper } from '../../../../utils/api';
import { useParams, useSearchParams } from 'react-router-dom';

const TransactionMap = ({ year }) => {
  const svgRef = useRef();
  const width = 928;
  const height = 600;

  const { fund_id: paramFundId } = useParams();
  const [searchParams] = useSearchParams();

  const fundId = paramFundId || searchParams.get('fund_id') || 1;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiWrapper.get(`/sber_transactions/transaction_map?year=${year}&fund_id=${fundId}`);
        drawChart(response.data);
      } catch (error) {
        console.error('Error fetching transaction map data:', error);
      }
    };
  
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  const drawChart = (data) => {
    const { nodes, links } = data;
    const types = Array.from(new Set(nodes.map(d => d.type)));

    // Clear previous SVG content
    d3.select(svgRef.current).selectAll("*").remove();

    // Create SVG
    const svg = d3.select(svgRef.current)
      .attr("viewBox", [-width / 2, -height / 2, width, height])
      .attr("width", width)
      .attr("height", height)
      .attr("style", "max-width: 100%; height: auto; font: 12px sans-serif;");

    // Color scale
    const color = d3.scaleOrdinal(types, d3.schemeCategory10);

    // Create simulation
    const simulation = d3.forceSimulation(nodes)
      .force("link", d3.forceLink(links).id(d => d.id))
      .force("charge", d3.forceManyBody().strength(-400))
      .force("x", d3.forceX())
      .force("y", d3.forceY());

    // Add arrow markers
    svg.append("defs").selectAll("marker")
      .data(types)
      .join("marker")
        .attr("id", d => `arrow-${d}`)
        .attr("viewBox", "0 -5 10 10")
        .attr("refX", 15)
        .attr("refY", -0.5)
        .attr("markerWidth", 6)
        .attr("markerHeight", 6)
        .attr("orient", "auto")
      .append("path")
        .attr("fill", color)
        .attr("d", "M0,-5L10,0L0,5");

    // Add links
    const link = svg.append("g")
      .attr("fill", "none")
      .attr("stroke-width", 1.5)
      .selectAll("path")
      .data(links)
      .join("path")
        .attr("stroke", d => color(d.type))
        .attr("marker-end", d => `url(#arrow-${d.type})`);

    // Add nodes
    const node = svg.append("g")
      .attr("fill", "currentColor")
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round")
      .selectAll("g")
      .data(nodes)
      .join("g")
        .call(drag(simulation));

    // Add circles to nodes
    node.append("circle")
      .attr("stroke", "white")
      .attr("stroke-width", 1.5)
      .attr("r", d => Math.sqrt(d.amount) / 100 + 4);

    // Add labels to nodes
    node.append("text")
      .attr("x", 8)
      .attr("y", "0.31em")
      .text(d => d.name)
      .clone(true).lower()
      .attr("fill", "none")
      .attr("stroke", "white")
      .attr("stroke-width", 3);

    // Update positions on each tick
    simulation.on("tick", () => {
      link.attr("d", linkArc);
      node.attr("transform", d => `translate(${d.x},${d.y})`);
    });
  };

  // Drag behavior
  const drag = (simulation) => {
    function dragstarted(event) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }

    function dragged(event) {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    function dragended(event) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    return d3.drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  };

  // Link arc generator
  const linkArc = d3.linkHorizontal()
    .x(d => d.x)
    .y(d => d.y);

  return (
    <Paper elevation={3} sx={{ p: 2, m: 2 }}>
      <Typography variant="h6" gutterBottom>
        Transaction Map {year}
      </Typography>
      <Box sx={{ overflow: 'auto' }}>
        <svg ref={svgRef}></svg>
      </Box>
    </Paper>
  );
};

export default TransactionMap; 