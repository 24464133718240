import React from 'react';
import { Tabs, Tab, Box, useTheme, useMediaQuery } from '@mui/material';
import { FundType } from '../../../types/Fund';

interface AppFundTypeSelectorProps {
  fundTypes: FundType[];
  selectedFundTypeId: string | null;
  onSelect: (fundTypeId: string) => void;
}

const AppFundTypeSelector: React.FC<AppFundTypeSelectorProps> = ({
  fundTypes,
  selectedFundTypeId,
  onSelect,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    onSelect(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={selectedFundTypeId || false}
        onChange={handleChange}
        variant="fullWidth"
        orientation={isMobile ? 'vertical' : 'horizontal'}
        TabIndicatorProps={{ style: { display: 'none' } }}
        sx={{
          '& .MuiTabs-flexContainer': {
            flexDirection: isMobile ? 'column' : 'row',
            gap: isMobile ? 1 : 0,
          },
          '& .MuiTab-root': {
            color: 'black',
            bgcolor: 'white',
            border: `2px solid ${theme.palette.border.main}`,
            textTransform: 'uppercase',
            fontWeight: 600,
            fontSize: { xs: '12px', sm: '14px' },
            borderRadius: 0,
            minHeight: { xs: '48px', sm: '56px' },
            padding: { xs: '8px 12px', sm: '12px 16px' },
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
            transition: 'background-color 0.2s ease-in-out',
            '&:hover': {
              bgcolor: theme.palette.appOrange.light,
              color: 'black',
            },
            '&.Mui-selected': {
              bgcolor: theme.palette.appOrange.main,
              color: 'white',
            },
            ...(!isMobile && {
              '&:not(:last-of-type)': {
                borderRight: 'none',
              },
              '&:first-of-type': {
                borderTopLeftRadius: '0.5rem',
                borderBottomLeftRadius: '0.5rem',
              },
              '&:last-of-type': {
                borderTopRightRadius: '0.5rem',
                borderBottomRightRadius: '0.5rem',
              },
            }),
            ...(isMobile && {
              width: '100%',
              borderRadius: '0.5rem',
              '&:not(:last-of-type)': {
                marginBottom: '-2px',
              },
            }),
          },
          '& .MuiTabs-scrollButtons': {
            display: { xs: 'flex', sm: 'none' },
          },
        }}
      >
        {fundTypes?.map((type) => (
          <Tab
            key={type.id}
            label={type.name}
            value={type.id}
            className="!cursor-pointer"
            disableRipple
            wrapped
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default AppFundTypeSelector;
