import React, { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import dayjs, { Dayjs } from 'dayjs';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { SberTransaction } from '../../../../types/SberTransaction';
import { DatePicker } from '@mui/x-date-pickers';
import months from '../../../../dictionaries/monthNames.json';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface TransactionsLogChartProps {
  incomeTransactions: SberTransaction[];
  outcomeTransactions: SberTransaction[];
  onPeriodClick?: (date: Date) => void;
}

const TransactionsLogChart: React.FC<TransactionsLogChartProps> = ({
  incomeTransactions,
  outcomeTransactions,
  onPeriodClick,
}) => {
  const [dateStart, setDateStart] = useState(dayjs('2024-01-01').toDate());
  const [dateEnd, setDateEnd] = useState(dayjs().endOf('year').toDate());
  const [timeMode, setTimeMode] = useState<'day' | 'month'>('month');

  const handleTimeModeChange = (_: React.MouseEvent<HTMLElement>, newMode: 'day' | 'month') => {
    if (newMode !== null) {
      setTimeMode(newMode);
    }
  };

  // A helper function that checks if a transaction’s date is in the current iteration period.
  const isTransactionInPeriod = (
    tx: SberTransaction,
    iterationDate: Dayjs,
    mode: 'day' | 'month',
  ) => {
    const txDate = dayjs(tx.date_of_execution);
    return mode === 'month'
      ? txDate.month() === iterationDate.month() && txDate.year() === iterationDate.year()
      : txDate.isSame(iterationDate, 'day');
  };

  const processData = () => {
    const labels: string[] = [];
    const incomeData: number[] = [];
    const outcomeData: number[] = [];

    let currentDate = dayjs(dateStart);
    const endDate = dayjs(dateEnd);

    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
      // Make a clone so we have an immutable reference for the current iteration.
      const iterationDate = currentDate.clone();

      const label =
        timeMode === 'month' ? months[iterationDate.month()] : iterationDate.format('DD.MM.YYYY');

      labels.push(label);

      const periodIncomeTransactions = incomeTransactions.filter((tx) =>
        isTransactionInPeriod(tx, iterationDate, timeMode),
      );

      const periodOutcomeTransactions = outcomeTransactions.filter((tx) =>
        isTransactionInPeriod(tx, iterationDate, timeMode),
      );

      const income = periodIncomeTransactions.reduce((sum, tx) => sum + Number(tx.amount), 0);
      const outcome = periodOutcomeTransactions.reduce(
        (sum, tx) => sum + Math.abs(Number(tx.amount)),
        0,
      );

      incomeData.push(income);
      outcomeData.push(outcome);

      // Move to the next day/month based on timeMode
      currentDate = currentDate.add(1, timeMode);
    }

    return { labels, incomeData, outcomeData };
  };

  const { labels, incomeData, outcomeData } = processData();

  const options: ChartOptions<'bar'> = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        type: 'linear',
        stacked: true,
        beginAtZero: true,
        grid: {
          color: '#E5E7EB',
        },
        ticks: {
          callback: (value) => {
            if (typeof value !== 'number') return '';
            return new Intl.NumberFormat('ru-RU', {
              style: 'currency',
              currency: 'RUB',
              maximumFractionDigits: 0,
            }).format(value);
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Динамика движения средств',
      },
    },
    onClick: (_event, elements) => {
      if (elements.length > 0 && onPeriodClick) {
        const index = elements[0].index;
        const date = dayjs(dateStart).add(index, timeMode).toDate();
        onPeriodClick(date);
      }
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Поступления',
        data: incomeData,
        backgroundColor: '#17A63F',
        stack: 'Stack 0',
      },
      {
        label: 'Расходы',
        data: outcomeData,
        backgroundColor: '#B02A2A',
        stack: 'Stack 1',
      },
    ],
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
      <Box sx={{ p: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Box display="flex" gap={2}>
            <DatePicker
              label="Начало периода"
              value={dayjs(dateStart)}
              onChange={(newValue) => newValue && setDateStart(newValue.toDate())}
            />
            <DatePicker
              label="Конец периода"
              value={dayjs(dateEnd)}
              onChange={(newValue) => newValue && setDateEnd(newValue.toDate())}
            />
          </Box>
          <ToggleButtonGroup
            value={timeMode}
            exclusive
            onChange={handleTimeModeChange}
            size="small"
          >
            <ToggleButton value="month">По месяцам</ToggleButton>
            <ToggleButton value="day">По дням</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Bar options={options} data={data} />
      </Box>
    </LocalizationProvider>
  );
};

export default TransactionsLogChart;
