import React, { useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from '@mui/material';
import { BankAccount } from '../../../../types/BankAccount';
import dayjs from 'dayjs';
import theme from '../../../../theme/theme';

type SortOrder = 'asc' | 'desc';

// Определяем только те поля, которые мы будем отображать
type DisplayableColumns = Pick<
  BankAccount,
  'bank_name' | 'payment_account' | 'corr_account' | 'inn' | 'opening_date' | 'balance'
>;

interface Column {
  id: keyof DisplayableColumns;
  label: string;
  align?: 'right' | 'left' | 'center';
  format?: (value: any) => string;
}

const columns: Column[] = [
  { id: 'bank_name', label: 'Банк', align: 'left' },
  { id: 'payment_account', label: 'Расчетный счет', align: 'right' },
  { id: 'corr_account', label: 'Корр. счет', align: 'right' },
  { id: 'inn', label: 'ИНН', align: 'right' },
  {
    id: 'opening_date',
    label: 'Дата открытия',
    align: 'right',
    format: (value: string) => (!!value ? dayjs(value).format('DD.MM.YYYY') : 'Не указана'),
  },
  {
    id: 'balance',
    label: 'Баланс',
    align: 'right',
    format: (value: string | number) => (value ? Number(value).toLocaleString('ru-RU') : '0'),
  },
];

interface BankAccountsTableProps {
  accounts: BankAccount[];
}

const BankAccountsTable: React.FC<BankAccountsTableProps> = ({ accounts }) => {
  const [orderBy, setOrderBy] = useState<keyof DisplayableColumns>('bank_name');
  const [order, setOrder] = useState<SortOrder>('asc');

  const handleSort = (property: keyof DisplayableColumns) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedAccounts = useMemo(() => {
    const comparator = (a: BankAccount, b: BankAccount) => {
      const aValue = a[orderBy]?.toString() || '';
      const bValue = b[orderBy]?.toString() || '';

      if (order === 'desc') {
        return bValue.localeCompare(aValue);
      }
      return aValue.localeCompare(bValue);
    };

    return [...accounts].sort(comparator);
  }, [accounts, order, orderBy]);

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className="border rounded-lg"
      sx={{ borderColor: theme.palette.border.main }}
    >
      <Table size="small" aria-label="банковские счета">
        <TableHead className="bg-[#EDEEF5]">
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                sortDirection={orderBy === column.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : 'asc'}
                  onClick={() => handleSort(column.id)}
                  className="!font-semibold"
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedAccounts.map((account, index) => (
            <TableRow
              key={`${account.payment_account}-${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} className="!font-medium">
                  {column.format
                    ? column.format(account[column.id])
                    : String(account[column.id] || '')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BankAccountsTable;
