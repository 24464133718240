import React from 'react';

interface FilterBarTransactionsProps {
  startDate: string;
  endDate: string;
  bank: string;
  innFilter: string;
  banks: string[];
  onFilterChange: (name: string, value: string) => void;
  onClearFilters: () => void;
  searchHandler: () => void;
}

const FilterBarTransactions: React.FC<FilterBarTransactionsProps> = ({
  startDate,
  endDate,
  bank,
  innFilter,
  banks,
  onFilterChange,
  onClearFilters,
  searchHandler,
}) => {
  return (
    <div className="flex flex-wrap items-center gap-2 px-3 py-2 rounded-lg bg-primary lg:px-5">
      <div className="text-sm font-semibold text-white uppercase">Фильтрация:</div>

      {/* Bank Selector */}
      <div className="w-full sm:w-auto">
        <select
          id="bank"
          name="bank"
          className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
          value={bank}
          onChange={(e) => onFilterChange(e.target.name, e.target.value)}
        >
          <option value="all">Все банки</option>
          {banks.map((b, index) => (
            <option value={b} key={index}>
              {b}
            </option>
          ))}
        </select>
      </div>

      {/* INN Filter */}
      <div className="w-full sm:w-auto">
        <input
          type="text"
          name="innFilter"
          placeholder="Поиск по ИНН"
          value={innFilter}
          onChange={(e) => onFilterChange(e.target.name, e.target.value)}
          className="px-3 py-2 text-sm text-gray-800 rounded-lg focus:outline-none"
        />
      </div>

      {/* Date Range Picker */}
      <div className="flex items-center text-sm">
        <span className="mr-2 font-semibold text-white">В период с</span>
        <input
          type="date"
          name="startDate"
          value={startDate}
          onChange={(e) => onFilterChange(e.target.name, e.target.value)}
          className="px-2 py-1 text-gray-800 rounded-lg"
        />
      </div>
      <div className="flex items-center text-sm">
        <span className="mr-2 font-semibold text-white">по</span>
        <input
          type="date"
          name="endDate"
          value={endDate}
          onChange={(e) => onFilterChange(e.target.name, e.target.value)}
          className="px-2 py-1 text-gray-800 rounded-lg"
        />
      </div>

      {/* Search and Clear Buttons */}
      <div className="flex w-full space-x-2 sm:w-auto">
        <button
          className="w-full px-6 py-2 font-semibold bg-white rounded-lg text-primary"
          onClick={searchHandler} // Use searchHandler here
        >
          Поиск
        </button>
        <button
          className="w-full px-6 py-2 font-semibold bg-gray-200 rounded-lg text-primary"
          onClick={onClearFilters} // Use onClearFilters here
        >
          Сбросить
        </button>
      </div>
    </div>
  );
};

export default FilterBarTransactions;
