import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TableSortLabel,
  Typography,
  Box,
  InputAdornment,
  TextField,
} from '@mui/material';
import { SberTransaction } from '../../../../types/SberTransaction';
import AppNumberWithSpaces from '../../../shared/AppNumberWithSpaces';
import formatDate from '../../../../utils/formatDate';
import theme from '../../../../theme/theme';
import { PaginationMeta } from '../../../../types/Pagination';

type SortOrder = 'asc' | 'desc';

/**
 * Пропсы для компонента таблицы транзакций
 * @interface AppTransactionsTableMiniProps
 * @property {('income'|'outcome')} mode - Режим отображения (доходы/расходы)
 * @property {SberTransaction[]} transactions - Массив транзакций для отображения
 * @property {Function} onRowClick - Callback при клике на строку таблицы
 * @property {PaginationMeta} pagination - Метаданные пагинации с сервера
 * @property {Function} onPageChange - Callback при изменении страницы
 * @property {Function} onSortChange - Callback при изменении сортировки
 * @property {boolean} loading - Флаг загрузки данных
 * @property {Function} onSearch - Callback при изменении поискового запроса
 */
interface AppTransactionsTableMiniProps {
  mode: 'income' | 'outcome';
  transactions: SberTransaction[];
  onRowClick?: (transaction: SberTransaction) => void;
  pagination?: PaginationMeta;
  onPageChange: (page: number, perPage: number) => void;
  onSortChange: (sortBy: string, sortDirection: SortOrder) => void;
  loading?: boolean;
  onSearch?: (query: string) => void;
}

/**
 * Интерфейс для описания колонок таблицы
 * @interface Column
 * @property {string} id - Идентификатор колонки (соответствует полю в объекте транзакции)
 * @property {string} label - Отображаемое название колонки
 * @property {string} align - Выравнивание содержимого колонки
 * @property {number} minWidth - Минимальная ширина колонки
 * @property {Function} format - Функция форматирования значения
 * @property {string} sortKey - Ключ для серверной сортировки (может отличаться от id)
 */
interface Column {
  id: keyof SberTransaction | string;
  label: string;
  align?: 'right' | 'left' | 'center';
  minWidth?: number;
  format?: (value: any) => string;
  sortKey?: string; // API sort key can be different from field id
}

// Колонки таблицы с соответствующими ключами сортировки для API
const TABLE_COLUMNS: Column[] = [
  { id: 'id', label: 'ID', minWidth: 50 },
  {
    id: 'amount',
    label: 'Сумма',
    align: 'right',
    minWidth: 120,
    format: (value: number) => `${Math.abs(value).toLocaleString('ru-RU')} ₽`,
    sortKey: 'amount',
  },
  {
    id: 'date_of_execution',
    label: 'Дата исполнения',
    format: formatDate,
    sortKey: 'date',
  },
  {
    id: 'payee_name',
    label: 'Получатель',
    minWidth: 200,
    sortKey: 'payee_name',
  },
  {
    id: 'payer_name',
    label: 'Плательщик',
    minWidth: 200,
    sortKey: 'payer_name',
  },
  { id: 'payee_inn', label: 'ИНН получателя', minWidth: 50 },
  { id: 'payer_inn', label: 'ИНН плательщика', minWidth: 50 },
  { id: 'payee_account', label: 'Счет получателя', minWidth: 50 },
  { id: 'payer_account', label: 'Счет плательщика', minWidth: 50 },
  {
    id: 'payment_purpose',
    label: 'Назначение',
    minWidth: 300,
    sortKey: 'description',
  },
];

// Стили для ячеек таблицы
const CELL_STYLES = { fontSize: 12 };

/**
 * Компонент для отображения таблицы транзакций с серверной пагинацией
 *
 * Обеспечивает отображение, сортировку, поиск и пагинацию для списка банковских транзакций.
 * Использует серверную пагинацию для оптимизации производительности при работе с большими наборами данных.
 */
const AppTransactionsTableMini: React.FC<AppTransactionsTableMiniProps> = ({
  mode,
  transactions,
  onRowClick,
  pagination,
  onPageChange,
  onSortChange,
  loading = false,
  onSearch,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [orderBy, setOrderBy] = useState<string>('date');
  const [order, setOrder] = useState<SortOrder>('desc');

  // Обработка изменения страницы пагинации
  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onPageChange(newPage + 1, pagination?.per_page || 10);
  };

  // Обработка изменения количества строк на странице
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPerPage = parseInt(event.target.value, 10);
    onPageChange(1, newPerPage);
  };

  // Обработка изменения поискового запроса с использованием debounce
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Используем debounce для поиска, чтобы не отправлять запрос на сервер при каждом нажатии клавиши
    const timeoutId = setTimeout(() => {
      if (onSearch) {
        onSearch(query);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  };

  // Обработка сортировки
  const handleRequestSort = (columnId: keyof SberTransaction | string) => {
    const column = TABLE_COLUMNS.find((col) => col.id === columnId);
    if (!column || !column.sortKey) return;

    const sortKey = column.sortKey;
    const isAsc = orderBy === sortKey && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';

    setOrder(newOrder);
    setOrderBy(sortKey);
    onSortChange(sortKey, newOrder);
  };

  // Отрисовка ячейки таблицы с учетом форматирования значений
  const renderCell = (transaction: SberTransaction, column: Column) => {
    const value = transaction[column.id as keyof SberTransaction];

    if (column.id === 'id') {
      return `${transaction.sber_upload_file_id}.${transaction.id}`;
    }

    if (column.id === 'amount') {
      return (
        <Typography
          key={column.id}
          align="right"
          sx={{
            ...CELL_STYLES,
            color: mode === 'income' ? theme.palette.appGreen.dark : '#B02A2A',
            fontWeight: 600,
          }}
        >
          <AppNumberWithSpaces value={Math.abs(transaction.amount)} />
          &nbsp;₽
        </Typography>
      );
    }

    return column.format ? column.format(value) : value || 'Не указано';
  };

  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      {/* Блок поиска */}
      <Box className="pb-2">
        <Typography
          variant="subtitle2"
          component="label"
          htmlFor="transactions-search"
          className="block mb-1 text-gray-700"
        >
          Поиск по транзакциям
        </Typography>
        <TextField
          id="transactions-search"
          size="small"
          fullWidth
          placeholder="Поиск по плательщику, получателю, назначению платежа, ИНН, сумме..."
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <i className="far fa-search"></i>
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
            },
          }}
        />
        {searchQuery && transactions.length === 0 ? (
          <Typography variant="body2" className="mt-2 text-gray-500">
            По вашему запросу ничего не найдено
          </Typography>
        ) : searchQuery ? (
          <Typography variant="body2" className="mt-2 text-gray-500">
            {loading ? 'Загрузка...' : `Найдено результатов: ${pagination?.total_count || 0}`}
          </Typography>
        ) : null}
      </Box>

      {/* Таблица транзакций */}
      <TableContainer className="border rounded-lg" sx={{ borderColor: theme.palette.border.main }}>
        <Table size="small">
          <TableHead className="bg-[#EDEEF5]">
            <TableRow>
              {TABLE_COLUMNS.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, lineHeight: '1.1rem' }}
                >
                  {column.sortKey ? (
                    <TableSortLabel
                      active={orderBy === column.sortKey}
                      direction={orderBy === column.sortKey ? order : 'asc'}
                      onClick={() => handleRequestSort(column.id)}
                      className="!font-semibold"
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    <span className="font-semibold">{column.label}</span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              // Отображение состояния загрузки
              <TableRow>
                <TableCell colSpan={TABLE_COLUMNS.length} align="center" sx={{ py: 3 }}>
                  <Typography variant="body2">Загрузка транзакций...</Typography>
                </TableCell>
              </TableRow>
            ) : transactions.length === 0 ? (
              // Отображение пустого состояния
              <TableRow>
                <TableCell colSpan={TABLE_COLUMNS.length} align="center" sx={{ py: 3 }}>
                  <Typography variant="body2">
                    {searchQuery ? 'По вашему запросу ничего не найдено' : 'Транзакции отсутствуют'}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              // Отображение транзакций
              transactions.map((transaction) => (
                <TableRow
                  key={transaction.id}
                  hover
                  onClick={() => onRowClick?.(transaction)}
                  sx={{ cursor: onRowClick ? 'pointer' : 'default' }}
                >
                  {TABLE_COLUMNS.map((column) => (
                    <TableCell key={column.id} sx={CELL_STYLES}>
                      {renderCell(transaction, column)}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Компонент пагинации */}
      <TablePagination
        component="div"
        count={pagination?.total_count || 0}
        page={(pagination?.current_page || 1) - 1} // MUI использует 0-индексацию для страниц
        onPageChange={handleChangePage}
        rowsPerPage={pagination?.per_page || 10}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Строк на странице:"
        labelDisplayedRows={({ from, to, count }) => `${from}–${to} из ${count}`}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    </Paper>
  );
};

export default React.memo(AppTransactionsTableMini);
