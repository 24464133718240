import React, { Component } from 'react';

interface FileInputProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  multiple?: boolean;
  accept?: string;
  name?: string;
  label?: string;
  disabled?: boolean;
}

interface FileInputState {
  file: File | null;
}

class AppFileInput extends Component<FileInputProps, FileInputState> {
  constructor(props: FileInputProps) {
    super(props);
    this.state = {
      file: null,
    };

    this.handleFileChange = this.handleFileChange.bind(this);
  }

  handleFileChange(e: React.ChangeEvent<HTMLInputElement>): void {
    let target = e.target as HTMLInputElement;
    let file: File = (target.files as FileList)[0];

    this.setState({
      file: file,
    });

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  render() {
    return (
      <div>
        {this.props.label && (
          <label className="block text-sm font-medium text-gray-700">{this.props.label}</label>
        )}
        <div className="relative mt-1 rounded-md">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <i className={`far fa-file text-gray-400 h-5 w-5`}></i>
          </div>
          <input
            type="file"
            className={`block w-full pl-10 sm:text-sm border-gray-900 rounded-md`}
            onChange={this.handleFileChange}
            multiple={this.props.multiple}
            accept={this.props.accept}
            name={this.props.name}
            style={{ outline: 'none' }}
            disabled={this.props.disabled}
          />
        </div>
      </div>
    );
  }
}

export default AppFileInput;
